// middleware/getHeadersAndRedirect.js

const SLUG_KEYS = {
  "/adam-gebrian": "/gebrian-prekvapive-stavby",
  "/aerofilms": "/",
  "/agata-pracharova": "/",
  "/andrej-polak": "/",
  "/arthur-f-sniegon": "/",
  "/auto-cz": "/",
  "/babcakova-simona": "/",
  "/barbora-havlikova": "/ikonicke-modely",
  "/blesk-pro-zeny": "/",
  "/cestmir-strakaty": "/prostor-x",
  "/chinaski": "/chinaski-katarina-knechtova-miliooony-prani",
  "/czc-cz": "/",
  "/daniel-marek": "/",
  "/david-vanicek": "/",
  "/denisa-bartosova": "/",
  "/divadlo-vosto5": "/",
  "/dockalova-tereza": "/branky-body-kokoti",
  "/dominik-dabrowski": "/udalosti-ludka-stanka",
  "/dominik-stroukal": "/",
  "/dusan-majer": "/vesmirne-zpravy",
  "/e15": "/",
  "/ester-geislerova": "/",
  "/ewa-farna": "/ewa-farna-vanoce-na-miru",
  "/festival-rock-for-people": "/",
  "/frekvence-1": "/pokacova-rychlovka-frekvence-1",
  "/hana-vecerkova": "/dobrou-chut",
  "/info-cz": "/",
  "/isport-cz": "/",
  "/jakub-jacob-bina-2": "/cvici-cele-cesko",
  "/jakub-klingohr": "/",
  "/jakub-nemcok": "/",
  "/jan-burian": "/",
  "/jan-grundman": "/",
  "/jan-prusinovsky": "/",
  "/jan-strejcovsky": "/",
  "/jana-samsukova": "/",
  "/jarda-konas": "/paran-jarda",
  "/jenovefa-bokova": "/zivot-je-hra",
  "/jerabek-tomas": "/",
  "/jerabek-tomas-2": "/",
  "/jiri-charvat": "/",
  "/jiri-havelka": "/",
  "/jiri-madl": "/",
  "/jiri-padevet": "/temna-doba",
  "/jitka-novackova": "/",
  "/karlos-terminator-vemola": "/",
  "/katarina-knechtova": "/chinaski-katarina-knechtova-miliooony-prani",
  "/klara-cibulkova": "/",
  "/krenkova-eliska": "/vsechno-je-jinak",
  "/krystof-hadek-5": "/",
  "/krystof-hadek-6": "/",
  "/kviff-distribuce": "/",
  "/laco-deczi": "/mily-laco",
  "/lucid-lucia-sladeckova": "/kurz-hviezdneho-licenia-s-lucid",
  "/lucie-kocman": "/kralovny-poradku",
  "/lucie-machackova": "/",
  "/ludek-stanek": "/udalosti-ludka-stanka",
  "/lukas-codr": "/na-vlastni-cocik",
  "/mallcz": "/",
  "/marie-radostova": "/sitoviny",
  "/marika-a-david": "/busny",
  "/marketa-ruzickova": "/",
  "/martin-franc": "/zmlsane-dejiny",
  "/martin-hanus": "/",
  "/martin-jirman": "/trafika",
  "/martin-mikyska": "/mikyrova-uzasna-pout-internetem",
  "/martin-vaculik": "/auta-martina-vaculika",
  "/matej-havelka": "/martyisdead",
  "/matej-kosut": "/",
  "/michal-galik": "/",
  "/michal-kern": "/stylista",
  "/michal-prskavec": "/svetem-jednou-stopou",
  "/michal-suchanek": "/zivot-je-hra",
  "/milos-cermak": "/",
  "/miracech": "/",
  "/naomi-adachi": "/naomi-v-japonsku",
  "/natalie-kotkova": "/mall-boxing",
  "/nf-neuron": "/",
  "/oliver-vozarik": "/",
  "/ondrej-cihlar": "/",
  "/ondrej-formanek": "/udalosti-ludka-stanka",
  "/ondrej-vetchy": "/",
  "/pan-cuketka": "/",
  "/pavel-novotny": "/extremni-starosta",
  "/pavel-zuna": "/top-moment",
  "/pavlina-stefanova": "/kralovny-poradku",
  "/pedro": "/",
  "/petr-havlicek": "/rozum-v-troube",
  "/petr-jan-juracka": "/",
  "/petr-marek": "/",
  "/petr-vanek": "/",
  "/petra-buckova": "/martyisdead",
  "/petra-nesvacilova": "/stylista",
  "/plodkova-jana": "/tridni-schuzka",
  "/radan-sprongl": "/no-go-zone",
  "/radek-david": "/",
  "/reflex": "/",
  "/roman-mrazik": "/",
  "/roman-vanek": "/rozum-v-troube",
  "/roman-zach": "/",
  "/sara-korbelova": "/martyisdead",
  "/sekce/nejnovejsi": "nejnovejsi-videa",
  "/stejk": "/mall-boxing",
  "/stephen-senewiratne": "/",
  "/tadeas-danek": "/",
  "/tigran-hovakimyan": "/o-papiry",
  "/tomas-ouhel": "/",
  "/tomas-plhon": "/udalosti-ludka-stanka",
  "/tomas-zastera": "/",
  "/umeni-pro-mesto": "/",
  "/vaclav-fric": "/prazdna-lednice",
  "/vaclav-marhold": "/",
  "/vaclav-neuzil": "/tridni-schuzka",
  "/veronika-koko-smehlikova": "/menutovka",
  "/veronika-razova": "/jednou-rukou-3",
  "/vit-samek": "/na-vlastni-otvor",
  "/vladimir-skultety": "/",
  "/vojtech-branik-dvorak": "/na-snure",
  "/weef": "/",
  "/zdenek-godla": "/",
  "/zdenek-marcin": "/kosik-na-grilu",
  "/zive-cz": "/",
  "/zpevacka-tonya-graves": "/",
  "/zivocisna-rise": "/",
  "/zive": "/",
  "/kratky-film": "/",
  "/sport-a-akce": "/",
  "/jednou-rukou": "/",
  "/testovaci-kategorie": "/",
  "/testovaci-category-2": "/",
  "/vyhraj-to": "/",
  "/30menutovka": "/",
  "/ctvrta-hvezda": "/",
  "/kosmo": "/",
  "/jana-plodkova": "/",
  "/mira-bosak": "/",
  "/roman-zaruba": "/",
  "/marek-cermak": "/",
  "/jacob-bina": "/",
  "/jakub-stafek": "/",
  "/tonya-graves": "/",
  "/ondrej-holzmann": "/",
  "/barbara-nesvadbova": "/",
  "/roman-snajdrvint": "/",
  "/bara-sedlackova": "/",
  "/petra-sovova": "/",
  "/ondrej-kasparek": "/",
  "/vilda-franek": "/",
  "/katerina-vackova": "/",
  "/denis-kubik": "/",
  "/nasty": "/",
  "/andrea-kalousova": "/",
  "/hanka-vecerkova": "/",
  "/petr-vancura": "/",
  "/eva-josefikova": "/",
  "/david-novotny": "/",
  "/jakub-zacek": "/",
  "/ivan-trojan": "/",
  "/miroslav-krobot": "/",
  "/hynek-cermak": "/",
  "/marek-taclik": "/",
  "/klara-meliskova": "/",
  "/martha-issova": "/",
  "/ceska-televize": "/",
  "/live": "/",
  "/bez-frazi-2": "/",
  "/vyhraj-to-black-friday-edition": "/",
  "/adam-ondra": "/",
  "/adam-ondra-2": "/",
  "/porad-o-kterem-se-nemluvi": "/",
  "/halka-tresnakova": "/",
  "/marek-daniel": "/",
  "/michal-dalecky": "/",
  "/halka-tresnakova-2": "/",
  "/michal-dalecky-2": "/",
  "/necekane-darky-2": "/",
  "/slow-tv": "/",
  "/slow-tv-show": "/",
  "/prague-short-film-festival": "/",
  "/festival-kratkych-filmu-praha": "/",
  "/slow-tv-4": "/",
  "/poradna-2": "/",
  "/hleda-se-nemo": "/",
  "/vsechno-je-jinak-2": "/",
  "/vsechno-je-jinak-3": "/",
  "/transgas-2": "/",
  "/test-riad-a": "/",
  "/test-riadi-fund": "/",
  "/demolice-komplexu-transgas": "/",
  "/mira-cech": "/",
  "/krystof-hadek": "/",
  "/krystof-hadek-2": "/",
  "/krystof-hadek-3": "/",
  "/krystof-hadek-4": "/",
  "/vanoce-s-mall-cz": "/",
  "/mall-tv-kviff": "/",
  "/wildlife-guards-2": "/",
  "/olympijsky-fanklub-2": "/",
  "/pracharova-agata": "/",
  "/adkdfjlkasdjf": "/",
  "/ceska-ulicka-michala-hrdlicky-2": "/",
  "/ceska-ulicka-michala-hrdlicky-3": "/",
  "/frekvence1": "/",
  "/prostor-x-2": "/",
  "/tyden-zive-2": "/",
  "/auto-cz-2": "/",
  "/brutal-relax-show-2": "/",
  "/brutal-relax-show-3": "/",
  "/pragueshorts": "/",
  "/ac-sparta-praha": "/",
  "/kocman-lucie": "/",
  "/zpravy-z-tymu": "/",
  "/kurz-hvezdneho-liceni-s-lucid": "/",
  "/den-ta-cr-e021": "/",
  "/den-ta-cr-e021-3": "/",
  "/kdyz-neumis-tocit": "/",
  "/kdyz-nemas-co-tocit": "/",
  "/tvurci-tym-ma-krizi": "/",
  "/kuba-ma-krizi-stredniho-veku": "/",
  "/new-player-testing-np-mall-tv": "/",
  "/test-notifications": "/",
  "/fame-play": "/",
  "/michael-szatmary": "/takze-tak",
  "/sekce/oblibenci": "/porady",
  "/jaro-slavik": "/prikazane-uvolnenie",
  "/dano-dangl": "/7-x-7",
  "/joe-trendy": "/naloz-s-joe-trendym",
  "/lucia-javorcekova": "/bullwar",
  "/dodik": "/glupe-videja",
  "/adela-elbel": "/nocni-smena-s-adelou",
  "/jana-kovalcikova": "/kavej",
  "/maly-miso": "/1-den-ako",
  "/juraj-curny": "/top-7-juraja-curneho",
  "/arnost-frauenberg": "/talk-show-na-rudlu",
  "/komentator-holec-zemanovo-okoli-panikari-nikdo-netusi-co-udela-skodi-i-svym-blizkym":
    "/nejnovejsi-videa",
  "/zive-sjizdeni-jezu-na-vltave": "/nejnovejsi-videa",
  "/test-sk": "/nejnovejsi-videa",
  "/test-sk-2": "/nejnovejsi-videa",
  "/jackass-test": "/nejnovejsi-videa",
  "/jackasstest2-mp4": "/nejnovejsi-videa",
  "/test-sk-video-3": "/nejnovejsi-videa",
  "/test-slovensko-4": "/nejnovejsi-videa",
  "/live-video-01-08-2019-11-27": "/nejnovejsi-videa",
  "/lewis-hamilton-test": "/nejnovejsi-videa",
  "/live-video-01-08-2019-14-27": "/nejnovejsi-videa",
  "/test-chyba": "/nejnovejsi-videa",
  "/live-video-01-08-2019-16-20": "/nejnovejsi-videa",
  "/odpoledni-test-2": "/nejnovejsi-videa",
  "/live-video-01-08-2019-16-51": "/nejnovejsi-videa",
  "/live-video-01-08-2019-17-11": "/nejnovejsi-videa",
  "/test12345": "/nejnovejsi-videa",
  "/live-video-01-08-2019-17-53": "/nejnovejsi-videa",
  "/live-video-01-08-2019-18-04": "/nejnovejsi-videa",
  "/live-video-01-08-2019-19-54": "/nejnovejsi-videa",
  "/live-video-01-08-2019-20-37": "/nejnovejsi-videa",
  "/live-video-02-08-2019-09-40": "/nejnovejsi-videa",
  "/live-video-02-08-2019-09-43": "/nejnovejsi-videa",
  "/live-video-02-08-2019-09-48": "/nejnovejsi-videa",
  "/live-video-02-08-2019-09-50": "/nejnovejsi-videa",
  "/live-video-02-08-2019-09-52": "/nejnovejsi-videa",
  "/live-video-02-08-2019-09-57": "/nejnovejsi-videa",
  "/live-video-02-08-2019-10-14": "/nejnovejsi-videa",
  "/test-video-2-8": "/nejnovejsi-videa",
  "/live-video-02-08-2019-11-42": "/nejnovejsi-videa",
  "/live-video-02-08-2019-11-45": "/nejnovejsi-videa",
  "/live-video-05-08-2019-16-40": "/nejnovejsi-videa",
  "/ionoojoi": "/nejnovejsi-videa",
  "/test-video-6-8": "/nejnovejsi-videa",
  "/test-copy-cz-sk": "/nejnovejsi-videa",
  "/test-cop-sk-cz": "/nejnovejsi-videa",
  "/live-video-06-08-2019-14-46": "/nejnovejsi-videa",
  "/test-ukladani-sk": "/nejnovejsi-videa",
  "/live-video-06-08-2019-15-04": "/nejnovejsi-videa",
  "/live-video-08-08-2019-12-50": "/nejnovejsi-videa",
  "/test-processing-a-ukladani-videi": "/nejnovejsi-videa",
  "/live-video-08-08-2019-16-35": "/nejnovejsi-videa",
  "/takze-tak-intro": "/nejnovejsi-videa",
  "/zive-capi-hnizdo-v-bolesove-sk": "/nejnovejsi-videa",
  "/zive-planespotting-praha-bez-infografiky": "/nejnovejsi-videa",
  "/samplevideo-720x480-30mb-mp4-2": "/nejnovejsi-videa",
  "/samplevideo-720x480-30mb-mp4-3": "/nejnovejsi-videa",
  "/samplevideo-720x480-30mb-mp4-4": "/nejnovejsi-videa",
  "/samplevideo-720x480-30mb-mp4-5": "/nejnovejsi-videa",
  "/diel01-h264-720p-mp4": "/nejnovejsi-videa",
  "/s1-e7-soutez-modelek": "/nejnovejsi-videa",
  "/s1-e1-lietajuci-pes": "/nejnovejsi-videa",
  "/s1-e1-priliv": "/nejnovejsi-videa",
  "/s1-e2-odliv": "/nejnovejsi-videa",
  "/s1-e3-hranice": "/nejnovejsi-videa",
  "/s1-e4-pristav": "/nejnovejsi-videa",
  "/s1-e5-po-dvou-letech": "/nejnovejsi-videa",
  "/jak-se-vam-ztracena-vejce-vzdycky-povedou": "/nejnovejsi-videa",
  "/utecenecka-vlna-trailer": "/nejnovejsi-videa",
  "/skok-starhoppera-do-150-metrov": "/nejnovejsi-videa",
  "/velmajster-jerry-nadmerna-operacia": "/nejnovejsi-videa",
  "/ako-na-domaci-popcorn": "/nejnovejsi-videa",
  "/trendy-hejty-01": "/nejnovejsi-videa",
  "/bullwar-trailer": "/nejnovejsi-videa",
  "/kavej-01": "/nejnovejsi-videa",
  "/top-7-james-bond": "/nejnovejsi-videa",
  "/kinopati-hruby-1-mp4": "/nejnovejsi-videa",
  "/7-x-7-adela": "/nejnovejsi-videa",
  "/takze-tak-1-diel": "/nejnovejsi-videa",
  "/paranormal-01": "/nejnovejsi-videa",
  "/samplevideo-720x480-30mb-mp4-6": "/nejnovejsi-videa",
  "/takze-tak-01": "/nejnovejsi-videa",
  "/y2mate-com-richard-hammond-reveals-the-new-land-rover-defender-vac6mje8rqo-360p-mp4":
    "/nejnovejsi-videa",
  "/test-kopirovani-live-videa": "/nejnovejsi-videa",
  "/y2mate-com-richard-hammond-reveals-the-new-land-rover-defender-vac6mje8rqo-360p-mp4-2":
    "/nejnovejsi-videa",
  "/test-kopy-sekce": "/nejnovejsi-videa",
  "/takze-tak-1-diel-mall-tv-youtube-1080p-hd-mp4": "/nejnovejsi-videa",
  "/suhajicitrailer": "/nejnovejsi-videa",
  "/vid-20190909-wa0009-mp4": "/nejnovejsi-videa",
  "/scampia-najnebezpecnejsie-sidlisko-v-taliansku": "/nejnovejsi-videa",
  "/heno-fackovec-najdlhsi-sex": "/nejnovejsi-videa",
  "/velmajster-jerry-nadmerna-operacia-2": "/nejnovejsi-videa",
  "/jaro-slavik-ako-sa-popasovat-s-minulostou": "/nejnovejsi-videa",
  "/7x7-adela": "/nejnovejsi-videa",
  "/kavej-s1-e1-slovan": "/nejnovejsi-videa",
  "/kavej-s1-e2-zlate-piesky": "/nejnovejsi-videa",
  "/start-falcon-9-amos-17": "/nejnovejsi-videa",
  "/stand-up-factory-danocisty-mall-tv-mp4": "/nejnovejsi-videa",
  "/stand-up-factory-henofackovec-mall-tv-mp4": "/nejnovejsi-videa",
  "/stand-up-factory-jaroslavik-mall-tv-mp4": "/nejnovejsi-videa",
  "/stand-up-factory-ladzo-mall-tv-mp4": "/nejnovejsi-videa",
  "/stand-up-factory-velmajter-jerry-mall-tv-mp4": "/nejnovejsi-videa",
  "/trendyho-praudy-01": "/nejnovejsi-videa",
  "/y2mate-com-richard-hammond-reveals-the-new-land-rover-defender-vac6mje8rqo-360p-mp4-3":
    "/nejnovejsi-videa",
  "/7x7-adela-vinczeova": "/nejnovejsi-videa",
  "/kavej-s1-ep1-slovan": "/nejnovejsi-videa",
  "/prva-velka-europska-misia": "/nejnovejsi-videa",
  "/7x7-ep1-v2-edit-mp4-2": "/nejnovejsi-videa",
  "/naloz-s-joe-trendym-1": "/nejnovejsi-videa",
  "/naloz-s-joe-trendym-1ep": "/nejnovejsi-videa",
  "/start-sojuz-2-1a-sojuz-ms-14-22-8-05-38": "/nejnovejsi-videa",
  "/starhopper-skok-do-150-metrov-28-08-0-00": "/nejnovejsi-videa",
  "/start-delta-iv-m-4-2-gps-iii-sv02-magellan-22-8-15-00": "/nejnovejsi-videa",
  "/dusan-sefredaktor": "/nejnovejsi-videa",
  "/dusan-sefredaktor-2": "/nejnovejsi-videa",
  "/rudolfov-magicky-tanec": "/nejnovejsi-videa",
  "/dusan-sefredaktor-3": "/nejnovejsi-videa",
  "/live-video-16-09-2019-14-30": "/nejnovejsi-videa",
  "/prasok-z-ludskych-lebiek-old": "/nejnovejsi-videa",
  "/live-video-19-09-2019-14-48": "/nejnovejsi-videa",
  "/stylista-dil-04-vimeo-1080p-hd-mp4": "/nejnovejsi-videa",
  "/kosicke-sidlisko-lunik-ix": "/nejnovejsi-videa",
  "/ako-pripravit-chutnu-vevericu": "/nejnovejsi-videa",
  "/prototyp-starship-rastie-ako-z-vody": "/nejnovejsi-videa",
  "/start-h-2b-htv-8-24-9-18-05": "/nejnovejsi-videa",
  "/start-sojuz-fg-sojuz-ms-15-25-9-15-57": "/nejnovejsi-videa",
  "/kralik-trochu-inak": "/nejnovejsi-videa",
  "/rybia-anestezia": "/nejnovejsi-videa",
  "/kavej-diel1-vimeo-1080p-hd-mp4": "/nejnovejsi-videa",
  "/kavej-diel2-v2-vimeo-1080p-hd-mp4": "/nejnovejsi-videa",
  "/prave-ste-objednali-na-mall-sk": "/nejnovejsi-videa",
  "/najvacsi-africky-slum-kibera-milion-ludi-v-absolutnej-chudobe":
    "/nejnovejsi-videa",
  "/devat-ludi-na-iss": "/nejnovejsi-videa",
  "/sladke-jatky": "/nejnovejsi-videa",
  "/ako-ziju-ludia-na-jednej-z-najvacsich-skladok-na-svete":
    "/nejnovejsi-videa",
  "/kolos-pathfinder-v-montaznej-hale": "/nejnovejsi-videa",
  "/proton-m-eutelsat-5-west-b-a-mev-1-9-10-12-17-2": "/nejnovejsi-videa",
  "/pivo-zo-16-storocia": "/nejnovejsi-videa",
  "/pegasus-xl-icon-11-10-3-30": "/nejnovejsi-videa",
  "/mogadiso-pirati-a-zraloky-v-mori-teroristi-na-susi": "/nejnovejsi-videa",
  "/co-caka-spacex-a-boeing-pred-absolvovanim-letov-s-ludmi":
    "/nejnovejsi-videa",
  "/novy-serial-martyisdead-startuje-v-nedelu-20-oktobra": "/nejnovejsi-videa",
  "/chrustia-polievka": "/nejnovejsi-videa",
  "/s1-ep1-martyisdead": "/nejnovejsi-videa",
  "/s1-e1-martyisdead-23": "/nejnovejsi-videa",
  "/drasticke-zabery-trh-so-psim-a-exotickym-masom": "/nejnovejsi-videa",
  "/dva-nove-skafandre-pre-lety-na-mesiac": "/nejnovejsi-videa",
  "/ladizo-last-minute-dovolenka": "/nejnovejsi-videa",
  "/nazivo-mazacka-jazda-prahou": "/nejnovejsi-videa",
  "/nazivo-vlaky-na-prazskom-hlavnom-nadrazi": "/nejnovejsi-videa",
  "/nazivo-lod-rio-2": "/nejnovejsi-videa",
  "/nazivo-vlaky-na-ousteckom-nadrazi": "/nejnovejsi-videa",
  "/nazivo-vlaky-v-cercanoch": "/nejnovejsi-videa",
  "/nazivo-prague-boats-plavba-po-vltave": "/nejnovejsi-videa",
  "/bitka-o-tripolis-na-vlastnej-kozi": "/nejnovejsi-videa",
  "/knopati-slavik-v3-mp4": "/nejnovejsi-videa",
  "/rozlozenie-obrieho-slnecneho-stitu": "/nejnovejsi-videa",
  "/lucie-machackova-ake-meno-vybrat-pre-dieta": "/nejnovejsi-videa",
  "/s1-e8-festival-vo-varoch": "/nejnovejsi-videa",
  "/mame-radi-maciatka": "/nejnovejsi-videa",
  "/zachytenie-kozmickej-lode-cygnus-ng-12-na-iss-4-11-11-45":
    "/nejnovejsi-videa",
  "/starliner-skuska-zachranneho-systemu-z-rampy-4-11-15-00":
    "/nejnovejsi-videa",
  "/tripolis-bombardovanie-letiska-a-zivot-v-obliehanom-meste":
    "/nejnovejsi-videa",
  "/europsky-solar-orbiter-dorazil-na-floridu": "/nejnovejsi-videa",
  "/abraham-final-mp4": "/nejnovejsi-videa",
  "/ako-sa-cvicia-psy-na-hladanie-min-a-vybusnin": "/nejnovejsi-videa",
  "/start-flacon-9-starlink-v1-1-11-11-15-56": "/nejnovejsi-videa",
  "/starliner-vyskusal-unik-z-rampy": "/nejnovejsi-videa",
  "/martin-jirman-skaredy-princ": "/nejnovejsi-videa",
  "/tuzla-v-bosne-ako-sa-hlada-nevybuchnuta-municia-v-minovom-poli":
    "/nejnovejsi-videa",
  "/vysavace-tycove-poradna": "/nejnovejsi-videa",
  "/cisticky-poradna": "/nejnovejsi-videa",
  "/mixery-poradna": "/nejnovejsi-videa",
  "/odvlhcovace-poradna": "/nejnovejsi-videa",
  "/roboti-poradna": "/nejnovejsi-videa",
  "/zvlhcovace-poradna": "/nejnovejsi-videa",
  "/kozmicka-oprava-ktoru-nikto-necakal": "/nejnovejsi-videa",
  "/prvu-lod-starship-znicil-vybuch": "/nejnovejsi-videa",
  "/geamana-rumunska-dedina-utopena-v-jedovatom-bahne": "/nejnovejsi-videa",
  "/prvu-lod-orion-cakaju-podrobne-skusky": "/nejnovejsi-videa",
  "/start-falcon-9-dragon-crs-19-5-12-18-29": "/nejnovejsi-videa",
  "/kozmicka-lod-dragon-crs-19-zachytenie-na-iss-7-12-cas-bude-upresneny":
    "/nejnovejsi-videa",
  "/moldavsko-najchudobnejsia-krajina-europy-sa-drasticky-vyludnuje":
    "/nejnovejsi-videa",
  "/slnko-dokaze-prekvapit-staci-se-pozriet-zblizka": "/nejnovejsi-videa",
  "/tigran-hovakimyan-tazky-zivot-imigranta": "/nejnovejsi-videa",
  "/soroca-hlavne-mesto-romov-a-mekka-romskej-architektury":
    "/nejnovejsi-videa",
  "/atlas-v-starliner-oft-20-12-12-36": "/nejnovejsi-videa",
  "/starliner-oft-iss-21-12-14-08": "/nejnovejsi-videa",
  "/budova-slovenskeho-rozhlasu": "/nejnovejsi-videa",
  "/neologicka-synagoga-v-ziline": "/nejnovejsi-videa",
  "/budova-slovenskeho-rozhlasu-dalsie-postrehy": "/nejnovejsi-videa",
  "/cech-a-slovak-odvazne-dopadli-bandu-indoneskych-paserakov-1-2":
    "/nejnovejsi-videa",
  "/dobroty-z-najoblubenejsieho-trhu-v-tokiu": "/nejnovejsi-videa",
  "/typicke-japonske-sushi-proste-uplne-nieco-ine-ako-na-slovensku":
    "/nejnovejsi-videa",
  "/oliver-vozarik-bozske-izraelske-ranajky": "/nejnovejsi-videa",
  "/oliver-vozarik-kurecie-prsia-s-pecenou-mrkvou": "/nejnovejsi-videa",
  "/matej-kosut-topinamburova-polievka": "/nejnovejsi-videa",
  "/oliver-vozarik-pecena-repa-s-javorovym-sirupom": "/nejnovejsi-videa",
  "/jaromir-jagr-hrali-sme-za-10-milionov-ludi": "/nejnovejsi-videa",
  "/tatana-gregor-brzobohata-to-nemozes-vyhrat": "/nejnovejsi-videa",
  "/pavel-nedved-zivotny-zapas-skropeny-slzami": "/nejnovejsi-videa",
  "/sekunda-kedy-sa-zastavi-svet-pavel-zuna-predstavuje-vrcholne-momenty-slavnych":
    "/nejnovejsi-videa",
  "/michael-zantovsky-ako-amerika-tlieskala-ceskoslovensku":
    "/nejnovejsi-videa",
  "/testujeme-fen-co-dokaze-inde-ako-v-kupelni": "/nejnovejsi-videa",
  "/test-it-prislusenstva-pojde-z-neho-nieco-uvarit": "/nejnovejsi-videa",
  "/bonus-suboj-syrov-pre-celu-europu": "/nejnovejsi-videa",
  "/bryndza-vs-syrecek-dokladny-test-syrov": "/nejnovejsi-videa",
  "/nadacia-louis-vuitton-v-parizi-dalsie-postrehy": "/nejnovejsi-videa",
  "/nadacia-louis-vuitton-v-parizi": "/nejnovejsi-videa",
  "/industrialny-objekt-mlynica-dalsie-postrehy": "/nejnovejsi-videa",
  "/industrialny-objekt-mlynica-v-bratislave": "/nejnovejsi-videa",
  "/cerpacia-stanica-gas-matuskovo": "/nejnovejsi-videa",
  "/cerpacia-stanica-gas-matuskovo-dalsie-postrehy": "/nejnovejsi-videa",
  "/kulturny-priestor-nadvorie-v-historickom-centre-trnavy":
    "/nejnovejsi-videa",
  "/rozhovor-s-architektom-a-bratislavskym-primatorom-matusom-vallom":
    "/nejnovejsi-videa",
  "/neologicka-synagoga-v-ziline-dalsie-postrehy": "/nejnovejsi-videa",
  "/bonus-behind-the-scenes-alebo-co-ste-nevideli-v-dokumente":
    "/nejnovejsi-videa",
  "/unikatny-zatah-na-prodejcov-korytnaciny-a-koniec-o-akom-nikto-ani-nesnival-2-2":
    "/nejnovejsi-videa",
  "/mrkva-v-prasku-ako-urobit-z-prdu-gulicku": "/nejnovejsi-videa",
  "/ako-sa-zdravo-detoxikovat": "/nejnovejsi-videa",
  "/ketodieta-moderny-sposob-chudnutia-ma-vela-uskali": "/nejnovejsi-videa",
  "/biely-alebo-hnedy-cukor-nenechajte-sa-zmanipulovat": "/nejnovejsi-videa",
  "/uvod-do-relacie-pravda-o-superpotravinach": "/nejnovejsi-videa",
  "/nejrusnejsia-krizovatka-na-svete-vo-vyhlasenej-stvrti-sibuja":
    "/nejnovejsi-videa",
  "/perfektne-organizovane-mravenisko-ako-si-uzit-japonske-metro":
    "/nejnovejsi-videa",
  "/ikonicky-japonsky-burger-kuracie-shaka-shaka-a-zmrzlinovy-drink":
    "/nejnovejsi-videa",
  "/schovajte-tetovani-nemavajte-palickami-dojedajte-a-srkajte":
    "/nejnovejsi-videa",
  "/ako-spravne-nabijat-mobil-a-setrit-bateriu": "/nejnovejsi-videa",
  "/ake-potraviny-v-ziadnom-pripade-nepatria-do-chladnicky":
    "/nejnovejsi-videa",
  "/test-aky-ste-este-nevideli-co-vydrzi-smartphone": "/nejnovejsi-videa",
  "/hanka-svobodova-ako-pomoct-morskym-korytnackam-aj-v-cesku":
    "/nejnovejsi-videa",
  "/bonus-zralok-velrybi-tvor-velky-ako-autobus": "/nejnovejsi-videa",
  "/bonus-co-je-to-ketoza-a-ako-sa-do-nej-clovek-dostane": "/nejnovejsi-videa",
  "/bonus-co-ste-nevedeli-o-preplachovani-creva": "/nejnovejsi-videa",
  "/roman-a-petr-zakladaju-novy-biznis-to-bude-prachov": "/nejnovejsi-videa",
  "/nebezpecne-nahradzovanie-cukru-zdravou-fruktozou": "/nejnovejsi-videa",
  "/suboj-o-nafukovaciu-pannu-za-200-mariek": "/nejnovejsi-videa",
  "/kto-su-romovia-a-kto-su-cigani": "/nejnovejsi-videa",
  "/ako-to-chodi-v-kapslovych-hoteloch-a-love-hoteloch": "/nejnovejsi-videa",
  "/starbucks-v-japonsku-nie-je-len-kava-do-teglika": "/nejnovejsi-videa",
  "/ako-spravne-prat-mame-par-tipov-ktore-urcite-nerobite": "/nejnovejsi-videa",
  "/bonus-testy-zeleninovych-cipsov-ktore-su-skutocne-zdrave":
    "/nejnovejsi-videa",
  "/zemiakove-cipsy-nezasyti-iba-drazdi-chut-su-zeleninove-lepsou-volbou":
    "/nejnovejsi-videa",
  "/co-vsetko-sa-da-pripravit-pomocou-rychlovarne-kanvice": "/nejnovejsi-videa",
  "/bonus-ako-dopadli-rybicky-z-rychlovarnej-kanvice": "/nejnovejsi-videa",
  "/pruser-na-luxusne-svadbe-americkej-smotanky": "/nejnovejsi-videa",
  "/svojrazna-vychova-deti-podla-laca": "/nejnovejsi-videa",
  "/s1-e1-najtrapnejsia-show-ceskoslovenskeho-internetu": "/nejnovejsi-videa",
  "/s1-e3-hanebny-olo-adam-misik-po-mne-ide": "/nejnovejsi-videa",
  "/s1-e4-spevacka-tonya-profackovala-drzeho-moderatora": "/nejnovejsi-videa",
  "/s1-e5-duch-mrtveho-spevaka-prehovoril": "/nejnovejsi-videa",
  "/s1-e6-devatdesiatkovy-moderator-slavek-boura-ma-zazracne-schopnosti":
    "/nejnovejsi-videa",
  "/ako-sa-robia-priemyselne-cierne-olivy-v-skutocnosti-su-zelene":
    "/nejnovejsi-videa",
  "/gastroskopia": "/nejnovejsi-videa",
  "/ako-prebieha-spontanny-porod": "/nejnovejsi-videa",
  "/tradicna-sojova-omacka-zakaznici-na-slovensku-sa-nechaju-klamat-uz-roky":
    "/nejnovejsi-videa",
  "/diskusia-s-lekarom-a-darkynou-vajicok-ide-vobec-o-pomoc-bezdetnym-parom":
    "/nejnovejsi-videa",
  "/gastroskopia-co-sposobuje-zaludocny-vred": "/nejnovejsi-videa",
  "/bronchoskopia": "/nejnovejsi-videa",
  "/ecka-na-obaloch-nie-su-in-ako-to-vyrobcovia-obchadzaju":
    "/nejnovejsi-videa",
  "/do-postele-bez-vecere-kedy-je-vhodne-naposledy-jest": "/nejnovejsi-videa",
  "/umele-oplodnenie-kolko-stoji-nadej-mat-vlastne-dieta": "/nejnovejsi-videa",
  "/lacne-detske-rezne-v-skutocnosti-pekne-draha-kupa": "/nejnovejsi-videa",
  "/gynekologia": "/nejnovejsi-videa",
  "/kedy-ist-prvykrat-ku-gynekologovi-a-ako-casto-absolvovat-prehliadku":
    "/nejnovejsi-videa",
  "/ake-to-je-rodit-s-dulou": "/nejnovejsi-videa",
  "/mall-sk-vydajna": "/nejnovejsi-videa",
  "/mall-sk-platba-prevodom": "/nejnovejsi-videa",
  "/kravickova-dieta-skratka-donaska-pre-lenochov": "/nejnovejsi-videa",
  "/ako-sa-dokazuje-ze-je-produkt-poctivy": "/nejnovejsi-videa",
  "/ovocie-ako-zive-pravda-je-na-druhej-strane": "/nejnovejsi-videa",
  "/porodna-asistentka-niektore-duly-v-porodnici-nevidime-radi-dochadza-ku-konfliktom":
    "/nejnovejsi-videa",
  "/ako-zachranuju-novorodence-na-modernej-neonatologii-v-ceskych-horoviciach":
    "/nejnovejsi-videa",
  "/kolonoskopia": "/nejnovejsi-videa",
  "/bonus-ako-spoznat-varovne-priznaky-mozneho-nadoru-v-hrubom-creve":
    "/nejnovejsi-videa",
  "/mlieko-na-pranieri-preco-je-nezmysel-ho-zatracovat": "/nejnovejsi-videa",
  "/choco-lite-klamstva-prachy-a-chudnutie-pripad-pre-kriminalku":
    "/nejnovejsi-videa",
  "/zazracna-tycinka-ako-premeni-vodu-a-vino-na-blahodarny-mok":
    "/nejnovejsi-videa",
  "/ako-vybrat-pre-deti-to-najlepsie-nenechajte-sa-nachytat":
    "/nejnovejsi-videa",
  "/kavej-diel-ep03-2020-vimeo-1080p-hd-mp4": "/nejnovejsi-videa",
  "/porodnica-v-ceskom-rakovniku-pozitivna-a-moderna-starostlivost-dokaze-zazraky":
    "/nejnovejsi-videa",
  "/hypnoporod-ako-sa-rodi-bez-strachu": "/nejnovejsi-videa",
  "/mamografia": "/nejnovejsi-videa",
  "/rozdiel-medzi-spekackami-a-parkami-v-parkoch-moze-byt-cokolvek":
    "/nejnovejsi-videa",
  "/post-naozaj-potrebuje-organizmus-trpiet": "/nejnovejsi-videa",
  "/zdrave-cigarety-co-sa-stane-ked-vyfajcite-vitaminy": "/nejnovejsi-videa",
  "/skinny-gummy-cukriky-po-ktorych-sa-ne-chudne": "/nejnovejsi-videa",
  "/5-zaujimavosti-o-soli-ktore-mozna-neviete": "/nejnovejsi-videa",
  "/je-toto-este-pivo-ako-sa-da-carovat-s-jeho-vyrobou": "/nejnovejsi-videa",
  "/stomatologia": "/nejnovejsi-videa",
  "/nevyhadzujte-zbytocne-potraviny-ktore-sa-daju-este-jest":
    "/nejnovejsi-videa",
  "/mlete-maso-nie-rodrvena-hmota-v-plastovej-vanicke-sa-tak-len-tvari":
    "/nejnovejsi-videa",
  "/ake-prinosy-ma-starostlivost-komunitnej-porodnej-asistenky-o-rodicku":
    "/nejnovejsi-videa",
  "/su-ranajky-naozaj-najdolezitejsim-jedlom-dna": "/nejnovejsi-videa",
  "/boj-pokracuje-dalsi-uspesny-zatah-na-predajcov-korytnaciny":
    "/nejnovejsi-videa",
  "/vacsi-objem-vacsi-obsah-neekologicki-balenia-obsahuju-hlavne-vzduch":
    "/nejnovejsi-videa",
  "/bonus-dalsie-triky-vyrobcov-malo-masa-vela-nahrazok": "/nejnovejsi-videa",
  "/ako-sa-moze-stat-porod-pre-rodicku-pozitivnym-zazitkom":
    "/nejnovejsi-videa",
  "/domaci-porod-unikatna-reportaz-dva-silne-zivotne-pribehy":
    "/nejnovejsi-videa",
  "/urologia": "/nejnovejsi-videa",
  "/kokosovy-olej-je-velkym-hitom-dobry-nie-je-skoro-v-nicom":
    "/nejnovejsi-videa",
  "/ako-dodrziavat-pitny-rezim-co-prezradi-vas-moc": "/nejnovejsi-videa",
  "/pecivo-do-nasej-stravy-patri-biele-alebo-celozrnne": "/nejnovejsi-videa",
  "/ake-sialenosti-obsahuju-priemyselne-vyrabane-makove-buchty-skoro-ako-od-babicky":
    "/nejnovejsi-videa",
  "/co-vsetko-musi-zvladnout-lekarka-v-porodnici-za-jednu-smenu":
    "/nejnovejsi-videa",
  "/ake-ucinky-ma-delena-strava-celkom-prekvapive-zistenie":
    "/nejnovejsi-videa",
  "/energy-drink-slusny-tahak-na-peniaze-preco-si-radsej-nedat-dobru-kavu":
    "/nejnovejsi-videa",
  "/koralova-mineralna-voda-nechajte-sa-prekvapit": "/nejnovejsi-videa",
  "/bonus-ako-sa-da-predlzit-penis": "/nejnovejsi-videa",
  "/kokain-za-20-tisic-eur": "/nejnovejsi-videa",
  "/najvacsi-omyl-pri-hladovke-ani-za-4-dni-tuky-nezhodite":
    "/nejnovejsi-videa",
  "/ludia-citajte-ako-vas-mozu-zmiast-vyzivove-tvrdenia-na-obaloch":
    "/nejnovejsi-videa",
  "/co-vsetko-obsahuju-instantne-kavove-napoje-nielen-vela-cukru-a-ecka":
    "/nejnovejsi-videa",
  "/takto-to-v-porodnici-skutocne-chodi-reaguje-lekarka-na-emotivne-komentare":
    "/nejnovejsi-videa",
  "/molovata-ako-sa-zije-v-totalne-odrezanej-dedine-a-v-republike-ktora-neexistuje":
    "/nejnovejsi-videa",
  "/limonady-bez-cukru-co-napacha-koktail-umelych-sladidiel":
    "/nejnovejsi-videa",
  "/dalsie-triky-vyrobcov-mozno-sa-dockame-aj-staroceskej-pizze":
    "/nejnovejsi-videa",
  "/ake-zvolit-ranajky-aby-nas-zasytili-a-nepriberali-sme": "/nejnovejsi-videa",
  "/spicbergy-polarna-noc-v-najsevernejsie-obyvanom-meste": "/nejnovejsi-videa",
  "/alzbeta-samkova-porod-patri-domov-rovnako-ako-smrt-moze-byt-bezpecnejsi-ako-v-porodnici":
    "/nejnovejsi-videa",
  "/zbytocne-obaly-v-obaloch-robia-z-nas-hlupakov-a-kaslu-na-zivotne-prostredie":
    "/nejnovejsi-videa",
  "/ako-skodi-mikrovlnna-rura-potravinam": "/nejnovejsi-videa",
  "/ako-moze-fungovat-dieta-usita-na-mieru-vasmu-znameniu": "/nejnovejsi-videa",
  "/autoimunitny-protokol-specialne-a-extremne-narocna-dieta":
    "/nejnovejsi-videa",
  "/cisarsky-rez-ojedinela-reportaz-z-porodu-ktory-vzbudzuje-vasne":
    "/nejnovejsi-videa",
  "/smetiarsky-slum-v-kahire-unikatna-stvrt-kam-turista-nevkroci":
    "/nejnovejsi-videa",
  "/cisarsky-rez-nie-je-ziadnou-jednoduchsou-formou-porodu-hovori-michal-zikan":
    "/nejnovejsi-videa",
  "/podcenene-ruska-su-krajiny-kde-vdaka-nim-nemaju-ziadnych-nakazenych":
    "/nejnovejsi-videa",
  "/cesi-doma-rusky-turisti-vonku-bez-rusok": "/nejnovejsi-videa",
  "/cirk-la-putyka-up-end-down-symphony-s-filmovou-filharmonii-18-3-20-00-2":
    "/nejnovejsi-videa",
  "/the-atavists-18-3": "/nejnovejsi-videa",
  "/live-video-19-03-2020-16-55": "/nejnovejsi-videa",
  "/ako-si-vyrobit-rusko-na-tri-sposoby-aj-bez-sijacieho-stroja":
    "/nejnovejsi-videa",
  "/ako-ucit-deti-doma-zabavnou-formou": "/nejnovejsi-videa",
  "/nezdrave-a-navyse-drahe-nahradky-syrov": "/nejnovejsi-videa",
  "/ako-sa-na-prahe-1-dealuju-ruska": "/nejnovejsi-videa",
  "/rozhovor-s-clenom-korona-timu": "/nejnovejsi-videa",
  "/ako-predchadzat-stresu-a-co-robit-ked-pride": "/nejnovejsi-videa",
  "/super-hra-na-doma-ruky-prec": "/nejnovejsi-videa",
  "/proteinove-produkty-dostanete-za-vyssiu-cenu-viac-bielkovin":
    "/nejnovejsi-videa",
  "/rapper-maniak-prideny-dj-black-angelika-19-3": "/nejnovejsi-videa",
  "/hentai-corporation-20-3-19-35-2": "/nejnovejsi-videa",
  "/ako-si-pripravit-jedlo-na-niekolko-dni-setrite-peniaze-aj-seba":
    "/nejnovejsi-videa",
  "/mrtve-centrum-ako-to-vyzera-na-hrade": "/nejnovejsi-videa",
  "/kysla-kapusta-ako-vyrobcovia-skazili-zdravy-cesky-zazrak":
    "/nejnovejsi-videa",
  "/vsechno-nejlepsi-iva-pazderkova-adela-elbel-21-3-19-55-2":
    "/nejnovejsi-videa",
  "/agatiny-beauty-tipy-ako-aj-s-ruskom-vyzerat-skvele": "/nejnovejsi-videa",
  "/prva-varka-rusok-mamicky-siju-agata-rozvaza-ma-to-zmysel":
    "/nejnovejsi-videa",
  "/aktualne-odporucania-od-porodnej-asistentky-pre-tehotne-zeny":
    "/nejnovejsi-videa",
  "/situacia-v-prazskej-mhd-policajti-zasahuju": "/nejnovejsi-videa",
  "/sedte-doma-vojnovy-reporter-vie-o-com-hovori": "/nejnovejsi-videa",
  "/pat-rychlych-jedal-jedna-rura-a-vela-zeleniny": "/nejnovejsi-videa",
  "/ako-sa-starat-o-rusko-pomocou-kuchynskych-technologii": "/nejnovejsi-videa",
  "/vsetko-najlepsie-iva-pazderkova-adela-elbel-21-3-2020": "/nejnovejsi-videa",
  "/mnaga-a-zdorp-treci-plochy-tour-krst-dvoch-novych-klipov-21-3-20-00":
    "/nejnovejsi-videa",
  "/stravovaci-rezim-petra-havlicka-ako-viete-zhresit": "/nejnovejsi-videa",
  "/par-cvikov-na-stehna-a-zadok-potrebujete-iba-stolicku": "/nejnovejsi-videa",
  "/domaci-chlieb-zakladame-kvasok-staci-len-muka-a-voda": "/nejnovejsi-videa",
  "/zasadne-rozdiely-medzi-chripkou-a-chorobou-covid-19": "/nejnovejsi-videa",
  "/nakladana-zelenina-skvele-zuzitkovanie-prebytkovej-zasoby":
    "/nejnovejsi-videa",
  "/vajickova-natierka-podla-agaty-na-velku-noc-cakat-nemusite":
    "/nejnovejsi-videa",
  "/sikovny-pomocnik-pri-vybere-potravin-ktoremu-sa-ale-vyrobcovia-brania":
    "/nejnovejsi-videa",
  "/vyhraj-to-skolni-special-1-5-fyzika-start-15-00": "/nejnovejsi-videa",
  "/zrni-24-3-19-35-2": "/nejnovejsi-videa",
  "/cesky-cerveny-kriz-pomaha-v-case-karanteny": "/nejnovejsi-videa",
  "/technicka-univerzita-v-liberci-ako-mozno-zvysit-ucinnost-rusok":
    "/nejnovejsi-videa",
  "/baloniky-s-prekvapenim-tato-hra-bude-bavit-vsetky-deti":
    "/nejnovejsi-videa",
  "/domaca-plastelina-zabava-na-cele-dopoludnie": "/nejnovejsi-videa",
  "/pizza-karantena-vlacne-cesto-a-napln-co-dom-dal": "/nejnovejsi-videa",
  "/rozhovor-s-primarom-aro-plucne-ventilatory-budu-potrebne":
    "/nejnovejsi-videa",
  "/darceky-od-deti-dojali-babicku-skuste-aj-vy-urobit-nieco-pre-svojich-blizkych":
    "/nejnovejsi-videa",
  "/hummus-vyborna-a-zdrava-desiata-po-ktorej-nepriberiete":
    "/nejnovejsi-videa",
  "/moze-byt-britska-cesta-budovania-kolektivnej-imunity-uspesna":
    "/nejnovejsi-videa",
  "/naloz-s-joe-trendym-ep10-2020-vimeo-1080p-hd-mp4": "/nejnovejsi-videa",
  "/domace-fitko-vyuzit-mozete-metlu-zemiaky-alebo-tekvicu":
    "/nejnovejsi-videa",
  "/ako-tuto-dobu-prezivaju-zachranari-su-dostatocne-chraneni":
    "/nejnovejsi-videa",
  "/3d-multimedialna-show-vivaldianno-reloaded-29-3-20-00": "/nejnovejsi-videa",
  "/vajecna-dieta-pekna-hypoteza-ale-postavena-na-hlavu": "/nejnovejsi-videa",
  "/z-ulic-do-stanov-ako-narocne-je-postarat-sa-o-ludi-bez-domova":
    "/nejnovejsi-videa",
  "/predlzene-riasy-v-karantene-co-teraz-s-nimi": "/nejnovejsi-videa",
  "/uzasne-spagety-s-rukolovym-pestom-za-10-minut-mate-hotovo":
    "/nejnovejsi-videa",
  "/dietne-zemiakove-placky-tipy-ako-vylepsit-uz-tak-dokonale-jedlo":
    "/nejnovejsi-videa",
  "/agata-princeznou-ako-vyrobit-jarny-veniec": "/nejnovejsi-videa",
  "/ako-telo-reaguje-na-virus-a-je-lepsie-sa-vyhnut-ibuprofenu":
    "/nejnovejsi-videa",
  "/3d-multimedialni-show-vivaldianno": "/nejnovejsi-videa",
  "/krmitko-na-konci-brd": "/nejnovejsi-videa",
  "/prazsky-komorny-balet-kytice-30-3-17-00": "/nejnovejsi-videa",
  "/prazsky-komorny-balet-kytice-30-3-17-00-2": "/nejnovejsi-videa",
  "/ako-nalozit-lososa-je-to-rychle-jednoduche-a-neskutocne-dobre":
    "/nejnovejsi-videa",
  "/ako-dostat-vitaminy-do-deti-vyrobte-zeleny-vitaminovy-vodnicky-drink":
    "/nejnovejsi-videa",
  "/zdravotne-sestry-v-zalohe-ponukaju-pomoc-nemocniciam-a-opatrovatelskym-domom":
    "/nejnovejsi-videa",
  "/domace-ucenie-s-detmi-mozete-spestrit-bojovkami": "/nejnovejsi-videa",
  "/ako-naporcovat-a-spracovat-kurca-bezo-zvyskov": "/nejnovejsi-videa",
  "/velkonocna-vyzdoba-podla-agaty-vystacite-s-tym-co-mate-doma":
    "/nejnovejsi-videa",
  "/kapitan-demo-3-4-20-00": "/nejnovejsi-videa",
  "/agatiny-tipy-na-rychle-ucesy-k-rusku-odstavajuce-usi-vyriesene":
    "/nejnovejsi-videa",
  "/ruska-rozhodne-ano-bezpecna-manipulacia-a-dezinfekcia-je-zaklad":
    "/nejnovejsi-videa",
  "/kapitan-demo-3-4": "/nejnovejsi-videa",
  "/ako-na-zahradny-stolik-z-paliet-zvladla-to-aj-agata": "/nejnovejsi-videa",
  "/vyprazane-cibulka-takto-vam-v-chladnicke-vydrzi-tyzdne":
    "/nejnovejsi-videa",
  "/omacka-z-medvedieho-cesnaku-idealna-k-minutkovemu-masu":
    "/nejnovejsi-videa",
  "/seniorov-ohrozuje-nielen-nakaza-ale-aj-karantena-ako-im-pomoct":
    "/nejnovejsi-videa",
  "/prazsky-komorni-balet-carmina-vetera-4-4": "/nejnovejsi-videa",
  "/agata-pecie-babovky-pre-bezdomovcov-pomoct-mozete-aj-vy":
    "/nejnovejsi-videa",
  "/domace-focaccia-z-vlastneho-mladeho-kvasku": "/nejnovejsi-videa",
  "/ako-posilnit-imunitny-system-nakupom-superpotravin-a-doplnkov-si-moc-nepomozeme":
    "/nejnovejsi-videa",
  "/na-akych-povrchoch-preziva-virus-najdlhsie-a-ako-ich-dezinfikovat":
    "/nejnovejsi-videa",
  "/co-vsetko-sa-dozviete-o-agate-10-vsetecnych-otazok-pre-najlepsiu-kamaratku":
    "/nejnovejsi-videa",
  "/bubble-squeak-genialny-sposob-ako-zuzitkovat-zvysky-surovin":
    "/nejnovejsi-videa",
  "/neviete-co-varit-skuste-rychlu-cesku-cinu-podla-agaty": "/nejnovejsi-videa",
  "/velkonocny-zajacik-z-rolky-od-toaletneho-papiera": "/nejnovejsi-videa",
  "/figle-vyrobcov-ako-znasilnuju-tradicne-pochutky": "/nejnovejsi-videa",
  "/rozhovor-so-zdravotnou-sestrou-v-zalohe-ako-projekt-funguje":
    "/nejnovejsi-videa",
  "/velkonocny-baranok-krasne-vlacne-cesto-takmer-bez-tuku":
    "/nejnovejsi-videa",
  "/cirk-la-putyka-hit-tell-the-difference-8-4": "/nejnovejsi-videa",
  "/sestrou-na-urgentnom-prijme-brada-musi-dole": "/nejnovejsi-videa",
  "/live-video-10-04-2020-15-27": "/nejnovejsi-videa",
  "/tipy-ako-najlepsie-uchovavat-v-chladnicke-cerstvu-zeleninu-a-bylinky":
    "/nejnovejsi-videa",
  "/nadychana-a-chrumkava-velkonocna-plnka-s-udenym-kolenom":
    "/nejnovejsi-videa",
  "/serum-pre-vyzivu-rias-si-mozete-vyrobit-jednoducho-doma":
    "/nejnovejsi-videa",
  "/velkonocne-dekoracie-ktoru-mozete-s-chutou-zjest": "/nejnovejsi-videa",
  "/prvy-den-na-bulovke-tu-konci-vsetka-sranda": "/nejnovejsi-videa",
  "/domace-kvaskovy-chlieb-krok-za-krokom": "/nejnovejsi-videa",
  "/velkonocne-pletenie-korbaca-s-agatou": "/nejnovejsi-videa",
  "/ovocie-aky-vplyv-ma-na-nas-imunitny-system": "/nejnovejsi-videa",
  "/cechomor-zahraje-pro-senior-telefon-zivota-90-13-4-20-00":
    "/nejnovejsi-videa",
  "/krasny-pozdrav-od-deti-a-agaty-pre-milovanu-babicku": "/nejnovejsi-videa",
  "/nakladane-vajcia-takto-predlzite-ich-trvanlivost-az-o-3-mesiace":
    "/nejnovejsi-videa",
  "/ozivenie-satnika-takto-mozete-vylepsit-nielen-bundu": "/nejnovejsi-videa",
  "/domace-bylinkove-zemle-od-agaty": "/nejnovejsi-videa",
  "/maslove-zemle": "/nejnovejsi-videa",
  "/psenicno-razny-podmaslovy-chlieb": "/nejnovejsi-videa",
  "/vaclavak-a-hlavna-stanica-turisti-zmizli-zobraci-zostali":
    "/nejnovejsi-videa",
  "/salat-jaroslaw-jarna-verzia-legendarneho-salatu-coleslaw":
    "/nejnovejsi-videa",
  "/prve-dojmy-z-bulovky-toto-som-na-zachranke-nezazil": "/nejnovejsi-videa",
  "/rozhovor-s-lekarkou-na-bulovke-u-nas-maju-sancu-vsetci":
    "/nejnovejsi-videa",
  "/zelenina-optimalne-je-zjest-pol-kila-denne": "/nejnovejsi-videa",
  "/karfiolove-steaky-a-jednoducha-salsa-z-paradajok": "/nejnovejsi-videa",
  "/kurca-na-hubach-z-jedneho-hrnca-jednoduchy-recept-uzasne-jedlo":
    "/nejnovejsi-videa",
  "/prva-nocna-na-urgente-takto-to-cele-prebieha-v-prvej-linii":
    "/nejnovejsi-videa",
  "/leco-nemusi-byt-vobec-obycajne": "/nejnovejsi-videa",
  "/ako-sa-v-tomto-ustrojeni-ne-poskrabkat-na-tvari": "/nejnovejsi-videa",
  "/s1-e1-dostat-druhu-sancu": "/nejnovejsi-videa",
  "/hamburgerove-zemle": "/nejnovejsi-videa",
  "/zo-zakladu-leca-vykuzlite-skvele-lahke-jedlo": "/nejnovejsi-videa",
  "/s1-e2-nezaspat-pri-peceni-pizze": "/nejnovejsi-videa",
  "/s1-e3-zobrat-flasku": "/nejnovejsi-videa",
  "/s1-e4-spomenut-si-na-meno": "/nejnovejsi-videa",
  "/s1-e5-skryt-smradlave-ponozky": "/nejnovejsi-videa",
  "/naloz-s-joe-trendym-ep14-2020-vimeo-1080p-hd-mp4": "/nejnovejsi-videa",
  "/zive-domacnost-sokolu-na-komine-teplarny-v-mlade-boleslavi":
    "/nejnovejsi-videa",
  "/razno-psenicny-chlieb-do-formy": "/nejnovejsi-videa",
  "/s1-e6-skorovat-pred-pohrebom": "/nejnovejsi-videa",
  "/s1-e7-nepokazit-to-holubom": "/nejnovejsi-videa",
  "/s1-e8-neukazat-pipik": "/nejnovejsi-videa",
  "/s1-e9-utopit-hovienko": "/nejnovejsi-videa",
  "/s1-e10-zapriet-expriatelku": "/nejnovejsi-videa",
  "/s2-e1-zbavit-sa-prisavky": "/nejnovejsi-videa",
  "/s2-e2-zapriet-sam-seba": "/nejnovejsi-videa",
  "/s2-e3-urobit-to-eve": "/nejnovejsi-videa",
  "/s2-e4-zachranit-priatelstvo": "/nejnovejsi-videa",
  "/s2-e5-konecne-sa-vyspat": "/nejnovejsi-videa",
  "/vitamin-d-je-pre-nasu-imunitu-zasadny": "/nejnovejsi-videa",
  "/centrum-prahy-po-karantene-70-kc-za-0-5-l-vody-uz-nehrozi":
    "/nejnovejsi-videa",
  "/french-toast-paradne-sladke-ranajky": "/nejnovejsi-videa",
  "/gemhaus-28-4": "/nejnovejsi-videa",
  "/co-je-to-cracking-kdeze-ziadne-drogy-ale-potravinarske-kuzla":
    "/nejnovejsi-videa",
  "/live-video-28-04-2020-10-33": "/nejnovejsi-videa",
  "/panzanella-skvely-salat-ktory-rozhodne-nie-je-dietny": "/nejnovejsi-videa",
  "/s2-e6-zistit-pravdu": "/nejnovejsi-videa",
  "/vycistit-vydezinfikovat-co-no-predsa-povrchy": "/nejnovejsi-videa",
  "/plucne-ventilatory-velmi-mudra-zivot-zachranujuca-masina":
    "/nejnovejsi-videa",
  "/chlpate-vajcia-s-chrumkavou-parmezanovou-krustou": "/nejnovejsi-videa",
  "/lupacky-s-makom": "/nejnovejsi-videa",
  "/rozhovor-s-primarom-urgentneho-odd-prva-vlna-je-za-nami-pacientov-ubuda":
    "/nejnovejsi-videa",
  "/slubovane-odmeny-zdravotnikom-aka-je-ich-realna-vyska": "/nejnovejsi-videa",
  "/grecke-tzatziky-hodia-sa-uplne-ku-vsetkemu": "/nejnovejsi-videa",
  "/spekgulas-netradicny-recept-s-porom-a-paprikou": "/nejnovejsi-videa",
  "/tradicne-prvorepublikove-dalamanky": "/nejnovejsi-videa",
  "/gejmhaus-5-5-20-00": "/nejnovejsi-videa",
  "/aktualna-situacia-na-porodnom-sale-ocami-porodnej-asistentky":
    "/nejnovejsi-videa",
  "/sunkofleky-jednoducha-klasika-a-trik-na-skoro-domace-fliacky":
    "/nejnovejsi-videa",
  "/aglio-olio-e-peperoncino-s-medvedim-cesnakom": "/nejnovejsi-videa",
  "/plnene-papriky-na-orientalny-sposob": "/nejnovejsi-videa",
  "/s2-e7-vyhnut-sa-rozchodu": "/nejnovejsi-videa",
  "/dnesna-cesko-nemecka-hranica-ma-este-zmysel-drzat-ludi-od-seba":
    "/nejnovejsi-videa",
  "/spargla-so-sunkou-vajickom-a-bylinkovym-maslom": "/nejnovejsi-videa",
  "/krkonosske-kyslo-z-domaceho-chleboveho-kvasku": "/nejnovejsi-videa",
  "/grissini-s-rozmarinom": "/nejnovejsi-videa",
  "/krekry-s-parmezanom-sypane-hrubou-solou": "/nejnovejsi-videa",
  "/naloz-s-joe-trendym-ep16-2020-vimeo-1080p-hd-mp4": "/nejnovejsi-videa",
  "/bielkoviny-aky-vplyv-maju-na-nase-zdravie": "/nejnovejsi-videa",
  "/jojo-efekt-preco-vacsina-diet-zlyhava": "/nejnovejsi-videa",
  "/slobodne-do-viedne-je-uzasne-znovu-vidiet-ludom-do-tvare":
    "/nejnovejsi-videa",
  "/cinska-hneda-omacka-tipy-pre-naozaj-autenticku-chut": "/nejnovejsi-videa",
  "/co-so-zvysnymi-varenymi-zemiakmi-zemiakove-placky": "/nejnovejsi-videa",
  "/s2-e8-vykopnut-dievca-na-jednu-noc": "/nejnovejsi-videa",
  "/milujem-ta-pucinka-diel-1": "/nejnovejsi-videa",
  "/ako-vyuzit-spargla-bezo-zvysku-sparglovy-zaklad-zo-supiek-a-odlomkov":
    "/nejnovejsi-videa",
  "/milujem-ta-pucinka-diel-2": "/nejnovejsi-videa",
  "/milujem-ta-pucinka-diel-3": "/nejnovejsi-videa",
  "/ako-zabijat-cas-pocas-pandnemie-hry-socialne-siete-porno-moznosti-su-nekonecne":
    "/nejnovejsi-videa",
  "/psy-mozu-pomoct-v-boji-s-covidom-19-projekt-potrebuje-pozitivne-vzorky":
    "/nejnovejsi-videa",
  "/nizkosacharidove-zemle": "/nejnovejsi-videa",
  "/zapeceny-bochnik-so-syrom-zo-stareho-peciva-vykuzlite-chrumkavu-dobrotu":
    "/nejnovejsi-videa",
  "/tabouleh-cestovinovy-salat-so-zeleninou-a-bylinkami": "/nejnovejsi-videa",
  "/domace-cesto-na-quiche-jednoducha-a-odlahcena-varianta":
    "/nejnovejsi-videa",
  "/prave-karlovarske-rozky-plne-lahodneho-masla": "/nejnovejsi-videa",
  "/na-pripadnu-druhu-vlnu-sme-dobre-pripraveni": "/nejnovejsi-videa",
  "/udalost-roka-konecne-otvorene-zahradky": "/nejnovejsi-videa",
  "/velky-navrat-serialu-horna-dolna": "/nejnovejsi-videa",
  "/napln-do-slaneho-kolaca-tipy-pre-lepsiu-chut-aj-konzistenciu":
    "/nejnovejsi-videa",
  "/gejmhaus-19-5-20-00": "/nejnovejsi-videa",
  "/v-laviciach-2-masmedia-mix-h264-1500kbps-576p-mov": "/nejnovejsi-videa",
  "/s2-e9-nezvracat-v-taxiku": "/nejnovejsi-videa",
  "/bryndzove-speclusky-obed-hotovy-do-10-minut": "/nejnovejsi-videa",
  "/adela-vinczeova-v-karantene-som-zistila-ako-dobre-som-sa-vydala":
    "/nejnovejsi-videa",
  "/dano-dangl-preco-mi-mafiani-zapalili-auto": "/nejnovejsi-videa",
  "/milujem-ta-pucinka-diel-4": "/nejnovejsi-videa",
  "/retazovka-03-online-03-mp4": "/nejnovejsi-videa",
  "/alkohol-v-akom-mnozstve-je-prospesny-a-moze-byt-vobec-prospesny":
    "/nejnovejsi-videa",
  "/velvyslanec-v-litve-krasna-krajina-o-ktorej-vieme-tak-malo":
    "/nejnovejsi-videa",
  "/velvyslanec-v-zambii-kvoli-okupacii-v-roku-68-som-rozplakal-byvaleho-prezidenta":
    "/nejnovejsi-videa",
  "/nie-je-ruska-ako-ruska-ako-dokaze-ochranit-kus-utierky-alebo-tricka":
    "/nejnovejsi-videa",
  "/pivny-chlieb-so-syrom-a-udenym": "/nejnovejsi-videa",
  "/velvyslanec-v-kuvajte-kedy-som-sa-za-ceskeho-statnika-naozaj-hanbil":
    "/nejnovejsi-videa",
  "/gejmhaus-26-5-20-00": "/nejnovejsi-videa",
  "/elixir-laska-ako-naozaj-funguje-tato-tekuta-radostna-energia":
    "/nejnovejsi-videa",
  "/velvyslanec-v-luxembursku-z-ceska-do-prace-na-lietadle-zlin-a-vecer-zase-spat":
    "/nejnovejsi-videa",
  "/pilotovany-start-falcon-9-crew-dragon-dm-2-start-odlozeny":
    "/nejnovejsi-videa",
  "/crew-dragon-je-pripraveny-ale-co-pocasie": "/nejnovejsi-videa",
  "/europske-servisne-moduly-lodi-orion": "/nejnovejsi-videa",
  "/laco-deczi-v-mexiku-som-dostal-horucku-zena-uz-zhanala-lacnu-rakvu":
    "/nejnovejsi-videa",
  "/spermiogram-ake-to-je-masturbovat-na-povel": "/nejnovejsi-videa",
  "/bonus-jak-je-mozne-zvysit-kvalitu-spermatu": "/nejnovejsi-videa",
  "/bonus-tvarohova-i-lekvarova-napln-a-sviatocne-drobky": "/nejnovejsi-videa",
  "/exkluzivne-ms-v-ladovom-hokeji-2020-finale-cesko-rusko-31-5-20-00":
    "/nejnovejsi-videa",
  "/hokejove-ms-2020-finale-cesko-rusko-v-nedelu": "/nejnovejsi-videa",
  "/rustikalna-bageta": "/nejnovejsi-videa",
  "/crew-dragon-dm-2-pripojenie-k-iss-31-5-16-29": "/nejnovejsi-videa",
  "/ako-martin-dejdar-zistil-ze-je-ms-v-hokeji-zavolal-davidovi-pastrnakovi":
    "/nejnovejsi-videa",
  "/pecene-kuracie-kridla-jednoduchsie-to-uz-snad-nejde": "/nejnovejsi-videa",
  "/preco-sa-virus-tak-rychlo-siril-bol-ischgl-epicentrom": "/nejnovejsi-videa",
  "/martin-dejdar-v-55-rokoch-sa-zo-mna-stal-youtuber": "/nejnovejsi-videa",
  "/obrazom-start-prveho-pilotovaneho-crew-dragonu": "/nejnovejsi-videa",
  "/gejmhaus-2-6-20-00": "/nejnovejsi-videa",
  "/za-kuzelnu-kavu-konecne-draho-zaplati-niekto-iny-ako-zakaznik":
    "/nejnovejsi-videa",
  "/volame-ruskemu-asi-manazerovi-a-zase-neverime-vlastnym-usiam":
    "/nejnovejsi-videa",
  "/prva-lod-crew-dragon-s-posadkou": "/nejnovejsi-videa",
  "/velvyslanec-v-irane-tisice-bankoviek-nejde-pocitat-v-ochrannych-rukaviciach":
    "/nejnovejsi-videa",
  "/thomas-pesquet-trenuje-na-druhu-misiu": "/nejnovejsi-videa",
  "/maslove-susienky-ktore-vydrzia-az-3-mesiace": "/nejnovejsi-videa",
  "/emg-vysetrenie-syndromu-karpalneho-tunela": "/nejnovejsi-videa",
  "/sisa-sklovska": "/nejnovejsi-videa",
  "/start-falconu-plny-zaujimavosti": "/nejnovejsi-videa",
  "/pecene-redkovky-delikatna-priloha-ku-kurcatu-alebo-rybam":
    "/nejnovejsi-videa",
  "/domace-toastovy-chlieb": "/nejnovejsi-videa",
  "/kosmatice-vyprazane-kvety-bazy-ciernej-v-cesticku": "/nejnovejsi-videa",
  "/vyrobit-cerstvy-domaci-syr-je-hracka-priprava-zaberie-3-minuty":
    "/nejnovejsi-videa",
  "/ako-smiesne-vyzera-potvrdenie-o-bezinfekcnosti-ktorym-sa-mate-preukazovat-na-hraniciach":
    "/nejnovejsi-videa",
  "/zero-waste-nauka-o-spolocnosti": "/nejnovejsi-videa",
  "/gejmhaus-9-6-20-00": "/nejnovejsi-videa",
  "/balzam-na-pery-za-par-eur-po-ktorom-vraj-stratite-chut-do-jedla":
    "/nejnovejsi-videa",
  "/bonus-kozmeticky-produkt-nesmie-slubovat-fyziologicke-zmeny":
    "/nejnovejsi-videa",
  "/bohemka-je-a-bude-trebars-aj-z-rebrika-co-vymysli-skalny-fanusik-ked-nemoze-na-stadion":
    "/nejnovejsi-videa",
  "/letne-kolac-zo-spaldovej-muky-nikto-nespozna-ze-tam-je":
    "/nejnovejsi-videa",
  "/mimoriadne-detailny-pohlad-na-planetku-bennu": "/nejnovejsi-videa",
  "/dva-prichody-prvych-stupnov-behom-par-dni": "/nejnovejsi-videa",
  "/ryzovy-salat-s-kuracim-rychla-priprava-bezne-suroviny": "/nejnovejsi-videa",
  "/pripravy-na-vysoku-kadenciu-startov-rakety-electron": "/nejnovejsi-videa",
  "/akupunktura-ako-vyliecit-alternativnou-cestou-nielen-bolest-chrbta":
    "/nejnovejsi-videa",
  "/bonus-akupunktura-nie-je-sarlatanstvo-chodia-za-mnou-aj-lekari":
    "/nejnovejsi-videa",
  "/domace-chrumkave-rozky": "/nejnovejsi-videa",
  "/marek-slobodnik-u-dana-pribana-bol-za-problemistu-potom-podnikol-vlastnu-expediciu":
    "/nejnovejsi-videa",
  "/dalsie-diely-novej-rakety-vulcan": "/nejnovejsi-videa",
  "/starship-sn7-otestuje-novu-ocel": "/nejnovejsi-videa",
  "/ceresnova-omacka-k-masu-s-tajnou-ingredienciou": "/nejnovejsi-videa",
  "/kysnuty-cucoriedkovy-kolac-na-plech": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-8-13-6-11-21": "/nejnovejsi-videa",
  "/bazovy-sirup-chuti-skvele-a-vyroba-je-velmi-jednoducha-a-rychla":
    "/nejnovejsi-videa",
  "/spickova-vonava-hrianka": "/nejnovejsi-videa",
  "/raketa-electron-vyniesla-pat-druzic": "/nejnovejsi-videa",
  "/cestoviny-inak-s-hraskom-a-nezvycajnymi-surovinami": "/nejnovejsi-videa",
  "/rover-viper-preskuma-juzny-pol-mesiaca": "/nejnovejsi-videa",
  "/melonovy-salat-ktorym-ohromite-kazdu-navstevu": "/nejnovejsi-videa",
  "/rock-for-people-home-vypsana-fixa-v-pardubiciach-17-6-19-00":
    "/nejnovejsi-videa",
  "/esa-a-nasa-dopravi-kusok-marsu-na-zem": "/nejnovejsi-videa",
  "/utajeny-biznis-petra-havlicka": "/nejnovejsi-videa",
  "/bonus-petr-havlicek-objednava-produkt-ktory-sam-nedobrovolne-propaguje":
    "/nejnovejsi-videa",
  "/dentalna-hygiena-preco-je-nutne-pravidelne-odstranovat-zubny-kamen":
    "/nejnovejsi-videa",
  "/webbov-teleskop-si-pretiahol-krk": "/nejnovejsi-videa",
  "/rock-for-people-home-open-air-v-hradci-kralove-20-6-14-30":
    "/nejnovejsi-videa",
  "/transport-segmentov-pre-rakety-sls-a-omega": "/nejnovejsi-videa",
  "/slaninova-struhanka-na-ochutenie-zeleniny-alebo-cestovin":
    "/nejnovejsi-videa",
  "/odlahcene-cestoviny-gnudi-malo-zname-ale-velmi-dobre": "/nejnovejsi-videa",
  "/martanskej-vozitko-perseverance-je-zbalene": "/nejnovejsi-videa",
  "/nekonciaci-prace-na-zakladni-spacex": "/nejnovejsi-videa",
  "/ricottovo-kokosove-knedle-s-pecenymi-jahodami": "/nejnovejsi-videa",
  "/solar-orbiter-prvykrat-blizko-slnka": "/nejnovejsi-videa",
  "/prirodne-tablety-podporuju-sustredenie-a-reakcie-ale-cielia-velmi-zlym-smerom":
    "/nejnovejsi-videa",
  "/sviezi-uhorkovo-rezancovy-salat": "/nejnovejsi-videa",
  "/cina-dokoncila-globalny-navigacny-system-beidou": "/nejnovejsi-videa",
  "/lekvar-cesky-narodny-poklad-v-rukach-priemyselnych-vyrobcov":
    "/nejnovejsi-videa",
  "/kahira-ludia-ktori-ziju-na-cintorine-s-pozostatkami-svojich-predkov":
    "/nejnovejsi-videa",
  "/destrukcia-a-prichod-noveho-prototypu-starship": "/nejnovejsi-videa",
  "/nakladany-cesnak": "/nejnovejsi-videa",
  "/dermatologia-pat-krokov-ako-si-samovysetrit-materske-znamienka":
    "/nejnovejsi-videa",
  "/bonus-ako-vznika-neprijemna-lupienka-a-ako-ju-mozno-liecit":
    "/nejnovejsi-videa",
  "/silny-a-vydatny-vyvar-pho-nielen-pre-vegetarianov": "/nejnovejsi-videa",
  "/vystup-do-volneho-priestoru-z-iss": "/nejnovejsi-videa",
  "/superdetailny-pohlad-na-odberne-miesto": "/nejnovejsi-videa",
  "/ako-sa-rano-nastartovat-a-zacat-den-s-dobrou-naladou": "/nejnovejsi-videa",
  "/karfiol-peceny-vcelku": "/nejnovejsi-videa",
  "/slavky-na-vine-jemna-vona-mora-a-skvela-chut": "/nejnovejsi-videa",
  "/strukturalne-skusky-sls-su-na-konci": "/nejnovejsi-videa",
  "/vega-s-dispenzerom-z-brna-ma-skratka-smolu": "/nejnovejsi-videa",
  "/start-falcon-9-gpsiii-sv03-30-6-22-10": "/nejnovejsi-videa",
  "/baba-ganoush-sviezi-a-pikantny-dip-z-pecenych-baklazanov":
    "/nejnovejsi-videa",
  "/nie-je-kaloria-ako-kaloria-na-co-zabudame-pri-chudnuti":
    "/nejnovejsi-videa",
  "/nazivo-ako-rastu-huby": "/nejnovejsi-videa",
  "/ako-rastie-hribik-do-krasy-casozber": "/nejnovejsi-videa",
  "/polenta-ako-rychla-priloha-k-masu-alebo-zelenine": "/nejnovejsi-videa",
  "/dodatocna-skuska-padakov-starliner": "/nejnovejsi-videa",
  "/spaceshiptwo-nad-novym-mexikom": "/nejnovejsi-videa",
  "/nacapali-sme-obratnu-zlodejku-hribov-priamo-pri-cine": "/nejnovejsi-videa",
  "/prelet-nad-koroljovovym-kraterom": "/nejnovejsi-videa",
  "/nosne-rakety-dneper": "/nejnovejsi-videa",
  "/cestoviny-carbonara-s-krasne-hebkou-omackou": "/nejnovejsi-videa",
  "/rychle-cuketove-zemiakove-placky-s-kremovym-dipom": "/nejnovejsi-videa",
  "/dalsie-tipy-ako-napadito-vyuzit-cuketu": "/nejnovejsi-videa",
  "/bleskovy-dressing-na-salat-caesar": "/nejnovejsi-videa",
  "/zlyhanie-rakety-electron-pri-13-misii": "/nejnovejsi-videa",
  "/ploche-pecene-kura-krasne-prepecena-koze-a-lahke-porciovanie":
    "/nejnovejsi-videa",
  "/spodne-lemy-sa-chystaju-na-premierovu-sls": "/nejnovejsi-videa",
  "/falcon-9-vyniesol-navigacnu-druzicu-gps": "/nejnovejsi-videa",
  "/nova-izraelska-druzica-po-4-rokoch": "/nejnovejsi-videa",
  "/kuskus-so-struhanou-brokolicou-a-orientalnym-korenim": "/nejnovejsi-videa",
  "/krevety-na-cesnaku-mrazene-vobec-nevadi-ale-dodrzujte-par-zasad":
    "/nejnovejsi-videa",
  "/aky-pristup-by-mal-mat-doveryhodny-vyzivovy-poradca": "/nejnovejsi-videa",
  "/nebezpecne-chrapanie-co-odhali-spankove-laboratorium-o-kvalite-spanku":
    "/nejnovejsi-videa",
  "/najstavnatejsi-kuraci-zavitok": "/nejnovejsi-videa",
  "/vysetrovanie-problemov-starliner-skoncilo": "/nejnovejsi-videa",
  "/zostavovanie-pomocnych-motorov-prvej-rakety-sls": "/nejnovejsi-videa",
  "/ocne-vysetrenie-ako-vcas-zachytit-zeleny-a-sedy-zakal": "/nejnovejsi-videa",
  "/jednoducha-jahodova-zmrzlina-s-dokonalou-konzistenciou":
    "/nejnovejsi-videa",
  "/najlepsie-uprava-karfiolu-aku-ste-kedy-ochutnali": "/nejnovejsi-videa",
  "/kalerabova-kapusta-letna-verzia-znamej-klasiky-je-hotova-raz-dva":
    "/nejnovejsi-videa",
  "/moravsky-vrabec-poctiva-klasika-s-minimom-prace": "/nejnovejsi-videa",
  "/dalsie-pokroky-na-boca-chica": "/nejnovejsi-videa",
  "/bleskova-hubova-omacka-s-masovymi-knedlickami-bez-prace":
    "/nejnovejsi-videa",
  "/premiera-rakety-ariane-6-sa-odsuva": "/nejnovejsi-videa",
  "/letny-salat-z-provencie-slane-a-sladke-ingrediencie-sa-rozplyvaju-na-jazyku":
    "/nejnovejsi-videa",
  "/krehky-a-vonavy-karfiol-s-gratinovanou-korkou": "/nejnovejsi-videa",
  "/desat-rokov-sledovania-slnka": "/nejnovejsi-videa",
  "/30-rokov-stare-motory-fungovali-vzorne": "/nejnovejsi-videa",
  "/lahodne-pecene-papriky-s-kroketami-z-kozieho-syra": "/nejnovejsi-videa",
  "/vrtulnik-ingenuity-pre-mars": "/nejnovejsi-videa",
  "/pristroj-ktory-na-marse-vyrobi-kyslik": "/nejnovejsi-videa",
  "/egresova-salsa-nielen-ku-grilovanemu-bielemu-masu": "/nejnovejsi-videa",
  "/najchrumkavejsie-bravcove-chrumky-s-jablkovou-omackou": "/nejnovejsi-videa",
  "/letna-pecena-ryza-s-kuracim-a-zeleninou": "/nejnovejsi-videa",
  "/cinska-misia-na-mars-caka-na-rampe": "/nejnovejsi-videa",
  "/to-najlepsie-od-romana-vanka-a-petra-havlicka-zdrava-magicka-kocka":
    "/nejnovejsi-videa",
  "/ako-spracovat-cely-filet-lososa-na-tri-rozne-pokrmy": "/nejnovejsi-videa",
  "/prva-medziplanetarna-sonda-spojenych-arabskych-emiratov":
    "/nejnovejsi-videa",
  "/stavnaty-peceny-losos-ako-docielite-extra-chrumkavu-kozu":
    "/nejnovejsi-videa",
  "/protesty-v-sofii-proti-bulharskej-mafii-uz-toho-bolo-dost":
    "/nejnovejsi-videa",
  "/ako-namorit-zverinu-maso-krasne-zmakne": "/nejnovejsi-videa",
  "/falcon-9-posluzil-juhokorejskej-armade": "/nejnovejsi-videa",
  "/skuska-obyvatelnosti-crew-dragonu": "/nejnovejsi-videa",
  "/silny-hovadzi-vyvar-ako-zaklad-polievok-a-omacok": "/nejnovejsi-videa",
  "/cina-vypravila-svoju-misiu-na-mars": "/nejnovejsi-videa",
  "/zelerovy-steak-a-zelerove-pyre-uplne-prekvapiva-chut": "/nejnovejsi-videa",
  "/predposledny-diel-rakety-sls-mieri-na-kozmodrom": "/nejnovejsi-videa",
  "/koprovka-s-vajcom-a-skorymi-zemiacikmi": "/nejnovejsi-videa",
  "/plnene-pecene-paradajky-so-syrom-a-bylinkami": "/nejnovejsi-videa",
  "/atlas-v-541-perseverance-30-7-13-50": "/nejnovejsi-videa",
  "/pristatie-crew-dragon-dm-2-2-8-2020-20-10": "/nejnovejsi-videa",
  "/prvy-tohtorocny-proton-m-na-rampe": "/nejnovejsi-videa",
  "/bruschetta-al-pomodoro-zdravsi-a-chrumkavejsi-chlebicek-po-taliansky":
    "/nejnovejsi-videa",
  "/jednoduchy-kysnuty-kolac-s-ovocim": "/nejnovejsi-videa",
  "/interier-lode-od-virgin-galactic": "/nejnovejsi-videa",
  "/ako-sa-skor-falsovalo-mlieko": "/nejnovejsi-videa",
  "/co-bol-povodny-narodny-pokrm-a-preco-ho-vytesnilo-vepro-knedlo-zelo":
    "/nejnovejsi-videa",
  "/ako-sa-ocitol-kecup-v-ceskej-kuchyni": "/nejnovejsi-videa",
  "/vynikajuci-baklazanovy-kaviar-so-stratenym-vajcom": "/nejnovejsi-videa",
  "/ako-jednoducho-zahustit-kazdu-omacku-ulahci-a-urychli-vam-pracu":
    "/nejnovejsi-videa",
  "/neobycajny-ovocny-salat-s-chrumkavymi-tortillami": "/nejnovejsi-videa",
  "/plnene-zapekane-baklazany-s-naplnou-presne-podla-vasej-chuti":
    "/nejnovejsi-videa",
  "/nacvik-v-bazene": "/nejnovejsi-videa",
  "/obrazom-najlepsie-fotky-zo-startu-perseverance": "/nejnovejsi-videa",
  "/prvy-pilotovany-crew-dragon-sa-vracia-domov": "/nejnovejsi-videa",
  "/ako-spracovat-baklazan-aby-mal-vyraznejsiu-chut": "/nejnovejsi-videa",
  "/gaspacho-rychla-a-osviezujuca-polievka-z-pecenych-paprik":
    "/nejnovejsi-videa",
  "/ako-lahko-olupat-papriky": "/nejnovejsi-videa",
  "/modna-policia-final-h264-mp4": "/nejnovejsi-videa",
  "/tortilly-miesto-palaciniek-pripravte-originalnu-slanu-tortu":
    "/nejnovejsi-videa",
  "/domace-pecene-belgicke-hranolky": "/nejnovejsi-videa",
  "/prelet-nad-kraterom-jazero": "/nejnovejsi-videa",
  "/skok-starship-sn5-do-150-metrov": "/nejnovejsi-videa",
  "/hurikan-isaias-pod-dohladom-druzic": "/nejnovejsi-videa",
  "/deviata-ostra-varka-druzic-starlink": "/nejnovejsi-videa",
  "/prvy-pilotovany-crew-dragon-je-spat": "/nejnovejsi-videa",
  "/50-start-rakety-dlhy-pochod-2d": "/nejnovejsi-videa",
  "/polievka-minestrone-letna-verzia-podla-koko": "/nejnovejsi-videa",
  "/zemiaciky-su-out-upecte-si-chrumkave-kelove-chipsy-so-syrom":
    "/nejnovejsi-videa",
  "/ako-pripravit-na-oslavu-dokonaly-roastbeef": "/nejnovejsi-videa",
  "/modul-nauka-mieri-na-kozmodrom": "/nejnovejsi-videa",
  "/sladke-knedlicky-vela-tvarohu-a-ovocia-malo-cesta": "/nejnovejsi-videa",
  "/kysnute-slivkove-knedle-s-tvarohom-a-makom": "/nejnovejsi-videa",
  "/brusnicovy-kompot-vyborny-ku-svieckovej-pastetam-i-zverine":
    "/nejnovejsi-videa",
  "/gazpacho-letna-ladova-polievka-uzasne-osviezi": "/nejnovejsi-videa",
  "/najchrumkavejsia-plnka-tipy-pre-dokonaly-vysledok": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-10-18-8-16-31": "/nejnovejsi-videa",
  "/je-nasim-skutocnym-narodnym-jedlom-obycajny-vyprazany-syr":
    "/nejnovejsi-videa",
  "/kapustova-sekana-aj-neoblubena-zelenina-moze-chutit-skvele":
    "/nejnovejsi-videa",
  "/syry-v-pyzame-z-listkoveho-cesta-s-prekvapenim-vnutri": "/nejnovejsi-videa",
  "/zostali-vam-uvarene-spagety-vykuzlite-z-nich-vajecnu-frittatu":
    "/nejnovejsi-videa",
  "/cerstve-makrely-pecene-v-marinade-su-lacne-a-chutovo-uzasne":
    "/nejnovejsi-videa",
  "/posledny-cvicny-zostup-k-povrchu-bennu": "/nejnovejsi-videa",
  "/obrazom-15-rokov-od-startu-sondy-mro": "/nejnovejsi-videa",
  "/ariane-5-vyniesla-3-druzice": "/nejnovejsi-videa",
  "/prvy-zazih-gem-63xl": "/nejnovejsi-videa",
  "/zbohom-htv-9": "/nejnovejsi-videa",
  "/desiata-porcie-ostrych-starlinkov": "/nejnovejsi-videa",
  "/ceska-podvodna-zakladna-otestovana": "/nejnovejsi-videa",
  "/tattoo-polepsovna-ako-sa-zbavit-mena-svojho-ex": "/nejnovejsi-videa",
  "/tetovanie-cez-jazvu-slavny-fotograf-michael-kratochvil-zvolil-originalne-obrazky":
    "/nejnovejsi-videa",
  "/nosi-ho-aj-miley-cyrus-ako-spoznat-single-needle-tetovanie":
    "/nejnovejsi-videa",
  "/vyuzite-zvysky-karfiolu-do-sladko-kyslej-marinady": "/nejnovejsi-videa",
  "/nazivo-zjazd-hate-na-vltave": "/nejnovejsi-videa",
  "/ignorant-tattoo-kerky-ktore-preslavil-ninja-z-die-antwoord":
    "/nejnovejsi-videa",
  "/tip-na-perfektne-prepecene-kura-a-plnku-uz-nemusite-stale-pozerat-do-rury":
    "/nejnovejsi-videa",
  "/osviezujuca-spasa-v-letnej-horucave-domaca-uhorkova-limonada":
    "/nejnovejsi-videa",
  "/lahky-a-osviezujuci-letny-salat-z-marinovanych-cukiet": "/nejnovejsi-videa",
  "/start-delta-iv-heavy-nrol-44-27-8-6-12": "/nejnovejsi-videa",
  "/grilovany-kukuricny-klas-s-pikantnym-chipottle-maslom": "/nejnovejsi-videa",
  "/zelene-chrumkave-fazulky-so-syrovou-omackou-a-slaninovou-struhankou":
    "/nejnovejsi-videa",
  "/pecene-marinovane-huby-chutia-ako-z-grilu-a-genialne-nahradia-maso":
    "/nejnovejsi-videa",
  "/teple-alebo-studene-napoje": "/nejnovejsi-videa",
  "/na-poslednu-chvilu-zastaveny-start": "/nejnovejsi-videa",
  "/hrejiva-tekvicova-polievka-so-zazvorom-a-pomarancom": "/nejnovejsi-videa",
  "/ma-zmysel-drzat-pevne-stanoveny-jedalnicek-s-gramazou": "/nejnovejsi-videa",
  "/cola-zero-je-variant-bez-cukru-zdravsi": "/nejnovejsi-videa",
  "/veganske-tetovanie-kapusta-a-vegan-tattoo-ovladli-polepsovne":
    "/nejnovejsi-videa",
  "/priprava-na-zazih-starship-sn6": "/nejnovejsi-videa",
  "/tropicka-burka-laura-pohladom-z-iss": "/nejnovejsi-videa",
  "/kde-sa-vzala-ceska-cina-napodobenina-sojovky-do-kazdej-domacnosti":
    "/nejnovejsi-videa",
  "/najdi-10-rozdielov-balkanska-klasika-vs-ceske-cevapcici":
    "/nejnovejsi-videa",
  "/slusny-ulet-talianska-pizza-podla-ceskych-kucharskych-knih":
    "/nejnovejsi-videa",
  "/zatracovana-pecen-pritom-to-byvala-lahodkova-podpultovka":
    "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-11-3-septembra-14-46": "/nejnovejsi-videa",
  "/ludovit-xiv-slavny-zhyraly-aj-nenavideny-kral-slnko": "/nejnovejsi-videa",
  "/ananas-ako-skoncilo-prepychove-ovocie-vo-zvratenych-pokrmoch":
    "/nejnovejsi-videa",
  "/ako-ceske-krajiny-rozsirili-slavu-madarskeho-gulasa": "/nejnovejsi-videa",
  "/duhova-babovka-s-cucoriedkami-netradicna-o-to-lepsia": "/nejnovejsi-videa",
  "/cubesat-ktory-preskuma-planetku": "/nejnovejsi-videa",
  "/japonska-raketa-h3-sa-blizi": "/nejnovejsi-videa",
  "/lahky-vanilkovy-krem-s-prekvapenim": "/nejnovejsi-videa",
  "/aku-desiatu-si-pripravit-na-celodenny-vylet": "/nejnovejsi-videa",
  "/ako-tetovat-vegansky-specialne-farby-bez-stopy-po-zvieratkach":
    "/nejnovejsi-videa",
  "/zemiakovy-krem-s-hubami-a-parmezanovymi-chipsami": "/nejnovejsi-videa",
  "/mate-prebytok-paradajok-upecte-ich-hodia-sa-do-salatov-cestovin-alebo-kuskusu":
    "/nejnovejsi-videa",
  "/plnene-zapekane-cukety-tipy-na-dokonaly-vysledok": "/nejnovejsi-videa",
  "/terminy-skusok-starliner": "/nejnovejsi-videa",
  "/novinky-okolo-rakety-super-heavy": "/nejnovejsi-videa",
  "/orion-je-certifikovany-na-let-k-mesiacu": "/nejnovejsi-videa",
  "/dispenzer-z-brna-splnil-svoju-ulohu": "/nejnovejsi-videa",
  "/starship-sn6-skocila-do-150-metrov": "/nejnovejsi-videa",
  "/na-iss-hladaju-drobny-unik-vzduchu": "/nejnovejsi-videa",
  "/po-polstoroci-otvoreny-letovy-koridor-z-floridy": "/nejnovejsi-videa",
  "/nakladane-pickles-uhorkova-chutovka-bez-zavarania": "/nejnovejsi-videa",
  "/ako-urobit-z-velkych-zemiakov-dve-prilohy-naraz": "/nejnovejsi-videa",
  "/hubova-prazenica-de-luxe": "/nejnovejsi-videa",
  "/vacsia-uroda-hribov-takto-ich-zamrazite-spravne": "/nejnovejsi-videa",
  "/amaterska-kerka-od-sestry-v-opitosti-tattoo-polepsovna-opravuje-rodinne-puta":
    "/nejnovejsi-videa",
  "/zelerova-polievka-ako-vyuzit-3-ingrediencie-v-2-receptoch":
    "/nejnovejsi-videa",
  "/kim-ir-sen-kruty-vodca-v-najizolovanejsej-krajine-sveta":
    "/nejnovejsi-videa",
  "/jacqueline-kennedy-elegantna-kraska-sa-stala-ikonou-americkych-dejin-a-ocarila-cely-svet":
    "/nejnovejsi-videa",
  "/prototyp-urceny-k-destrukcii": "/nejnovejsi-videa",
  "/crew-dragon-zvezie-aj-europanov": "/nejnovejsi-videa",
  "/je-je-to-iste-omega-nevznikne": "/nejnovejsi-videa",
  "/spojenie-lode-orion-s-adapterom": "/nejnovejsi-videa",
  "/paradajkovy-dip-bagnet-k-masu-chuti-lepsie-ako-tatarka":
    "/nejnovejsi-videa",
  "/co-ne-robit-ked-chcete-rychlo-schudnut-na-svadbu-alebo-do-plaviek":
    "/nejnovejsi-videa",
  "/bonus-04-edit-h264-720p-mp4": "/nejnovejsi-videa",
  "/pod-co-by-sa-petr-havlicek-nepodpisal-ani-za-10-milionov-czk":
    "/nejnovejsi-videa",
  "/skrotte-horu-spenatu-do-vlacnej-naplne-alebo-smotanovej-prilohy":
    "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-12-termin-neznamy": "/nejnovejsi-videa",
  "/pecene-bravcove-koleno-mudra-rura-ho-pripravi-dokonale":
    "/nejnovejsi-videa",
  "/na-co-ma-dlhodobe-sedenie-velmi-negativny-vplyv": "/nejnovejsi-videa",
  "/polievka-z-pecenych-paradajok-pripravte-si-aj-vlastny-bazalkovy-olej":
    "/nejnovejsi-videa",
  "/paradajkove-pyre-jemne-alebo-rustikalne-oboje": "/nejnovejsi-videa",
  "/totalna-rychlovka-stroganoff-z-muchotravky": "/nejnovejsi-videa",
  "/finger-trouble-ake-uskalia-ma-tetovanie-na-prsty": "/nejnovejsi-videa",
  "/rychla-tortillizza-hribova-pizza-po-ktorej-sa-zaprasi": "/nejnovejsi-videa",
  "/live-video-21-09-2020-15-00": "/nejnovejsi-videa",
  "/cuketka-frankfurtska-polevka-mall-sk-mp4": "/nejnovejsi-videa",
  "/divoke-anjele-predstavovacka-03-lienka-mp4": "/nejnovejsi-videa",
  "/kavej-diel-ep22-2020-vimeo-1080p-hd-mp4": "/nejnovejsi-videa",
  "/vyprazany-zvitok-do-vyvaru-uz-nebudete-chciet-nic-ine": "/nejnovejsi-videa",
  "/trik-na-extra-chrumkave-batatove-hranolky": "/nejnovejsi-videa",
  "/hubove-orzotto-rychle-a-krasne-kremove": "/nejnovejsi-videa",
  "/jesenne-caprese-bez-paradajok-vyzera-bozsky-chuti-este-lepsie":
    "/nejnovejsi-videa",
  "/najtensie-superzelene-spenatove-palacinky": "/nejnovejsi-videa",
  "/co-vyvolava-chut-na-sladke-a-cim-ju-mozno-zahnat": "/nejnovejsi-videa",
  "/skuska-naklanania-motorov-rakety-sls": "/nejnovejsi-videa",
  "/maketa-lunarneho-landeru-alpaca": "/nejnovejsi-videa",
  "/druhy-cinsky-start-z-morskej-plosiny": "/nejnovejsi-videa",
  "/manipulacia-so-segmentmi-motorov-rakety-sls": "/nejnovejsi-videa",
  "/staticky-zazih-rakety-alpha": "/nejnovejsi-videa",
  "/prototyp-sn7-1-konecne-praskol": "/nejnovejsi-videa",
  "/prva-europska-znovupouzitelna-raketa": "/nejnovejsi-videa",
  "/premysl-otakar-i-a-zlata-bula-sicilska-mimoriadny-dokument-premenil-cesku-zem-v-sebavedomu-europsku-mocnost":
    "/nejnovejsi-videa",
  "/hummus-z-pecenej-tekvice-pre-zdrave-vecerne-zobanie": "/nejnovejsi-videa",
  "/super-jednoducha-malinova-omacka": "/nejnovejsi-videa",
  "/jednohubky-z-pecenych-paprik-a-rychla-paprikova-omacka":
    "/nejnovejsi-videa",
  "/chutovo-bohate-karfiolove-kari-maso-vam-tu-vobec-nebude-chybat":
    "/nejnovejsi-videa",
  "/piccalilli-nakladana-zelenina-v-sladko-kyslej-omacke-na-chladne-dni":
    "/nejnovejsi-videa",
  "/ako-sa-pocas-dojcenia-prirodzene-vratit-do-svojej-povodnej-formy":
    "/nejnovejsi-videa",
  "/staticky-zazih-obrieho-motora": "/nejnovejsi-videa",
  "/hera-europska-misia-k-dvojplanetke": "/nejnovejsi-videa",
  "/nebud-ovca-sleduj-havrana-divoku-party-k-osemnactinam-zehli-tattoo-polepsovna":
    "/nejnovejsi-videa",
  "/necakana-dobrota-zapekane-cakanky-so-slaninou-a-syrom": "/nejnovejsi-videa",
  "/jesenna-slivkova-omacka-k-masu": "/nejnovejsi-videa",
  "/zamatova-tekvicova-svieckova-tradicny-nedelny-obed-tak-trochu-netradicne":
    "/nejnovejsi-videa",
  "/nacvik-vystupu-na-mesiac-v-bazene": "/nejnovejsi-videa",
  "/na-bajkonure-sa-chysta-nova-posadka": "/nejnovejsi-videa",
  "/zemiakove-langose-vyprazane-klasika-zo-stanku-ale-domaca-a-lepsia":
    "/nejnovejsi-videa",
  "/chrumkave-zeleninove-chipsy": "/nejnovejsi-videa",
  "/tolk-in-inglis-mikela-celi-intimnym-otazkam": "/nejnovejsi-videa",
  "/bratia-masinovci-pribeh-zivy-aj-po-sedemdesiatich-rokoch":
    "/nejnovejsi-videa",
  "/prva-spanielska-opticka-snimkovacia-druzica": "/nejnovejsi-videa",
  "/europsko-americka-druzica-na-meranie-morskej-hladiny": "/nejnovejsi-videa",
  "/cervie-logo-nasa-na-misii-artemis-i": "/nejnovejsi-videa",
  "/lod-cygnus-vezie-na-iss-potrebne-aj-novu-toaletu": "/nejnovejsi-videa",
  "/msolo-preskuma-zlozenie-materialov-na-mesiaci": "/nejnovejsi-videa",
  "/vynimocne-vajcia-na-vynimocne-vikendove-ranajky": "/nejnovejsi-videa",
  "/zelerova-remulada-k-bravcovemu-nic-lepsie-nenajdete": "/nejnovejsi-videa",
  "/pecene-sladke-cibulky-najjednoduchsie-pohostenie-vlastnej-vyroby":
    "/nejnovejsi-videa",
  "/cesnacka-podla-koko-silny-a-poctivy-vyprostovak-vas-postavi-na-nohy":
    "/nejnovejsi-videa",
  "/ako-si-pochutnaju-na-ruzickovom-keli-aj-muzi-a-deti-alebo-kelom-na-slanine-s-medom":
    "/nejnovejsi-videa",
  "/jablka-v-zupane-s-orechmi-a-medom-na-jesenny-vikendovy-stol":
    "/nejnovejsi-videa",
  "/strudla-v-bochniku-odlahceny-a-rychly-mini-dezert-ku-kave":
    "/nejnovejsi-videa",
  "/natierka-z-pecenej-repy-v-greckom-style": "/nejnovejsi-videa",
  "/cierna-krasa-nepodarenu-kerku-zakryje-najlepsie-blackwork":
    "/nejnovejsi-videa",
  "/dvanasta-varka-ostrych-druzic-starlink": "/nejnovejsi-videa",
  "/rusky-projekt-kozmickeho-jadroveho-tahaca": "/nejnovejsi-videa",
  "/fettuciny-s-jedinecnou-omackou-z-vlasskych-orechov": "/nejnovejsi-videa",
  "/druha-zmena-v-posadke-starliner": "/nejnovejsi-videa",
  "/zazih-europskeho-motora-na-tuhe-pohonne-latky": "/nejnovejsi-videa",
  "/priprava-prvej-ostrej-lodi-crew-dragon": "/nejnovejsi-videa",
  "/cuketka-kulajda-mp4": "/nejnovejsi-videa",
  "/petrzlen-v-hlavnej-ulohe-toto-nie-je-len-nejaka-nudna-polievka":
    "/nejnovejsi-videa",
  "/odklad-prveho-ostreho-crew-dragonu": "/nejnovejsi-videa",
  "/boh-sud-tattoo-polepsovna-zakryva-kerku-ktoru-mas-mozno-aj-ty":
    "/nejnovejsi-videa",
  "/zemiakove-rosti-jednoduchy-a-elegantny-variant-placiek":
    "/nejnovejsi-videa",
  "/kulajda-podla-sefkuchara-huby-jednoduche-posirovane-vajce-a-koprovy-olej":
    "/nejnovejsi-videa",
  "/luxusna-stavnata-kotleta-z-prestika": "/nejnovejsi-videa",
  "/4-dovody-preco-piect-kacicu-den-vopred": "/nejnovejsi-videa",
  "/slane-palacinky-vydatne-ranajky-pripravene-den-vopred": "/nejnovejsi-videa",
  "/vibracne-a-hlukove-testy-dalekohladu-jamesa-webba": "/nejnovejsi-videa",
  "/trinasty-skok-new-shepard-bol-uspesny": "/nejnovejsi-videa",
  "/najrychlejsia-pilotovana-lod-k-iss": "/nejnovejsi-videa",
  "/s-pomocou-venuse-o-krok-blizsie-k-merkuru": "/nejnovejsi-videa",
  "/sedem-startov-rakiet-falcon-za-mesiac": "/nejnovejsi-videa",
  "/komercna-prechodova-komora-bishop": "/nejnovejsi-videa",
  "/napoleon-bonaparte-muz-ktory-si-podmanil-celu-europu-konci-v-potupnom-vyhnanstve":
    "/nejnovejsi-videa",
  "/co-si-dat-k-jedlu-po-treningu-zalezi-od-druhu-fyzickej-aktivity":
    "/nejnovejsi-videa",
  "/krem-z-paprik-a-paradajok-chutna-spomienka-na-leto-krasne-zahreje":
    "/nejnovejsi-videa",
  "/vanilkova-panna-cotta-rychly-dezert-s-marhulovou-vychytavkou":
    "/nejnovejsi-videa",
  "/marhulove-pyre-jednoducha-priprava-nekonecne-moznosti-vyuzitia":
    "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-14-24-10-17-31": "/nejnovejsi-videa",
  "/ku-kozmickej-zrazke-nakoniec-nedoslo": "/nejnovejsi-videa",
  "/obria-plosina-zamierila-na-startovu-rampu": "/nejnovejsi-videa",
  "/po-195-dnoch-opat-na-zemi": "/nejnovejsi-videa",
  "/maso-na-hubach-pod-zemiakovou-pokrievkou": "/nejnovejsi-videa",
  "/cool-portrety-filmovych-hrdinov-linework-zmeni-ciary-v-dokonalost":
    "/nejnovejsi-videa",
  "/legendarny-houdini-kral-iluzii-a-majster-uniku-svojim-umenim-fascinoval-davy":
    "/nejnovejsi-videa",
  "/jesenne-variacie-pre-celu-rodinu-minimum-prace-minimum-riadu":
    "/nejnovejsi-videa",
  "/vyprazana-baby-mozzarella-s-paradajkami-na-party-aj-k-televizoru":
    "/nejnovejsi-videa",
  "/historicky-prvy-zazih-troch-motorov-raptor": "/nejnovejsi-videa",
  "/prvy-pokus-o-odber-vzoriek-z-bennu": "/nejnovejsi-videa",
  "/prerusovany-post-22-2-ake-prinosy-aj-uskalia-ma-jedno-jedlo-denne":
    "/nejnovejsi-videa",
  "/rozpeceny-camembert-s-cesnakom-idealna-chutovka-k-vinu":
    "/nejnovejsi-videa",
  "/citronove-zemiaky-pecene-na-jezka-luxusna-priloha-s-ktorou-oslnite":
    "/nejnovejsi-videa",
  "/boj-za-prava-zvierat-na-vlastnom-tele-aj-tetovanie-moze-menit-svet":
    "/nejnovejsi-videa",
  "/zabudnite-na-tatarku-paradna-pazitkova-omacka-sa-hodi-uplne-ku-vsetkemu":
    "/nejnovejsi-videa",
  "/ako-pocitat-kalorie-a-zistit-kolko-energie-a-zivin-mam-konzumovat":
    "/nejnovejsi-videa",
  "/dlhy-pochod-2c-vyniesol-styri-satelity": "/nejnovejsi-videa",
  "/novy-termin-misie-crew-1": "/nejnovejsi-videa",
  "/vydatna-fazulova-polievka-s-klobaskami-nahradi-aj-hlavne-jedlo":
    "/nejnovejsi-videa",
  "/ako-pripravit-hadi-mord-tajomny-a-neznamy-cierny-koren-chuti-ako-zakazana-lahodka":
    "/nejnovejsi-videa",
  "/raketa-electron-vyniesla-10-snimkovacich-druzic": "/nejnovejsi-videa",
  "/sonda-odobrala-z-planetky-bennu-dostatocne-mnozstvo-materialu":
    "/nejnovejsi-videa",
  "/kolkokrat-denne-treba-jest-aby-ste-schudli": "/nejnovejsi-videa",
  "/hraskova-polievka-zahreje-aj-osviezi-a-hotova-je-za-5-minut":
    "/nejnovejsi-videa",
  "/cesnakove-krutony-do-polievok-a-salatov": "/nejnovejsi-videa",
  "/kopa-padnych-dovodov-preco-nikdy-nemat-svadbu": "/nejnovejsi-videa",
  "/natierka-pivny-syr-pre-tych-ktorym-sa-cnie-po-krcme": "/nejnovejsi-videa",
  "/rydzo-ceska-kapustnica-s-paprikovou-klobasou": "/nejnovejsi-videa",
  "/genialny-talent-w-a-mozart-cesi-ho-zboznovali-vo-viedni-zomiera-v-osamelosti-a-biede":
    "/nejnovejsi-videa",
  "/kurca-z-jedneho-hrnca-stavnaty-a-vonavy-pokrm-plny-farieb-a-chuti":
    "/nejnovejsi-videa",
  "/ako-sa-da-maskrtit-pri-diete-petr-havlicek-ma-dobru-spravu":
    "/nejnovejsi-videa",
  "/ludstvo-je-na-iss-uz-20-rokov-bez-prerusenia": "/nejnovejsi-videa",
  "/ako-preskumat-prudke-zrazy-na-marse": "/nejnovejsi-videa",
  "/kysnuta-zemiakova-buchta-ako-priloha-aj-hlavne-jedlo": "/nejnovejsi-videa",
  "/testy-panelov-sondy-juice": "/nejnovejsi-videa",
  "/carpaccio-z-pecenej-repy-s-granatovym-jablkom-a-citronovym-dresingom":
    "/nejnovejsi-videa",
  "/jedinecny-domaci-kecup-z-cervenej-repy-hodi-sa-aj-k-masu-a-naozaj-nezostane":
    "/nejnovejsi-videa",
  "/premiera-cinskej-rakety-ceres-1": "/nejnovejsi-videa",
  "/pitvy-a-jazvy-v-morbidnej-tattoo-polepsovni-sa-zakryva-nepodarena-skarifikacia":
    "/nejnovejsi-videa",
  "/zapekane-vajcia-v-kokotkach-to-nie-je-nadavka-ale-pohodove-vikendove-ranajky":
    "/nejnovejsi-videa",
  "/skvela-priloha-k-svatomartinskej-husi-krupove-risotto-s-ruzickovym-kelom":
    "/nejnovejsi-videa",
  "/v-i-lenin-ako-vodca-bolsevikov-krvou-a-terorom-prebral-moc-v-najvacsej-krajine-sveta":
    "/nejnovejsi-videa",
  "/galore-ukaze-ako-spracovat-lunarny-regolit": "/nejnovejsi-videa",
  "/kurca-na-paprike-idealny-vikendovy-slavnostny-obed": "/nejnovejsi-videa",
  "/falcon-9-mesiac-po-zavade-motora-uspesne-vzlietol": "/nejnovejsi-videa",
  "/ako-spravne-uchovavat-pecivo-a-patri-vobec-do-mraznicky":
    "/nejnovejsi-videa",
  "/uzasne-zemiakove-knedle-ktore-sa-nikdy-nerozvaria": "/nejnovejsi-videa",
  "/sefkuchar-matteo-de-carli-tatarak-s-hluzovkou": "/nejnovejsi-videa",
  "/alberto-hamburger-s-pecorino-a-pikantnou-majonezou": "/nejnovejsi-videa",
  "/christian-vietnamsky-salat-s-hovadzim-masom-a-bylinkami":
    "/nejnovejsi-videa",
  "/traja-preslaveni-sefkuchari-varia-ceske-wagyu-najluxusnejsie-hovadzie-na-svete":
    "/nejnovejsi-videa",
  "/crew-dragon-mieri-k-prvej-ostrej-misii": "/nejnovejsi-videa",
  "/cvicna-manipulacia-s-telom-rakety-ariane-6": "/nejnovejsi-videa",
  "/lupici-sa-nikdy-nevzdavaju": "/nejnovejsi-videa",
  "/idealne-pohostenie-slane-lievance-s-ochutenym-syrom-a-udenym-lososom":
    "/nejnovejsi-videa",
  "/prva-sukromna-pilotovana-lod-ma-certifikaciu-nasa": "/nejnovejsi-videa",
  "/burka-menom-richard-krajco-vycina-na-internete": "/nejnovejsi-videa",
  "/viralbrothers-opat-precistia-youtube": "/nejnovejsi-videa",
  "/ako-urobit-z-malych-usmrkancov-zocelenych-gladiatorov": "/nejnovejsi-videa",
  "/geograficke-memiky-pre-scestovanych-pepikov-ruze-ceskeho-internetu":
    "/nejnovejsi-videa",
  "/influenceri-funguju-skvele-ale-niekedy-tiez-nie": "/nejnovejsi-videa",
  "/fizi-je-busy-alebo-ako-zarobit-na-detoch": "/nejnovejsi-videa",
  "/kazma-na-to-kvapol-co-je-klucom-k-uspechu": "/nejnovejsi-videa",
  "/premotivovane-motivacne-video-ktore-musis-vidiet": "/nejnovejsi-videa",
  "/tajomstvo-talianskych-cestovin-cerstve-tagliatelle-s-bazalkovym-pestom":
    "/nejnovejsi-videa",
  "/jednoduche-domace-ravioli-s-ricottou": "/nejnovejsi-videa",
  "/satelitny-komunikacny-system-tdrss": "/nejnovejsi-videa",
  "/strie-materske-znamienka-alebo-ekzem-cez-co-sa-moze-tetovat-a-cez-co-nikdy":
    "/nejnovejsi-videa",
  "/koncert-pre-buducnost-2020-samialespolu-17-11-16-30": "/nejnovejsi-videa",
  "/prva-operacna-misia-lodi-crew-dragon-je-tu": "/nejnovejsi-videa",
  "/start-falcon-9-crew-1-16-11-1-27": "/nejnovejsi-videa",
  "/najlepsie-fotky-zo-startu-utajenej-misie-nrol-101": "/nejnovejsi-videa",
  "/ktore-nizkokaloricke-potraviny-predlzia-pocit-sytosti": "/nejnovejsi-videa",
  "/svatomartinske-kurca-nadivoko-miesto-husi-alebo-kacice":
    "/nejnovejsi-videa",
  "/arnold-schwarzenegger-fantasticky-pribeh-chudobneho-rakuskeho-chlapca-ktory-nikdy-nevzdal-svoje-sny":
    "/nejnovejsi-videa",
  "/netradicne-speciality-na-ceskom-vine-od-svetovych-kucharov-ideme-do-najstarsej-vinicnej-oblasti-v-cechach":
    "/nejnovejsi-videa",
  "/matteo-diviak-na-vinnych-listoch-s-cuketou-cakankou-a-omackou-z-cerveneho-vina":
    "/nejnovejsi-videa",
  "/alberto-risotto-s-lososom-a-hroznovym-vinom": "/nejnovejsi-videa",
  "/chris-musle-sv-jakuba-marinovane-vo-vine-s-para-orechmi-a-jedlymi-kvetmi":
    "/nejnovejsi-videa",
  "/crew-dragon-crew-1-pripojenie-k-iss-17-11-5-00": "/nejnovejsi-videa",
  "/prvy-ostry-crew-dragon-odstartoval": "/nejnovejsi-videa",
  "/dva-vikendove-starty-falcon-9": "/nejnovejsi-videa",
  "/zlyhanie-rakety-vega": "/nejnovejsi-videa",
  "/vonave-pecene-gastany-vo-vasej-kuchyni": "/nejnovejsi-videa",
  "/nazivo-metamorfozy-bratislavy": "/nejnovejsi-videa",
  "/syrove-cannelloni-so-spenatom-cestoviny-ktore-sa-nevaria":
    "/nejnovejsi-videa",
  "/prehliadka-volebneho-bizaru-kto-toto-vyraba": "/nejnovejsi-videa",
  "/adolfeen-plod-koronavirusovej-epidemie": "/nejnovejsi-videa",
  "/start-electron-dragracer-29-dalsich-druzic-20-11-3-20": "/nejnovejsi-videa",
  "/uvod-do-systemov-podpory-zivota": "/nejnovejsi-videa",
  "/domaci-francuzsky-quiche": "/nejnovejsi-videa",
  "/ako-dat-koziemu-syru-chut-a-smrnc": "/nejnovejsi-videa",
  "/mackas-mi-hada-mizernu-kerku-z-mladosti-oslizol-exoticky-plaz":
    "/nejnovejsi-videa",
  "/raketa-electron-sa-dokazala-vratit": "/nejnovejsi-videa",
  "/falcon-9-sentinel-6a-21-11-18-17": "/nejnovejsi-videa",
  "/ako-charles-darwin-obratil-svet-hore-nohami-svojou-prevratnou-teoriou-o-povode-druhov":
    "/nejnovejsi-videa",
  "/kolko-mam-spravne-vazit": "/nejnovejsi-videa",
  "/originalne-pokrmy-zo-sumavskych-hub-nechajte-sa-inspirovat-od-majstrov-kucharov":
    "/nejnovejsi-videa",
  "/alberto-kacacie-prsia-s-hubovym-carpaccio-a-pomarancovym-dresingom":
    "/nejnovejsi-videa",
  "/chris-zmes-lesnych-hub-s-vajcami-japonskym-ryzovym-vinom-a-hluzovkou":
    "/nejnovejsi-videa",
  "/matteo-hubova-polievka-a-bruschetta-s-hubami-a-udenym-syrom-scamorza":
    "/nejnovejsi-videa",
  "/dokonaly-rybi-vyvar-pripravte-si-ho-v-pokoji-aj-mesiac-dopredu":
    "/nejnovejsi-videa",
  "/dlhy-pochod-5-chang-e-5-23-11-21-30": "/nejnovejsi-videa",
  "/zvuk-natoceny-medzi-zemou-a-marsom": "/nejnovejsi-videa",
  "/obrazom-falcon-9-vyniesol-strazcu-vzostupu-oceanov": "/nejnovejsi-videa",
  "/falcon-9-starlink-1-15-25-11-3-13": "/nejnovejsi-videa",
  "/jesenne-specle-patminutovka-z-jednej-panvice-zazenie-akutny-hlad":
    "/nejnovejsi-videa",
  "/obrateny-cheesecake-s-podrobnym-postupom-aj-pre-zaciatocnikov":
    "/nejnovejsi-videa",
  "/cesta-ku-skoku-starship-sn8-je-otvorena": "/nejnovejsi-videa",
  "/zacalo-zostavovanie-rakety-sls": "/nejnovejsi-videa",
  "/cina-zacala-misiu-k-doprave-vzoriek-z-mesiaca": "/nejnovejsi-videa",
  "/live-q-a-s-mikyrom": "/nejnovejsi-videa",
  "/cerstve-zemiakove-gnocchi-s-restovanou-zeleninou": "/nejnovejsi-videa",
  "/zakladna-paradajkova-omacka-na-cestoviny-rizoto-a-polievky":
    "/nejnovejsi-videa",
  "/10-najcastejsich-otazok-o-tetovani-boli-to-a-mozem-pit-alkohol-alebo-kavu":
    "/nejnovejsi-videa",
  "/s1-e4-rozmaz-to-poriadne": "/nejnovejsi-videa",
  "/s1-e5-milujem-ta": "/nejnovejsi-videa",
  "/cinska-firma-landspace-vyskusala-motory": "/nejnovejsi-videa",
  "/nova-japonska-prenosova-druzica": "/nejnovejsi-videa",
  "/preco-nas-prepada-vlci-hlad-da-sa-mu-predchadzat": "/nejnovejsi-videa",
  "/sefovia-vam-to-pekne-okorenia-kampotske-korenie-je-najlepsie-na-svete-a-zozeniete-ho-uz-aj-v-cesku":
    "/nejnovejsi-videa",
  "/alberto-slavky-a-la-marinara-s-grilovanym-pecivom": "/nejnovejsi-videa",
  "/chris-japonsky-street-food-kuracie-maso-na-spize": "/nejnovejsi-videa",
  "/matteo-spagety-cacio-e-pepe-s-tuniakom-a-cervenym-kampotskym-korenim":
    "/nejnovejsi-videa",
  "/bozena-nemcova-horky-koniec-najvacsej-ceskej-spisovatelky-a-velmi-nekonvencnej-zeny":
    "/nejnovejsi-videa",
  "/oslavujeme-100-diel-vesmirnych-sprav": "/nejnovejsi-videa",
  "/sonda-chang-e-5-pristala-na-mesiaci": "/nejnovejsi-videa",
  "/potlac-tricka-mp4": "/nejnovejsi-videa",
  "/03-3d-guru-ako-na-3d-tlac-mp4": "/nejnovejsi-videa",
  "/biznismen-tary-ide-cez-mrtvoly": "/nejnovejsi-videa",
  "/snimkovacia-spravodajska-druzica-pre-uae": "/nejnovejsi-videa",
  "/japonska-sonda-priviezla-vzorky-z-planetky-ryuga": "/nejnovejsi-videa",
  "/hodvabna-rybia-polievka-so-safranom-tato-bude-chutit-vsetkym":
    "/nejnovejsi-videa",
  "/vzorky-z-mesiaca-odobrate-chang-e-5-sa-vracia": "/nejnovejsi-videa",
  "/ako-sa-moze-vyvijat-styl-tetovania-od-samouka-az-po-youtube-lekcie-a-instagramove-inspiracie":
    "/nejnovejsi-videa",
  "/falcon-9-dragon-2-crs-21-6-12-17-17": "/nejnovejsi-videa",
  "/ludia-spite-novy-program-odhaluje-fakty-aj-myty-o-spanku":
    "/nejnovejsi-videa",
  "/historicke-spojenie-u-mesiace": "/nejnovejsi-videa",
  "/poctivy-segedinsky-gulas-z-chytreho-hrnca": "/nejnovejsi-videa",
  "/ako-funguje-ketonova-dieta-a-na-co-si-dat-pozor": "/nejnovejsi-videa",
  "/prvy-nakladny-dragon-druhej-generacie": "/nejnovejsi-videa",
  "/dragon-2-crs-21-pripojenie-k-iss-7-12-19-30": "/nejnovejsi-videa",
  "/john-lennon-jedna-z-najvacsich-hudobnych-hviezd-zhasla-pod-vystrelmi-choreho-vraha":
    "/nejnovejsi-videa",
  "/priprava-rakety-vulcan-centralny-stupen-a-rampa": "/nejnovejsi-videa",
  "/bleskova-majoneza-z-mixera": "/nejnovejsi-videa",
  "/sefovia-sa-ako-lovci-neosvedcili-ale-zverinu-vedia-co-uvaria-z-prvotriedneho-lokalneho-masa":
    "/nejnovejsi-videa",
  "/matteo-udene-srncie-maso-s-articokmi-a-stredomorskou-rastlinou-agretti":
    "/nejnovejsi-videa",
  "/chris-jeleni-chrbat-s-hubovou-omackou": "/nejnovejsi-videa",
  "/alberto-jeleni-gulas-so-zemiakmi": "/nejnovejsi-videa",
  "/dalsia-varka-luxusnych-dat-z-druzice-gaia": "/nejnovejsi-videa",
  "/starship-sn8-skok-do-12-5-kilometrov-9-12-22-00": "/nejnovejsi-videa",
  "/miliooony-prani-od-chinaski-a-katariny-knechtovej": "/nejnovejsi-videa",
  "/k-uplnemu-uspechu-starship-sn8-chybal-len-kusok": "/nejnovejsi-videa",
  "/start-delta-iv-heavy-nrol-44-11-12-2-09": "/nejnovejsi-videa",
  "/vychytana-nakladana-zelenina-do-zemiakoveho-salatu": "/nejnovejsi-videa",
  "/systemy-podpory-zivota-jedlo-a-pitie": "/nejnovejsi-videa",
  "/bonus-ako-to-naozaj-bolo-s-romanom-prymulom": "/nejnovejsi-videa",
  "/kto-bude-buduci-prezident": "/nejnovejsi-videa",
  "/odklad-start-falcon-9-sirius-xm-7-11-12-18-55": "/nejnovejsi-videa",
  "/lucid-fitko-mp4": "/nejnovejsi-videa",
  "/najlepsi-rezen-aky-ste-kedy-mali-philadelphsko-kyjevsky-variant-sa-hodi-na-stedry-den":
    "/nejnovejsi-videa",
  "/zubozeny-tiger-vytetovany-spendlikom-z-nastenky-vztah-matky-a-dcery-konecne-bude-symbolizovat-skutocna-selma":
    "/nejnovejsi-videa",
  "/tajny-recept-na-vianocne-cukrovinky-od-babicky": "/nejnovejsi-videa",
  "/druzica-so-stometrovou-antenou": "/nejnovejsi-videa",
  "/dokonala-sekana-z-vinnej-klobasy-a-hub": "/nejnovejsi-videa",
  "/start-falcon-9-sirius-xm-7-13-12-18-30": "/nejnovejsi-videa",
  "/s1-e6-zadok": "/nejnovejsi-videa",
  "/ako-a-co-jest-po-vecernom-cviceni-behu-alebo-jazde-na-bicykli":
    "/nejnovejsi-videa",
  "/trapenie-so-snami-ako-si-poradit-s-namesacnostou-alebo-nocnymi-morami":
    "/nejnovejsi-videa",
  "/ako-zaspat-ked-sa-to-nedari-nebudte-prilis-urputni-a-rozhodne-vypnite-mobil":
    "/nejnovejsi-videa",
  "/5-katastrof-za-ktorymi-stoji-unava-a-nedostatok-spanku":
    "/nejnovejsi-videa",
  "/roald-amundsen-vitazstvo-neustupneho-objavitela-v-bitke-o-juzny-pol-dostalo-horku-prichut":
    "/nejnovejsi-videa",
  "/dovera-v-pouzity-hardver-spacex-rastie": "/nejnovejsi-videa",
  "/posadka-spaceshiptwo-si-poradila-s-mimoriadnou-situaciou":
    "/nejnovejsi-videa",
  "/posilnujuca-zimna-polievka-stracciatella": "/nejnovejsi-videa",
  "/sutaz-o-najlepsiu-sefkucharsku-bagetu-ta-vitazna-sa-dostane-od-8-12-do-predaja":
    "/nejnovejsi-videa",
  "/chris-bageta-s-kuracimi-prsiami-sous-vide-camembertom-a-cibulkou-na-cervenom-vine":
    "/nejnovejsi-videa",
  "/matteo-bageta-s-konfitovanym-kacacim-stehnom-pomarancom-a-majonezou-s-foie-gras":
    "/nejnovejsi-videa",
  "/alberto-bageta-s-marinovanymi-morcacimi-prsiami-a-vyprazanym-vajcom":
    "/nejnovejsi-videa",
  "/tartaletky-s-bielou-cokoladou": "/nejnovejsi-videa",
  "/druhy-test-tazkej-rakety-angara": "/nejnovejsi-videa",
  "/na-dosiahnutie-obeznej-drahy-chybal-len-kusok": "/nejnovejsi-videa",
  "/luxusna-cokoladova-omacka-s-vianockovym-francuzskym-toastom":
    "/nejnovejsi-videa",
  "/odklad-start-falcon-9-nrol-108-17-12-15-45": "/nejnovejsi-videa",
  "/nove-vzorky-z-mesiaca-po-44-rokoch": "/nejnovejsi-videa",
  "/aky-tetovaci-styl-pouziva-mazacio-a-kde-sa-inspiruje": "/nejnovejsi-videa",
  "/chrumkave-hranolceky-zo-sumceka-100-vianocny-uspech": "/nejnovejsi-videa",
  "/sovietska-sonda-luna-16-pripravy-a-pristatie": "/nejnovejsi-videa",
  "/start-falcon-9-nrol-108-19-12-15-00": "/nejnovejsi-videa",
  "/silvestrovsky-kavej-maraton-31-12-12-00": "/nejnovejsi-videa",
  "/1731-gramov-vzoriek-z-mesiaca": "/nejnovejsi-videa",
  "/vianocny-sosovicovy-salat-uzasna-priloha-nielen-k-rybam":
    "/nejnovejsi-videa",
  "/projekt-oneweb-sa-po-bankrote-opat-rozbieha": "/nejnovejsi-videa",
  "/spat-ako-v-bavlnke-ako-si-vypestovat-zdrave-navyky-pred-spanim":
    "/nejnovejsi-videa",
  "/bonus-ako-vam-obycajne-cervene-okuliare-pomozu-zaspat": "/nejnovejsi-videa",
  "/tradicna-vianocna-kuba-zapecena-syrom-raclette": "/nejnovejsi-videa",
  "/nahana-vas-v-noci-pazravost-zamyslite-sa-co-vam-v-zivote-chyba-cukor-totiz-upokojuje-a-dodava-pocit-bezpecia":
    "/nejnovejsi-videa",
  "/najlepsie-hovadzie-skotske-plemeno-v-rukach-najlepsich-sefov-makke-stavnate-delikatne":
    "/nejnovejsi-videa",
  "/chris-ovcie-kotlety-s-cibulovou-omackou-a-salatom-z-kvetov":
    "/nejnovejsi-videa",
  "/alberto-grilovane-hovadzie-s-guanciale-liskami-a-zemiakmi":
    "/nejnovejsi-videa",
  "/matteo-hovadzia-svieckova-s-cibulkovym-dressingom-a-chrumkavou-zeleninou":
    "/nejnovejsi-videa",
  "/spacex-uzavrela-rekordny-rok-utajenou-misiou": "/nejnovejsi-videa",
  "/starship-sn9-v-testovacej-oblasti": "/nejnovejsi-videa",
  "/vincent-van-gogh-jeden-z-najvacsich-maliarov-trpel-fyzicky-aj-psychicky-v-zufalej-uzkosti-si-odrezal-ucho":
    "/nejnovejsi-videa",
  "/lucid-jlo-final-mp4": "/nejnovejsi-videa",
  "/krasne-vianoce-vsetkym-aj-astronautom": "/nejnovejsi-videa",
  "/sovietska-sonda-luna-16-odber-vzoriek-a-navrat": "/nejnovejsi-videa",
  "/zlato-to-das-takto-to-dopadne-ked-muzi-provokuju-zeny": "/nejnovejsi-videa",
  "/jazvu-po-cisarovi-prekryju-3-neohrozene-hrdinky-milovnicka-fantasy-si-vybrala-originalnu-temu":
    "/nejnovejsi-videa",
  "/silvestrovski-rychloutopenci-na-prelezanie-im-staci-dva-dni":
    "/nejnovejsi-videa",
  "/komora-bishop-je-na-svojom-mieste": "/nejnovejsi-videa",
  "/sladke-mamenie-sefovia-predvedu-ako-sa-da-carovat-s-kvalitnou-cokoladou":
    "/nejnovejsi-videa",
  "/alberto-cokoladova-torta": "/nejnovejsi-videa",
  "/chris-sladky-salat-s-kaki-kesu-orieskami-a-cokoladovym-dresingom":
    "/nejnovejsi-videa",
  "/matteo-holubok-s-grilovanym-baklazanom-a-cokoladovou-omackou":
    "/nejnovejsi-videa",
  "/cinska-radarova-druzica-yaogan-33": "/nejnovejsi-videa",
  "/poctiva-polnocna-novorocna-gulasovka": "/nejnovejsi-videa",
  "/5-kozmonautickych-udalosti-roku-2020": "/nejnovejsi-videa",
  "/posledny-start-roku-2020": "/nejnovejsi-videa",
  "/boris-nikolajevic-jelcin-prvy-rusky-prezident-po-sebe-zanechal-zalostnu-bilanciu-biedu-krviprelievani-aj-hospodarsky-krach-krajiny":
    "/nejnovejsi-videa",
  "/slany-kolac-2-v-1-pripravite-rychle-pohostenie-a-zbavite-sa-zvyskov-potravin":
    "/nejnovejsi-videa",
  "/kozmonauticke-srandicky-roka-2020": "/nejnovejsi-videa",
  "/chrapanie-a-apnoe-ako-odstranit-najhorsi-zvuk-na-svete-a-liecit-zavazny-problem":
    "/nejnovejsi-videa",
  "/daj-dar-dnes-aj-ty-do-kazdej-rodiny": "/nejnovejsi-videa",
  "/sovietske-sondy-luna-18-a-luna-20": "/nejnovejsi-videa",
  "/20-najlepsich-fotiek-zeme-roka-2020-podla-nasa": "/nejnovejsi-videa",
  "/skovranok-alebo-sova-nas-biorytmus-ovplyvnuje-tma-a-svetlo-najhorsia-je-praca-na-smeny":
    "/nejnovejsi-videa",
  "/z-ktorych-potravin-ziskate-najviac-bielkovin": "/nejnovejsi-videa",
  "/portrety-aj-mytologie-ciernobiela-realistika-zostava-verna-predlohe":
    "/nejnovejsi-videa",
  "/5-najocakavanejsich-momentov-kozmonautiky-roka-2021": "/nejnovejsi-videa",
  "/cuberovery-buducnost-prieskumu-mesiaca": "/nejnovejsi-videa",
  "/sefovia-vyuzili-lahodne-produkty-z-ceskej-kozej-farmy-do-pozoruhodnych-receptov":
    "/nejnovejsi-videa",
  "/matteo-sviezi-salat-s-grilovanymi-jahodami-broskynami-a-kozim-syrom":
    "/nejnovejsi-videa",
  "/alberto-grilovane-chobotnice-s-kozim-syrom-baklazanom-a-sladke-zemiaky":
    "/nejnovejsi-videa",
  "/chris-sladky-krem-z-kozieho-mlieka-s-maracujou-makom-a-olejom-z-vietnamskej-kavy":
    "/nejnovejsi-videa",
  "/preco-si-ludia-nepamataju-porod": "/nejnovejsi-videa",
  "/prve-tankovanie-centralneho-stupna-rakety-sls": "/nejnovejsi-videa",
  "/1-den-ako-hrobar-mp4": "/nejnovejsi-videa",
  "/jan-werich-jiri-voskovec-neohrozena-dvojica-bola-nemcom-trnom-v-oku-emigracia-sa-podarila-na-poslednu-chvilu":
    "/nejnovejsi-videa",
  "/lovec-burok-adam-kajumi": "/nejnovejsi-videa",
  "/prva-vyroba-inzulinu-bola-drsna-hlavne-pre-prasiata": "/nejnovejsi-videa",
  "/sovietska-sonda-luna-23": "/nejnovejsi-videa",
  "/teroristicky-dezert-recept-na-muffiny-sa-objavil-na-webovkach-al-kajdy-12-1-2021":
    "/nejnovejsi-videa",
  "/rocny-odpocet-do-startu-sls-sa-rozbehol": "/nejnovejsi-videa",
  "/lod-cygnus-opustila-medzinarodnu-kozmicku-stanicu": "/nejnovejsi-videa",
  "/22-1-2021-1-mp4": "/nejnovejsi-videa",
  "/svetelne-znecistenie-skodi-ludom-aj-prirode-ako-sa-proti-nemu-branit":
    "/nejnovejsi-videa",
  "/vplyv-svetla-na-imunitny-system-svetlo-v-noci-je-neziaduce-a-nezdrave":
    "/nejnovejsi-videa",
  "/rychly-mejkap-pre-mamicky-ako-vyzerat-stylovo-a-upravena-aj-pri-kocikovani":
    "/nejnovejsi-videa",
  "/vyroba-nadrze-a-skusky-motorov-rakety-vulcan": "/nejnovejsi-videa",
  "/chris-kapor-na-vietnamsky-sposob-s-citronovou-travou-a-galangalom":
    "/nejnovejsi-videa",
  "/alberto-carpa-alla-mugnaia-kapor-po-mlynarsky-s-grilovanou-brokolicou":
    "/nejnovejsi-videa",
  "/huggies": "/nejnovejsi-videa",
  "/male-kamery-pre-lunarne-landery": "/nejnovejsi-videa",
  "/vznika-prvy-nakladny-dream-chaser": "/nejnovejsi-videa",
  "/chesley-sullenberger-ako-hrdina-sully-zazracne-pristal-na-rieke-hudson-bez-obeti-na-zivotoch":
    "/nejnovejsi-videa",
  "/maximum-lubeznej-motivacie-alebo-garantovany-pasivny-prijem":
    "/nejnovejsi-videa",
  "/tri-staticke-zazihy-pocas-jedneho-dna": "/nejnovejsi-videa",
  "/slovensko-oslavuje-27-vyrocie-vstupu-do-osn-19-1-2021": "/nejnovejsi-videa",
  "/vzlietol-new-shepard-ktory-bude-vozit-turistov": "/nejnovejsi-videa",
  "/centralny-stupen-rakety-sls-staticky-zazih-16-1-22-00": "/nejnovejsi-videa",
  "/chcete-vyzerat-mlado-zdravo-a-stastne-chudnite-podla-rady-petra-havlicka":
    "/nejnovejsi-videa",
  "/prvy-zazih-centralneho-stupna-rakety-sls": "/nejnovejsi-videa",
  "/ked-nechcete-mat-vrasky-tak-toto-nerobte": "/nejnovejsi-videa",
  "/legendarne-slovenske-reklamy": "/nejnovejsi-videa",
  "/medzinarodny-den-objimania-mal-pomoct-americanom-zbavit-sa-hanblivosti-21-1-2021":
    "/nejnovejsi-videa",
  "/vratil-sa-prvy-dragon-druhej-generacie": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-16-20-1-14-02": "/nejnovejsi-videa",
  "/druzica-o-ktorej-vieme-len-malo": "/nejnovejsi-videa",
  "/kuraci-ondras-strips-v-hanebne-dobrom-cesticku": "/nejnovejsi-videa",
  "/lod-orion-caka-tankovanie": "/nejnovejsi-videa",
  "/co-znamena-moj-sen-ako-sa-robi-moderny-vyklad-snov": "/nejnovejsi-videa",
  "/zimny-ruzovy-salat-coleslaw-so-smrncovnym-chrenom": "/nejnovejsi-videa",
  "/klement-gottwald-jazdime-sa-do-moskvy-ucit-ako-vam-zakrutit-krkom":
    "/nejnovejsi-videa",
  "/taketo-blaznive-sportove-kusky-moze-vymysliet-a-zrealizovat-len-chlap":
    "/nejnovejsi-videa",
  "/prvy-tohtorocny-cinsky-start": "/nejnovejsi-videa",
  "/start-falcon-9-transporter-1-24-1-16-00": "/nejnovejsi-videa",
  "/home-office-ako-sa-nezblaznit-nepriberat-a-zostat-pozitivny":
    "/nejnovejsi-videa",
  "/vyroba-modulu-lunarna-stanica": "/nejnovejsi-videa",
  "/jej-tanec-bol-taky-pikantny-ze-neraz-skoncila-za-mrezami":
    "/nejnovejsi-videa",
  "/rekordnych-143-druzic-vypustenych-pri-jednej-misii": "/nejnovejsi-videa",
  "/mac-n-cheese-s-prekvapenim-ziadna-priloha-vsetko-je-vnutri":
    "/nejnovejsi-videa",
  "/prasovlk-jedna-z-najhorsich-keriek-ake-sme-videli": "/nejnovejsi-videa",
  "/pomoze-oscar-s-likvidaciou-odpadkov": "/nejnovejsi-videa",
  "/kamcatsky-krab-luxusna-pochutka-50-rokov-odporny-zapach-ale-vycibrena-chut":
    "/nejnovejsi-videa",
  "/sonda-isee-3-prva-vedecka-faza": "/nejnovejsi-videa",
  "/ceska-zadrzala-lupica-vlastnym-telom-nahym-a-odovzdanym-uz-nemozem-zoberte-si-ho-kricala-na-policajtov":
    "/nejnovejsi-videa",
  "/ako-si-udrzat-zdrave-creva-crevne-bakterie-ovplyvnuju-vasu-vahu-naladu-aj-vykonnost":
    "/nejnovejsi-videa",
  "/dva-prototypy-starship-v-testovacej-oblasti": "/nejnovejsi-videa",
  "/zostavovanie-motorov-pre-sls-pokracuje": "/nejnovejsi-videa",
  "/juhokorejcania-pripravuju-premieru-novej-rakety": "/nejnovejsi-videa",
  "/starship-sn9-skok-do-10-kilometrov-2-2-21-25": "/nejnovejsi-videa",
  "/takmer-uspesny-skok-starship-sn9": "/nejnovejsi-videa",
  "/preco-sme-urobili-vladny-tiktok": "/nejnovejsi-videa",
  "/horuca-cokoladova-laska-so-zmrzlinou-a-malinami": "/nejnovejsi-videa",
  "/prvotriedna-kremova-domaca-zmrzlina": "/nejnovejsi-videa",
  "/brokolica-s-prichutou-zuvacky-nauci-ludi-zdravo-jest-9-2-2021":
    "/nejnovejsi-videa",
  "/prve-cinske-fotky-marsu": "/nejnovejsi-videa",
  "/sonda-isee-3-prieskum-zemskej-magnetosfery": "/nejnovejsi-videa",
  "/vyklad-snov-nehladajte-vyznam-symbolov-v-snari-ale-v-sebe":
    "/nejnovejsi-videa",
  "/zaruceny-hit-instagramu-vystrc-prsia-strc-k-nim-dieta": "/nejnovejsi-videa",
  "/lod-starliner-sa-chysta-na-reparat": "/nejnovejsi-videa",
  "/kolko-krokov-denne-mame-ujst-udajnych-10-000-je-len-marketingovy-tah":
    "/nejnovejsi-videa",
  "/prva-cisto-civilna-pilotovana-kozmicka-misia": "/nejnovejsi-videa",
  "/gregor-johann-mendel-moravsky-opat-a-otec-genetiky-predbehol-dobu-svojimi-genialnymi-myslienkami":
    "/nejnovejsi-videa",
  "/aka-je-najnizsia-namerana-teplota-na-slovensku-11-2-2021":
    "/nejnovejsi-videa",
  "/suboj-kavy-a-caju-v-ceskych-dejinach-ktory-napoj-vitazi-na-celej-ciare":
    "/nejnovejsi-videa",
  "/sonda-z-uae-zakotvila-pri-marse": "/nejnovejsi-videa",
  "/mikyrove-mudrosti-3": "/nejnovejsi-videa",
  "/chrumkavy-hashbrown-priloha-z-iba-dvoch-surovin": "/nejnovejsi-videa",
  "/poriadny-stejk-vajcia-a-sampinony-pre-postelovych-sampionov":
    "/nejnovejsi-videa",
  "/porezany-portret-exmanzela-na-stehne-na-to-po-rozvode-nechcete-pozerat":
    "/nejnovejsi-videa",
  "/lupic-a-gangster-jesse-james-sa-stal-legendou-ako-robin-hood-lenze-bohatym-bral-chudobnym-nedaval":
    "/nejnovejsi-videa",
  "/budovanie-stanice-gateway-zahaji-falcon-heavy": "/nejnovejsi-videa",
  "/sonda-isee-3-ice-skumanie-komety-a-navrat-k-zemi": "/nejnovejsi-videa",
  "/ako-upravit-jedalnicek-v-zimnom-obdobi": "/nejnovejsi-videa",
  "/zacala-prva-misia-k-iss-v-roku-2021": "/nejnovejsi-videa",
  "/ako-videla-cinska-sonda-manever-pri-marse": "/nejnovejsi-videa",
  "/sitoviny-16-2-final-sk-mp4": "/nejnovejsi-videa",
  "/bonus-16-2-final-mp4": "/nejnovejsi-videa",
  "/sitoviny-17-2-final-sk-mp4": "/nejnovejsi-videa",
  "/stupen-falcon-nepristal-ale-misia-bola-splnena": "/nejnovejsi-videa",
  "/muammar-kaddafi-libyjskeho-diktatora-po-krutej-obcianskej-vojne-zlyncoval-dav":
    "/nejnovejsi-videa",
  "/prvy-stupen-rakety-vulcan-je-na-kozmodrome": "/nejnovejsi-videa",
  "/hladanie-pravdy-o-vaku-z-cinu": "/nejnovejsi-videa",
  "/progress-ms-16-pripojenie-k-iss-17-2-7-20": "/nejnovejsi-videa",
  "/perseverance-pristava-na-marse": "/nejnovejsi-videa",
  "/ako-urobit-z-obycajnej-kase-luxusnu-prilohu": "/nejnovejsi-videa",
  "/talianska-bravcova-extra-liga-chrumkavejsi-bocik-nenajdete":
    "/nejnovejsi-videa",
  "/mirka-x-mythra-11-dolezitych-veci-ktore-by-ste-mali-vediet-o-tattoo-prerabkach":
    "/nejnovejsi-videa",
  "/sitoviny-22-2-final-cz-mp4": "/nejnovejsi-videa",
  "/sonda-perseverance-pristatie-na-marse-18-2-21-55": "/nejnovejsi-videa",
  "/beton-ako-cesky-originalny-koktail-prerazil-vo-svete": "/nejnovejsi-videa",
  "/cygnus-ng-15-pripojenie-k-iss-22-2-10-40": "/nejnovejsi-videa",
  "/k-medzinarodnej-vesmirnej-stanici-doletela-lod-cygnus": "/nejnovejsi-videa",
  "/prve-fotky-marsu-od-perseverance": "/nejnovejsi-videa",
  "/mikyrova-uzasna-live-put-internetom-mupi-nazivo-zaznam":
    "/nejnovejsi-videa",
  "/mikyr-medvedik-acoustic-cover-acoustic-cover-live-official":
    "/nejnovejsi-videa",
  "/mikyr-feat-poslanec-volny-medvedik-acoustic": "/nejnovejsi-videa",
  "/fotky-z-kamier-vozitka-perseverance": "/nejnovejsi-videa",
  "/unikatne-videa-z-pristatia-perseverance": "/nejnovejsi-videa",
  "/ako-vyuzit-zvysne-palacinky-tipy-na-rychle-a-dobre-ranajky-ci-veceru":
    "/nejnovejsi-videa",
  "/palacinkova-torta-s-dvoma-naplnami-vykrmite-janicka-marienku-aj-celu-rodinu":
    "/nejnovejsi-videa",
  "/taterka-any-predstavuje-neo-traditional-styl": "/nejnovejsi-videa",
  "/ako-analyzovat-svoje-sny-a-ukoncit-opakujuce-sa-nocne-mory":
    "/nejnovejsi-videa",
  "/katerina-emmons-hlavne-si-nepustit-do-gati": "/nejnovejsi-videa",
  "/dychberuca-panorama-marsu-od-perseverance": "/nejnovejsi-videa",
  "/ako-sa-predtym-dodavala-uhorkam-cerstva-zelena-farbicka":
    "/nejnovejsi-videa",
  "/deti-s-nadvahou-su-zrkadlom-vasej-vychovy-vazne-ich-chcete-trestat-dietou":
    "/nejnovejsi-videa",
  "/skusobne-panely-noveho-horneho-stupna": "/nejnovejsi-videa",
  "/cesky-edison-frantisek-krizik-priekopnik-a-vynalezca-svetoveho-formatu":
    "/nejnovejsi-videa",
  "/trendy-vo-video-obsahu-a-nove-formaty-mall-tv-18-03-2021":
    "/nejnovejsi-videa",
  "/prvy-indicky-start-roka-2021": "/nejnovejsi-videa",
  "/nostradamus-vedel-pokrokovy-liecitel-a-astrolog-predpovedat-historiu-jeho-vestby-budia-vasne-dodnes":
    "/nejnovejsi-videa",
  "/zaciatok-budovania-systemu-arktika-m": "/nejnovejsi-videa",
  "/zostavovanie-pomocnych-motorov-sls-finisuje": "/nejnovejsi-videa",
  "/na-iss-poleti-europska-astronautka": "/nejnovejsi-videa",
  "/starship-sn10-skok-do-10-kilometrov-3-3-21-14": "/nejnovejsi-videa",
  "/falcon-9-starlink-1-17-4-3-9-24": "/nejnovejsi-videa",
  "/pecene-americke-zemiaky-s-vlastnou-koreniacou-zmesou": "/nejnovejsi-videa",
  "/retro-kura-s-broskynou-je-spat-v-kvalitnej-verzii-pre-21-storocie":
    "/nejnovejsi-videa",
  "/dvaja-na-jednu-amaterska-kerka-mizne-pod-rukami-nelly-a-svarta":
    "/nejnovejsi-videa",
  "/pu-zdravica-mp4": "/nejnovejsi-videa",
  "/prve-rande": "/nejnovejsi-videa",
  "/starship-sn-10-dokazala-pristat-ale-potom-explodovala": "/nejnovejsi-videa",
  "/bazant-symbol-luxusneho-pokrmu-vyviezli-sme-ho-do-sveta-a-to-vratane-brokov":
    "/nejnovejsi-videa",
  "/nazivo-nakladna-lod-ms-capella": "/nejnovejsi-videa",
  "/nazivo-nakladna-lod-ms-katja": "/nejnovejsi-videa",
  "/styri-zasadne-chyby-pri-amaterskych-dietach": "/nejnovejsi-videa",
  "/perseverance-sa-rozbehla": "/nejnovejsi-videa",
  "/zvaranie-modulu-lunarnej-stanice": "/nejnovejsi-videa",
  "/testovaci-stupen-novej-europskej-rakety": "/nejnovejsi-videa",
  "/le-secrete-le-film-by-le-martan-mikynsky": "/nejnovejsi-videa",
  "/zahadna-smrt-jana-masaryka-vrazda-samovrazda-alebo-tragicka-nehoda":
    "/nejnovejsi-videa",
  "/dalsi-starship-caka-na-testy": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-21-14-3-11-01": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-20-11-3-9-13": "/nejnovejsi-videa",
  "/hambar-hamba-burger-s-vytunenym-holandskym-reznom": "/nejnovejsi-videa",
  "/slovaci-miluju-riesit-blbosti": "/nejnovejsi-videa",
  "/sonda-mars-pathfinder-konstrukcia-preletovej-casti-a-pristatia":
    "/nejnovejsi-videa",
  "/dlhy-pochod-7a-zlozil-reparat": "/nejnovejsi-videa",
  "/cesky-lekar-vymyslel-zrejme-najvystrednejsiu-dietu-na-svete":
    "/nejnovejsi-videa",
  "/potraviny-ktore-by-nemali-nikdy-chybat-v-chladnicke": "/nejnovejsi-videa",
  "/start-falcon-s-mnohymi-rekordmi": "/nejnovejsi-videa",
  "/kratke-vesmirne-spravy-oslavuju-1-narodeniny": "/nejnovejsi-videa",
  "/raketa-a-lod-pre-misie-k-mesiacu": "/nejnovejsi-videa",
  "/perseverance-na-marse-nezahala": "/nejnovejsi-videa",
  "/pu-ep05-online-mp4": "/nejnovejsi-videa",
  "/zahour-najjednoduchsia-ovocna-omacka-k-dezertom": "/nejnovejsi-videa",
  "/trhanec-opiteho-cisara-s-cucoriedkami-a-pravym-sodo": "/nejnovejsi-videa",
  "/obrazom-fotky-zeme-z-iss": "/nejnovejsi-videa",
  "/velkonocny-mazanec": "/nejnovejsi-videa",
  "/vrazda-jana-nepomuckeho-pri-muceni-kruto-trpel-napriek-tomu-neprehovoril-ani-slovo":
    "/nejnovejsi-videa",
  "/uspesny-test-obrovskej-rakety-sls": "/nejnovejsi-videa",
  "/lod-sojuz-sa-prepojila-z-jedneho-miesta-na-druhe": "/nejnovejsi-videa",
  "/centralny-stupen-rakety-sls-opakovany-staticky-zazih-18-3-21-15":
    "/nejnovejsi-videa",
  "/mars-pathfinder-konstrukcia-a-vedecke-vybavenie-povrchovej-platformy":
    "/nejnovejsi-videa",
  "/5-rad-pre-sikovne-chudnutie-co-je-dolezitejsie-ako-jedlo-a-pohyb":
    "/nejnovejsi-videa",
  "/druha-slovenska-druzica-na-obeznej-drahe": "/nejnovejsi-videa",
  "/start-sojuz-2-1a-grbalpha-22-3-7-07": "/nejnovejsi-videa",
  "/webinar-ako-by-mohol-peter-ludwig-posobit-ako-mensi-kreten":
    "/nejnovejsi-videa",
  "/svojrazna-dieta-z-jesenicka-denne-opity-alebo-zemle-vino-a-tanec":
    "/nejnovejsi-videa",
  "/firma-rocket-lab-chysta-novu-techniku": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-22-24-3-9-28": "/nejnovejsi-videa",
  "/bageta-v-azijskom-style-s-bravcovym-masom-z-rebierok": "/nejnovejsi-videa",
  "/azijske-bravcove-rebierka-krasna-farba-dokonaly-sos-pozor-nech-si-nevypestujete-zavislost":
    "/nejnovejsi-videa",
  "/milos-forman-amadeus-a-8-oscarov-v-roku-1985-cesi-ovladli-hollywood":
    "/nejnovejsi-videa",
  "/slovenske-tradicie-velkej-noci": "/nejnovejsi-videa",
  "/raketa-sojuz-vyniesla-36-druzic-oneweb": "/nejnovejsi-videa",
  "/ako-uvarit-prepelicie-vajcia-namakko-natvrdo-a-na-hnilicku":
    "/nejnovejsi-videa",
  "/chris-najde-animator-tu-pravou": "/nejnovejsi-videa",
  "/mtv-na-s302-a-master-mp4": "/nejnovejsi-videa",
  "/co-je-nove-na-zakladni-boca-chica": "/nejnovejsi-videa",
  "/lahky-a-chrumkavy-jarny-zemiakovy-salat": "/nejnovejsi-videa",
  "/budovanie-kozmodromu-vostocnyj-pokracuje": "/nejnovejsi-videa",
  "/ako-doma-osetrit-zeleninu-a-ovocie-aby-boli-bezpecne-na-konzumaciu":
    "/nejnovejsi-videa",
  "/ako-hollywoodska-dieta-uchvatila-socialisticke-zeny": "/nejnovejsi-videa",
  "/zablokovany-suezsky-prieplav-pohladom-druzic": "/nejnovejsi-videa",
  "/kozmonauticky-april": "/nejnovejsi-videa",
  "/velkonocne-nadychane-muffiny-s-cuketou-vlacne-a-odlahcene":
    "/nejnovejsi-videa",
  "/bleskova-morcacia-sekana": "/nejnovejsi-videa",
  "/studena-vajecna-omacka-gribiche-inspirujte-sa-lahodnym-francuzskom":
    "/nejnovejsi-videa",
  "/co-sa-skor-jedlo-na-velku-noc-slimaky-zabie-stehienka-alebo-tak-trochu-iny-barancek":
    "/nejnovejsi-videa",
  "/mars-pathfinder-vedecke-vybavenie-roveru-sojourner": "/nejnovejsi-videa",
  "/ako-chudnut-po-styridsiatke": "/nejnovejsi-videa",
  "/starship-sn11-skok-do-10-kilometrov-30-3-15-00": "/nejnovejsi-videa",
  "/test-vodnych-chrlicov-pre-raketu-sls": "/nejnovejsi-videa",
  "/starship-sn11-v-hmle-explodovala": "/nejnovejsi-videa",
  "/priprava-novej-japonskej-rakety-h3": "/nejnovejsi-videa",
  "/starship-sn15-zostavena": "/nejnovejsi-videa",
  "/potraviny-ktore-by-nemali-chybat-v-ziadnom-jedalnicku": "/nejnovejsi-videa",
  "/spesto-tak-trochu-ine-jarne-pesto": "/nejnovejsi-videa",
  "/vrtulnik-ingenuity-uz-stoji-na-marse": "/nejnovejsi-videa",
  "/predstartova-priprava-posadky-sojuzu": "/nejnovejsi-videa",
  "/alfred-wetzler-a-rudolf-vrba-po-uspesnom-uteku-z-osviencimu-sokovali-svet-svedectvom-o-hrozach-z-tovarne-na-smrt":
    "/nejnovejsi-videa",
  "/najjednoduchsie-cestoviny-na-svete-za-par-minut-bude-vecera":
    "/nejnovejsi-videa",
  "/test-dopadu-lode-orion-do-vody": "/nejnovejsi-videa",
  "/dvadsiata-tretia-porcia-druzic-starlink": "/nejnovejsi-videa",
  "/ako-uchovat-avokado-krasne-zelene": "/nejnovejsi-videa",
  "/robotnici-ziadostivosti-nova-dokumentarna-seria-jakuba-klingohra":
    "/nejnovejsi-videa",
  "/nasledky-sexualneho-zneuzitia-da-sa-s-traumou-vobec-niekedy-vyrovnat":
    "/nejnovejsi-videa",
  "/zeny-ako-pachatelky-sexualneho-zneuzivania": "/nejnovejsi-videa",
  "/kedy-mozu-mat-deti-sex-v-taliansku-uz-od-trinastich-v-madarsku-dokonca-od-dvanastich":
    "/nejnovejsi-videa",
  "/niektore-formy-sexualneho-zneuzivania-deti-su-nebezpecne-podcenovane-hovori-psychoterapeutka-machkova":
    "/nejnovejsi-videa",
  "/sexualne-zneuzivanie-deti-v-cesku-tresty-liecba-a-rozhovory-s-pachatelmi":
    "/nejnovejsi-videa",
  "/vladimir-zneuzil-svoje-dve-sestry-a-trojrocne-dievcatko-nie-som-pedofil-bolo-to-zo-zvedavosti":
    "/nejnovejsi-videa",
  "/sexuolog-slavoj-brichcin-priznat-si-sexualnu-deviaciu-je-velmi-tazke-mnoho-deliktov-sa-nikdy-neodhali":
    "/nejnovejsi-videa",
  "/sojuz-ms-18-pripojenie-k-iss-9-4-13-07": "/nejnovejsi-videa",
  "/sovietsky-program-vostok-predosle-programy": "/nejnovejsi-videa",
  "/vyzivne-a-zdrave-ranajky-miesane-vajicka-s-avokadom": "/nejnovejsi-videa",
  "/mtv-na-s304-a-master-mp4": "/nejnovejsi-videa",
  "/mtv-na-s304-a-master-mp4-2": "/nejnovejsi-videa",
  "/mtv-na-s304-a-master-mp4-3": "/nejnovejsi-videa",
  "/jurij-gagarin-prvy-clovek-vo-vesmire-a-najslavnejsich-108-minut-v-historii-kozmonautiky":
    "/nejnovejsi-videa",
  "/ako-blahodarne-je-zdriemnut-si-po-obede-bohuzial-nie-pre-kazdeho":
    "/nejnovejsi-videa",
  "/posledne-zlozenie-obrovskej-clony": "/nejnovejsi-videa",
  "/desat-ludi-na-iss": "/nejnovejsi-videa",
  "/nove-fotovoltaicke-panely-pre-iss": "/nejnovejsi-videa",
  "/lajfheky-3-0-offline-mp4": "/nejnovejsi-videa",
  "/nazivo-ako-si-hraju-stenata-yuki": "/nejnovejsi-videa",
  "/zapeceny-toast-specialne-upraveny-pre-deti-rodicovske-body-zarucene":
    "/nejnovejsi-videa",
  "/posobivy-pohlad-na-nocny-kozmodrom": "/nejnovejsi-videa",
  "/lunarny-rover-vynesie-falcon-heavy": "/nejnovejsi-videa",
  "/preco-nebude-mikyr-reagovat-na-like-house": "/nejnovejsi-videa",
  "/new-shepard-sa-pripravuje-na-cestujucich": "/nejnovejsi-videa",
  "/tipy-a-triky-ako-nevysusit-kralicie-maso-a-naopak-mu-dodat-stavnatost":
    "/nejnovejsi-videa",
  "/ako-uchovavat-sparglu-aby-zostala-svieza": "/nejnovejsi-videa",
  "/cesta-k-programu-vostok-vyskove-lety-psov": "/nejnovejsi-videa",
  "/co-si-doma-neupeciete-ako-chuti-polarna-liska-alebo-alpsky-svist":
    "/nejnovejsi-videa",
  "/ludia-sa-na-mesiac-vratia-v-starshipe": "/nejnovejsi-videa",
  "/sojuz-ms-17-sa-vratil-domov": "/nejnovejsi-videa",
  "/natierka-z-udenej-makrely-celu-rybu-spracujete-necakane-jednoducho":
    "/nejnovejsi-videa",
  "/prvy-vrtulovy-let-mimo-zem-bol-uspesny": "/nejnovejsi-videa",
  "/dalsie-detaily-o-historickom-lete-ingenuity": "/nejnovejsi-videa",
  "/svet-smiesnych": "/nejnovejsi-videa",
  "/odlet-sukromneho-lietadla-petra-kellnera-boeing-737-do-anchorage":
    "/nejnovejsi-videa",
  "/special-odvaza-ruskych-diplomatov-do-moskvy": "/nejnovejsi-videa",
  "/najrychlejsia-a-najlepsia-uprava-baklazanu-bude-krasne-makky-a-stavnaty":
    "/nejnovejsi-videa",
  "/navrat-vyhostenych-ceskych-diplomatov-z-moskvy": "/nejnovejsi-videa",
  "/karel-hynek-macha-extravagantny-cudak-je-autorom-nadhernych-versov-aj-pikantneho-erotickeho-dennika":
    "/nejnovejsi-videa",
  "/vyroba-kyslika-na-marse-zacala": "/nejnovejsi-videa",
  "/start-falcon-9-crew-2-23-4-11-49": "/nejnovejsi-videa",
  "/kozmicka-lod-crew-2-pripojenie-k-iss-24-4-11-10": "/nejnovejsi-videa",
  "/preco-milovat-rusko": "/nejnovejsi-videa",
  "/zapecene-sampinony-so-spenatom-zltkami-a-syrovym-trikom-vo-finale":
    "/nejnovejsi-videa",
  "/ako-zaradovat-do-svojho-zivota-jednodnovy-post-ake-su-vyhody-a-pripadne-rizika":
    "/nejnovejsi-videa",
  "/mtv-na-s306-b-master-mp4-2": "/nejnovejsi-videa",
  "/druha-operacna-misia-lode-crew-dragon-zacala": "/nejnovejsi-videa",
  "/centralny-stupen-sls-mieri-na-floridu": "/nejnovejsi-videa",
  "/start-delta-iv-heavy-nrol-82-26-4-22-46": "/nejnovejsi-videa",
  "/kremove-sparglove-risotto-s-predpripravenym-zakladom-mate-hotovo-za-par-minut":
    "/nejnovejsi-videa",
  "/ako-spravne-zalozit-talianske-risotto-rozbehnite-ho-pokojne-rano-dotiahnite-vecer":
    "/nejnovejsi-videa",
  "/nebudem-reagovat": "/nejnovejsi-videa",
  "/kvz-299-mp4": "/nejnovejsi-videa",
  "/snehovo-biela-lahka-a-nadychana-omeleta-pre-stihlu-figuru":
    "/nejnovejsi-videa",
  "/nove-spionazne-dvojca-hubblea": "/nejnovejsi-videa",
  "/ako-si-zorganizovat-kuchynu-do-najmensieho-detailu-pomocou-par-trikov":
    "/nejnovejsi-videa",
  "/ako-si-vybrat-kuchynske-dozy-upracete-usetrite-a-zbavite-sa-moli":
    "/nejnovejsi-videa",
  "/mtv-na-s307-a-master-mp4": "/nejnovejsi-videa",
  "/mtv-na-s307-a-master-mp4-2": "/nejnovejsi-videa",
  "/zemiakove-buchty-skvele-samotne-ako-priloha-aj-na-grilovacku":
    "/nejnovejsi-videa",
  "/cesta-k-programu-vostok-misia-sputnik-2": "/nejnovejsi-videa",
  "/centralny-stupen-sls-je-na-floride": "/nejnovejsi-videa",
  "/priznaky-sexualneho-zneuzivania-ako-spoznat-zneuzite-dieta-a-ako-mu-pomoct":
    "/nejnovejsi-videa",
  "/dlhy-pochod-5b-falcon-9-tianhe-starlink-1-24-29-4-5-18-5-44":
    "/nejnovejsi-videa",
  "/cina-zacala-budovanie-kozmickej-stanice": "/nejnovejsi-videa",
  "/ako-trvalo-schudnut-a-netrapit-sa-ide-to-a-navyse-bez-diety":
    "/nejnovejsi-videa",
  "/vieteze27-2-mp4": "/nejnovejsi-videa",
  "/europska-raketa-vega-sa-vratila-po-nehode": "/nejnovejsi-videa",
  "/prvy-ostry-crew-dragon-sa-bezpecne-vratil": "/nejnovejsi-videa",
  "/ernest-hemingway-napinavy-zivotny-pribeh-drzitela-nobelovej-ceny-a-velkeho-dobrodruha":
    "/nejnovejsi-videa",
  "/antena-a-rameno-europskej-sondy-juice": "/nejnovejsi-videa",
  "/ako-sa-lord-mikynsky-lordom-stal": "/nejnovejsi-videa",
  "/ceske-krajiny-v-18-storoci-raj-plny-kvalitnych-potravin-vratane-safranu-alebo-melonov":
    "/nejnovejsi-videa",
  "/satay-azijska-marinada-dip-a-dresing-v-jednom": "/nejnovejsi-videa",
  "/dvadsiatapiata-porcia-druzic-starlink": "/nejnovejsi-videa",
  "/papas-bravas-pikantne-vyprazane-zemiaky-v-poriadnej-davke-omacky":
    "/nejnovejsi-videa",
  "/starship-sn15-skok-do-10-km-6-5-0-24": "/nejnovejsi-videa",
  "/rychly-tip-ako-dodat-polievkam-aj-omackam-skvelu-chut-a-vonu":
    "/nejnovejsi-videa",
  "/ako-sa-stravovat-aby-ste-boli-v-psychickej-pohode": "/nejnovejsi-videa",
  "/co-je-to-pedofilia-je-velky-rozdiel-medzi-pedofilom-a-osobou-ktora-deti-sexualne-zneuziva":
    "/nejnovejsi-videa",
  "/pribeh-richarda-s-deviaciou-sa-clovek-narodi-aj-zomrie-nemozno-ju-vyliecit":
    "/nejnovejsi-videa",
  "/starship-sn15-zvladla-skok-do-10-kilometrov": "/nejnovejsi-videa",
  "/sparglove-tagliatelle-s-mandlami-a-chilli-sposob-ako-zacat-zboznovat-cesku-bielu-sparglu":
    "/nejnovejsi-videa",
  "/ingenuity-si-aj-po-piatykrat-posunula-moznosti": "/nejnovejsi-videa",
  "/mimoriadny-prilet-aeroflotu-a320": "/nejnovejsi-videa",
  "/jubilejne-desiate-pouzitie-prveho-stupna": "/nejnovejsi-videa",
  "/stejk-florida-maso-syr-a-ovocie-stvorili-legendu-90-rokov":
    "/nejnovejsi-videa",
  "/retro-torta-florida-exoticky-sen-a-kaloricka-nocna-mora":
    "/nejnovejsi-videa",
  "/zanik-centralneho-stupna-rakety-dlhy-pochod-5b": "/nejnovejsi-videa",
  "/ako-vyuzit-prvy-jarny-hrasok-na-maximum-spracujeme-aj-struky":
    "/nejnovejsi-videa",
  "/sranda-skoncila-ide-sa-marinadkovat": "/nejnovejsi-videa",
  "/tip-na-najlepsie-kuracie-prsia": "/nejnovejsi-videa",
  "/presuny-techniky-na-zakladni-boca-chica": "/nejnovejsi-videa",
  "/zjavenia-panny-marie-vo-fatime-a-zazrak-ktory-videlo-desattisice-ludi":
    "/nejnovejsi-videa",
  "/policajne-vypady-nezvycajna-kradez-spodneho-pradla": "/nejnovejsi-videa",
  "/tlakove-skusky-modulu-nauka": "/nejnovejsi-videa",
  "/cesta-k-programu-vostok-misia-sputnik-6-9-a-10": "/nejnovejsi-videa",
  "/jazdenky-avensis-2-mp4": "/nejnovejsi-videa",
  "/jazdenky-avensis-2-mp4-2": "/nejnovejsi-videa",
  "/jazdenky-avensis-2-mp4-3": "/nejnovejsi-videa",
  "/jazdenky-avensis-2-mp4-5": "/nejnovejsi-videa",
  "/jazdenky-avensis-2-mp4-6": "/nejnovejsi-videa",
  "/toyota-avensis-tourer-unesie-aj-nechut-k-manzelstvu": "/nejnovejsi-videa",
  "/mtv-na-s310-b-master-mp4-2": "/nejnovejsi-videa",
  "/mtv-na-s402-a-master-mp4": "/nejnovejsi-videa",
  "/osca-mp4": "/nejnovejsi-videa",
  "/cina-uspesne-pristala-na-marse": "/nejnovejsi-videa",
  "/ake-deti-su-potencialne-ohrozene-sexualnym-zneuzivanim-a-patria-do-rizikovych-skupin":
    "/nejnovejsi-videa",
  "/slavoj-brichcin-priekopnik-systematickej-starostlivosti-o-sexualnych-delikventov":
    "/nejnovejsi-videa",
  "/start-falcon-9-starlink-1-26-16-5-00-56": "/nejnovejsi-videa",
  "/raketa-electron-tretikrat-zlyhala": "/nejnovejsi-videa",
  "/susene-ovocie-preco-sa-po-nom-moze-priberat-a-ako-je-to-s-jeho-sirenim":
    "/nejnovejsi-videa",
  "/renesancia-turistickych-letov-na-iss": "/nejnovejsi-videa",
  "/test-kropenia-novej-rampy-vodou": "/nejnovejsi-videa",
  "/start-atlas-v-sbirs-geo-5-18-5-19-31": "/nejnovejsi-videa",
  "/vegetariani-vs-masozruti-kto-je-vacsi-zvrhlik": "/nejnovejsi-videa",
  "/tazky-udel-veganov-pred-rokom-1989-a-nevsedne-menu-prezidenta-zapotockeho":
    "/nejnovejsi-videa",
  "/druzica-vcasnej-vystrahy-vypustena": "/nejnovejsi-videa",
  "/chimichurri-bylinkova-cili-omacka-nielen-k-masu": "/nejnovejsi-videa",
  "/crumble-s-rebarborou-a-jahodami-jednoduchy-a-uzasne-chrumkavy":
    "/nejnovejsi-videa",
  "/cuketka-pivni-rohliky-mall-sk-mp4": "/nejnovejsi-videa",
  "/cuketka-smazena-treska-mall-sk-mp4": "/nejnovejsi-videa",
  "/honda-civic-vesmirna-lod-pre-bezny-den": "/nejnovejsi-videa",
  "/exhibicionizmus-silne-nutkavej-deviacii-nejde-vzdorovat-trpia-nou-ale-skor-bojazlivi-muzi":
    "/nejnovejsi-videa",
  "/pribeh-vaclava-svoju-deviaciu-nezvladal-podstupil-kastraciu-je-to-liecba-alebo-trest":
    "/nejnovejsi-videa",
  "/walt-disney-vizionar-ktory-zapocal-zlatu-eru-animacie-pred-takmer-sto-rokmi":
    "/nejnovejsi-videa",
  "/dve-skusky-pre-program-artemis": "/nejnovejsi-videa",
  "/rover-zhurong-zisiel-na-povrch-marsu": "/nejnovejsi-videa",
  "/ako-spravne-cistit-pracku": "/nejnovejsi-videa",
  "/ako-zorganizovat-a-skraslit-malu-kupelnu-aby-mal-kazdy-clen-domacnosti-vsetko-po-ruke":
    "/nejnovejsi-videa",
  "/osviezujuca-melonova-salsa-na-letne-grilovanie": "/nejnovejsi-videa",
  "/odriekat-si-tucne-a-sladke-alebo-jest-vsetko-ale-v-malych-porciach":
    "/nejnovejsi-videa",
  "/vajcia-podla-batu-morsky-jazyk-podla-ministra-priemyslu-ake-bola-jedla-pomenovane-po-slavnych-osobnostiach":
    "/nejnovejsi-videa",
  "/spaceshiptwo-unity-v-kozmickom-priestore": "/nejnovejsi-videa",
  "/s1-e2-nie-som-cinan": "/nejnovejsi-videa",
  "/testy-cinskeho-kyslikometanoveho-motora": "/nejnovejsi-videa",
  "/europske-rameno-pre-modul-nauka": "/nejnovejsi-videa",
  "/nudny-start-falcone-kdeze-plno-zaujimavosti": "/nejnovejsi-videa",
  "/sexuolog-petr-weiss-posledne-nutene-kastracie-vykonaval-hitler-ja-som-uplne-proti":
    "/nejnovejsi-videa",
  "/ceskoslovensko-ako-svetovy-priekopnik-sexuologie": "/nejnovejsi-videa",
  "/jeme-cim-dalej-zdravsie-ale-priberame-ako-je-to-mozne": "/nejnovejsi-videa",
  "/s1-e3-spolocne-to-zvladneme": "/nejnovejsi-videa",
  "/domace-vytunene-specle-jednoduchsie-nez-halusky": "/nejnovejsi-videa",
  "/nudny-start-falcone-kdeze-plno-zaujimavosti-2": "/nejnovejsi-videa",
  "/medailon-alexandra-lukasenka": "/nejnovejsi-videa",
  "/start-sojuz-2-1b-oneweb-6-28-5-19-20": "/nejnovejsi-videa",
  "/dalsia-porcia-druzic-oneweb": "/nejnovejsi-videa",
  "/milada-horakova-jedna-z-najvacsich-ceskych-hrdiniek-a-obet-brutalneho-procesu":
    "/nejnovejsi-videa",
  "/ako-prebiehala-distancna-vyucba-naozaj": "/nejnovejsi-videa",
  "/technicke-vybavenie-problem-pre-ziakov-i-skoly": "/nejnovejsi-videa",
  "/dokonala-pracovna-miesto-kde-sa-dobre-sustredite-a-kde-vladne-poriadok":
    "/nejnovejsi-videa",
  "/ako-spravne-sediet-pri-pracovnom-stole-za-pravidelne-pretiahnutie-vam-chrbat-podakuje":
    "/nejnovejsi-videa",
  "/za-dve-minuty-je-hotovo-univerzalna-biela-omacka-sa-hodi-nielen-k-minutkovym-masam":
    "/nejnovejsi-videa",
  "/start-a-pripojenie-cinskej-lode-v-jeden-den": "/nejnovejsi-videa",
  "/cim-chcela-ceskoslovenska-kuchyna-ohromit-svet": "/nejnovejsi-videa",
  "/technicke-vybavenie-problem-pre-ziakov-i-skoly-2": "/nejnovejsi-videa",
  "/spojenie-dielov-pre-robotizovanu-raketu-sls": "/nejnovejsi-videa",
  "/s1-e5-vlada-za-to-nemoze": "/nejnovejsi-videa",
  "/s1-e6-mladej-moze-stary-musi": "/nejnovejsi-videa",
  "/zoom-teams-kde-frcala-online-vyucba-v-pohode-a-kde-to-naopak-viazlo":
    "/nejnovejsi-videa",
  "/prvy-tohtorocny-rusky-vystup-do-volneho-priestoru": "/nejnovejsi-videa",
  "/online-vyucba-co-sa-dialo-doma-za-vypnutou-kamerou": "/nejnovejsi-videa",
  "/mick-jagger-keith-richards-pol-storocia-na-rockovom-vyslni":
    "/nejnovejsi-videa",
  "/daju-sa-v-obchodoch-kupit-zdrave-hotovky-a-ako-ich-vyberat":
    "/nejnovejsi-videa",
  "/kto-je-patologicky-sexualny-agresor-a-cim-sa-lisi-od-sadistov":
    "/nejnovejsi-videa",
  "/agresivny-sadizmus-zavazna-deviacia-ktorou-trpia-seriovi-vrahovia":
    "/nejnovejsi-videa",
  "/lajfheky-9-0-finall-mp4": "/nejnovejsi-videa",
  "/zasobovacia-misia-lode-dragon-2-k-stanici-iss": "/nejnovejsi-videa",
  "/ako-sa-da-spestrit-a-ozivit-online-vyucba": "/nejnovejsi-videa",
  "/pu-ep19-online-mp4-2": "/nejnovejsi-videa",
  "/18-tohtorocny-start-falcon-9-bol-uspesny": "/nejnovejsi-videa",
  "/ako-narocna-bola-distancna-vyucba-pre-deti-zo-zahranicia":
    "/nejnovejsi-videa",
  "/kalerabove-listy-nevyhadzujte-naplnte-ich-ako-na-chrumkave-zavitky-s-risotto-a-syrom":
    "/nejnovejsi-videa",
  "/parmezanovy-chips-do-kremovych-polievok-a-salatov": "/nejnovejsi-videa",
  "/stavba-zakladne-starbase-boca-chica": "/nejnovejsi-videa",
  "/spacex-dokoncuje-stavbu-tretej-pristavacej-plosiny": "/nejnovejsi-videa",
  "/ako-si-utriedit-oblecenie-v-satni-poznajte-system-a-poriadok-uz-navzdy":
    "/nejnovejsi-videa",
  "/piliere-damskeho-satnika-a-ako-ich-kombinovat-radi-influencerka-zuzana-novakova-straska":
    "/nejnovejsi-videa",
  "/mlade-kalerabova-rezne-pecene-v-rure": "/nejnovejsi-videa",
  "/podme-na-seba-revat-s-laskou-ako-sa-zmenil-vztah-rodicov-a-deti":
    "/nejnovejsi-videa",
  "/marek-a-marek-pecu-na-pionieroch-2-v1-mov": "/nejnovejsi-videa",
  "/marek-a-marek-pecu-na-pionieroch-2-v1-mov-2": "/nejnovejsi-videa",
  "/s1-e7-chodiaci-experiment": "/nejnovejsi-videa",
  "/marek-a-marek-pecu-na-pionieroch-2-v1-mov-3": "/nejnovejsi-videa",
  "/s1-e10-sme-pozitivni": "/nejnovejsi-videa",
  "/pripravy-na-integraciu-obrieho-stupna": "/nejnovejsi-videa",
  "/ake-pokrmy-a-napoje-sa-odporucali-tehotnym-zenam-v-dobe-rudolfa-ii":
    "/nejnovejsi-videa",
  "/ako-zamavalo-odlucenie-s-psychikou-deti-aj-ucitelov": "/nejnovejsi-videa",
  "/po-20-rokoch-nove-fotky-mesiaca-ganymede": "/nejnovejsi-videa",
  "/europa-bude-tiez-skumat-venusu": "/nejnovejsi-videa",
  "/spekacky-na-pive-chaluparska-klasika-podla-koko": "/nejnovejsi-videa",
  "/zoofilia-tabu-o-ktorom-sa-vtipkuje-co-vsetko-o-nej-neviete":
    "/nejnovejsi-videa",
  "/mtv-na-s404-a-master-mp4": "/nejnovejsi-videa",
  "/ako-deti-poznamenala-online-vyucba-a-co-sa-vdaka-nej-zmeni-v-skolstve":
    "/nejnovejsi-videa",
  "/mtv-na-s404-a-master-mp4-2": "/nejnovejsi-videa",
  "/mtv-na-s409-b-master-mp4": "/nejnovejsi-videa",
  "/testovacie-a-ostre-misie-lodi-vostok": "/nejnovejsi-videa",
  "/obrie-spojenie-v-hale-vab": "/nejnovejsi-videa",
  "/steak-prepeceny-presne-podla-vasich-predstav-minutovy-princip-zvladne-uplne-kazdy":
    "/nejnovejsi-videa",
  "/ako-zharmonizovat-obyvaci-priestor-pracujte-s-farbami-aj-tvarmi":
    "/nejnovejsi-videa",
  "/ako-upratat-obyvacku-rozdelte-ju-do-jednotlivych-zon": "/nejnovejsi-videa",
  "/lucenie-s-raketou-pegasus-xl": "/nejnovejsi-videa",
  "/benjamin-franklin-muz-z-najslavnejsej-bankovky-sveta-stal-pri-zrode-usa-vedel-ale-aj-skrotit-blesky":
    "/nejnovejsi-videa",
  "/mikyr-a-marcus-live-debata-15-6-od-19-00": "/nejnovejsi-videa",
  "/potrebujem-kakat-trapasy-a-vtipy-pocas-online-vyucby": "/nejnovejsi-videa",
  "/ako-zrychlit-metabolizmus-pri-vecnom-dlhodobom-sedeni": "/nejnovejsi-videa",
  "/netradicne-palacinky-podla-prezidenta-edvarda-benesa": "/nejnovejsi-videa",
  "/making-of-ako-sa-natacal-serial-triedna-schodzka": "/nejnovejsi-videa",
  "/start-minotaur-1-nrol-111-15-6-15-35": "/nejnovejsi-videa",
  "/posobivy-pohlad-na-obrie-spojenie-rakety-sls": "/nejnovejsi-videa",
  "/start-dlhy-pochod-2f-shenzhou-12-17-6-3-22": "/nejnovejsi-videa",
  "/mtv-na-s404-a-master-mp4-3": "/nejnovejsi-videa",
  "/ako-zit-aby-sme-zostali-dlho-mladi-zdravi-a-plni-energie":
    "/nejnovejsi-videa",
  "/prva-posadka-v-novej-cinskej-stanici": "/nejnovejsi-videa",
  "/ako-falometria-od-50-rokov-zabranila-aby-sa-muzi-vyhybali-vojencine":
    "/nejnovejsi-videa",
  "/falometria-odhali-najskrytejsie-sexualne-preferencie-ako-prebieha-vysetrenie":
    "/nejnovejsi-videa",
  "/eva-peronova-povabna-bojovnicka-a-prva-dama-pre-ktoru-plakala-cela-argentina":
    "/nejnovejsi-videa",
  "/instalacia-kozmickej-toalety": "/nejnovejsi-videa",
  "/iss-bude-mat-viac-energie": "/nejnovejsi-videa",
  "/raketa-pre-robotizovanu-lod-starliner": "/nejnovejsi-videa",
  "/adela-z-agrofertu-ktoru-nevlastni-nas-premier": "/nejnovejsi-videa",
  "/ako-na-dokonale-pecene-hovadzie-rebra-naozajstna-delikatesa-skoro-bez-prace":
    "/nejnovejsi-videa",
  "/lajfheky-11-0-finall-mp4": "/nejnovejsi-videa",
  "/ako-konzumovat-maso-ktore-preferovat-a-ktoremu-sa-skor-vyhnut":
    "/nejnovejsi-videa",
  "/dalsi-diel-pre-premierovu-raketu-sls": "/nejnovejsi-videa",
  "/pritahuju-vas-zeny-muzi-alebo-nieco-uplne-ine-ako-sa-odhaluju-sexualne-preferencie":
    "/nejnovejsi-videa",
  "/prevoz-horneho-stupna-pre-raketu-sls": "/nejnovejsi-videa",
  "/kanadska-ruka-manipulator-srms-historia": "/nejnovejsi-videa",
  "/ruska-armadna-druzica-pre-zber-signalov": "/nejnovejsi-videa",
  "/joseph-smith-zakladatel-mormonskej-cirkvi-a-samozvany-bozi-prorok-neprezil-krvavy-lync":
    "/nejnovejsi-videa",
  "/rozvoj-zakladne-boca-chica-neberie-konca": "/nejnovejsi-videa",
  "/ako-do-spalne-vratit-priestor-pre-intimitu-i-pokojny-spanok":
    "/nejnovejsi-videa",
  "/kvalitny-spanok-ovplyvnuje-zdravie-i-dlhovekost-ako-dobre-a-dostatocne-spat":
    "/nejnovejsi-videa",
  "/progress-ms-17-caka-na-start": "/nejnovejsi-videa",
  "/co-sa-stane-ked-omylom-zjeme-plesnivu-potravinu": "/nejnovejsi-videa",
  "/start-launcherone-misia-tubular-bells-30-6-16-50": "/nejnovejsi-videa",
  "/progress-ms-17-pripojenie-k-iss-2-7-3-02": "/nejnovejsi-videa",
  "/franz-kafka-neuveritelna-cesta-k-literarnej-slave": "/nejnovejsi-videa",
  "/prevoz-najvacsieho-raketoveho-stupna-v-dejinach": "/nejnovejsi-videa",
  "/knedle-s-vajickom-par-tipov-ako-z-uplne-obycajneho-jedla-urobit-potesenie":
    "/nejnovejsi-videa",
  "/projekt-oneweb-sa-blizi-na-spustenie-sluzieb": "/nejnovejsi-videa",
  "/ake-potraviny-pomozu-najlepsie-zregenerovat-svaly": "/nejnovejsi-videa",
  "/zarucene-tipy-na-zaruceny-zivot-ktore-zarucene-funguju":
    "/nejnovejsi-videa",
  "/posledny-clanok-cinskej-siete-prenosovych-druzic": "/nejnovejsi-videa",
  "/druhy-cinsky-vystup-do-volneho-priestoru": "/nejnovejsi-videa",
  "/mate-vlastnu-prevadzkaren-takto-sa-k-vam-klienti-budu-radi-vracat":
    "/nejnovejsi-videa",
  "/special-preco-hromadime-tolko-veci": "/nejnovejsi-videa",
  "/na-ceste-k-novej-generacii-systemov-podpory-zivota": "/nejnovejsi-videa",
  "/dva-bezchybne-tipy-ako-zobrat-z-omacky-vypeceny-tuk-nevyhadzujte-ho-bude-sa-hodit":
    "/nejnovejsi-videa",
  "/kanadska-ruka-manipulator-srms-zabezpecenie-a-ovladanie":
    "/nejnovejsi-videa",
  "/horny-stupen-obrovskej-rakety-sls-je-na-svojom-mieste": "/nejnovejsi-videa",
  "/nevidany-pohlad-na-instalaciu-panelov-na-iss": "/nejnovejsi-videa",
  "/co-nie-je-v-poriadku-s-ockovanim": "/nejnovejsi-videa",
  "/maniak-feat-mikyr-uzasna-put-internetom-official-video":
    "/nejnovejsi-videa",
  "/ktore-potraviny-zlepsuju-sex-libido-povzbudi-zinok-flavonoidy-aj-cokolada":
    "/nejnovejsi-videa",
  "/ako-vytvorit-z-predsiene-krasnu-a-prakticku-vizitku-vasej-domacnosti":
    "/nejnovejsi-videa",
  "/ako-efektivne-usporiadat-domacu-lekarnicku-a-potravinove-doplnky":
    "/nejnovejsi-videa",
  "/spaceshiptwo-unity-vyniesla-sest-ludi-do-86-kilometrov":
    "/nejnovejsi-videa",
  "/vyp-6-fin-mp4": "/nejnovejsi-videa",
  "/co-jest-v-lete-paradajkova-polievka-nahradi-lahky-obed":
    "/nejnovejsi-videa",
  "/dragon-2-priviezol-2-3-tony-nakladu-z-iss": "/nejnovejsi-videa",
  "/jan-zizka-hrdina-ceskych-dejin-alebo-nabozensky-fanatik":
    "/nejnovejsi-videa",
  "/astris-dodatocny-europsky-horny-stupen": "/nejnovejsi-videa",
  "/lod-orion-dostane-zachrannu-vezicku": "/nejnovejsi-videa",
  "/predlzovaci-nadstavec-manipulatora-obss": "/nejnovejsi-videa",
  "/modul-nauka-caka-na-startovej-rampe": "/nejnovejsi-videa",
  "/prvy-stupen-atlasu-v-priviezlo-lietadlo": "/nejnovejsi-videa",
  "/kolko-gramov-soli-denne-konzumovat-prijem-v-cesku-je-neunosny":
    "/nejnovejsi-videa",
  "/ako-urobit-z-izbicky-male-kralovstvo-kde-deti-samy-lahko-udrzia-poriadok":
    "/nejnovejsi-videa",
  "/ako-usporiadat-detsku-kniznicu": "/nejnovejsi-videa",
  "/lucid-1-makeupnatvar-oprava-mp4": "/nejnovejsi-videa",
  "/start-new-shepard-ns-16-20-7-15-04": "/nejnovejsi-videa",
  "/prvy-staticky-zazih-super-heavy": "/nejnovejsi-videa",
  "/start-proton-m-nauka-21-7-16-58": "/nejnovejsi-videa",
  "/new-shepard-vyniesol-prvu-posadku": "/nejnovejsi-videa",
  "/modul-nauka-postretli-problemy": "/nejnovejsi-videa",
  "/uvod-do-problematiky-mikrogravitacie": "/nejnovejsi-videa",
  "/emil-zatopek-vitazstvo-ktore-uz-nikto-neprekona": "/nejnovejsi-videa",
  "/stav-modulu-nauka-sa-postupne-zlepsuje": "/nejnovejsi-videa",
  "/bylinky-ako-dezert-mp4": "/nejnovejsi-videa",
  "/divoke-byliny-v-kazdodennom-zivote-mp4": "/nejnovejsi-videa",
  "/vakuove-testy-sondy-juice-skoncili": "/nejnovejsi-videa",
  "/idete-do-toho-s-nami": "/nejnovejsi-videa",
  "/carpaccio-z-peceneho-melona-naslano-podla-koko": "/nejnovejsi-videa",
  "/nauka-doletela-k-iss": "/nejnovejsi-videa",
  "/dobry-lepsi-a-skaredy": "/nejnovejsi-videa",
  "/raketa-electron-sa-vratila-do-sluzby": "/nejnovejsi-videa",
  "/augustus-najdlhsie-vladnuci-cisar-a-jedna-z-najvacsich-postav-starovekej-historie":
    "/nejnovejsi-videa",
  "/zakladna-boca-chica-sa-chysta-na-orbitalnu-misiu": "/nejnovejsi-videa",
  "/ako-spravne-komentovat-na-facebooku": "/nejnovejsi-videa",
  "/je-rychly-metabolizmus-naozaj-vrodeny": "/nejnovejsi-videa",
  "/ariane-5-odstartovala-po-takmer-rocnej-pauze": "/nejnovejsi-videa",
  "/tieto-zabery-ste-na-zahajeni-urcite-nevideli": "/nejnovejsi-videa",
  "/skece-epi-2-auta-mp4": "/nejnovejsi-videa",
  "/lod-starliner-caka-na-reparat": "/nejnovejsi-videa",
  "/kto-je-vseobecne-lepsi-clovek-ben-vs-mikyr-2": "/nejnovejsi-videa",
  "/marilyn-monroe-zahadna-smrt-najvacsej-sexualnej-ikony-20-storocia":
    "/nejnovejsi-videa",
  "/super-heavy-s-29-motormi-raptor-mieri-k-testom": "/nejnovejsi-videa",
  "/najlepsia-ryba-bez-kosti-aj-bez-prace": "/nejnovejsi-videa",
  "/co-organizatori-olympijskych-hier-nechcu-aby-ste-videli":
    "/nejnovejsi-videa",
  "/stanicu-iss-opustil-modul-pirs": "/nejnovejsi-videa",
  "/ako-vyberat-izbove-kvetiny-ktore-su-jedovate-alebo-znizuju-chut-na-sex":
    "/nejnovejsi-videa",
  "/ako-sa-starat-o-rezane-kvety-aby-vydrzali-co-najdlhsie-pomoze-aj-kladivko":
    "/nejnovejsi-videa",
  "/padova-veza-zarm-v-bremach": "/nejnovejsi-videa",
  "/prve-zostavenie-najvyssej-rakety-sveta": "/nejnovejsi-videa",
  "/skusky-dielov-pre-motory-rakety-sls": "/nejnovejsi-videa",
  "/preco-maju-byt-huby-beznou-sucastou-jedalnicka": "/nejnovejsi-videa",
  "/kozmicke-olympijske-hry-na-iss": "/nejnovejsi-videa",
  "/skuska-pristatia-cinskej-rakety": "/nejnovejsi-videa",
  "/start-antares-cygnus-ng-16-11-8-0-01": "/nejnovejsi-videa",
  "/nakladna-lod-cygnus-mieri-k-iss": "/nejnovejsi-videa",
  "/cygnus-ng-16-zachytenie-u-iss-12-8-12-10": "/nejnovejsi-videa",
  "/indicka-raketa-gslv-mk-ii-zlyhala": "/nejnovejsi-videa",
  "/lech-wa≈Çesa-ako-sa-elektrikar-z-gdanskych-lodenic-stal-zakladatelom-solidarity-a-polskym-prezidentom":
    "/nejnovejsi-videa",
  "/start-lodi-starliner-sa-vyrazne-odklada": "/nejnovejsi-videa",
  "/dva-gravitacne-manevre-pri-venusi": "/nejnovejsi-videa",
  "/mikrozelenina-atraktivna-naloz-zdravia-mlade-rastlinky-maju-az-o-40-viac-zivin-ako-tie-dospele":
    "/nejnovejsi-videa",
  "/stavba-samovrazednej-sondy-finisuje": "/nejnovejsi-videa",
  "/najrychlejsi-vege-gulas-na-svete-na-chuti-to-ale-nespoznate":
    "/nejnovejsi-videa",
  "/sranda-skoncila-ide-sa-dakovat": "/nejnovejsi-videa",
  "/parabolicke-lety-lietadiel": "/nejnovejsi-videa",
  "/tajomstvo-bylin-nech-zije-imunita-mp4": "/nejnovejsi-videa",
  "/europska-raketa-vyniesla-5-druzic": "/nejnovejsi-videa",
  "/elvis-presley-od-sexualneho-symbolu-po-totalne-vycerpanie-ako-kral-rock-n-rollu-zmenil-hudbu":
    "/nejnovejsi-videa",
  "/prehliadka-modulu-nauka": "/nejnovejsi-videa",
  "/docasny-ale-dolezity-pomocnik-rakety-sls": "/nejnovejsi-videa",
  "/uvod-do-temy-vyskovych-rakiet": "/nejnovejsi-videa",
  "/treti-cinsky-vystup-do-volneho-priestoru": "/nejnovejsi-videa",
  "/deviata-porcia-druzic-oneweb": "/nejnovejsi-videa",
  "/stavnaty-burger-v-azijskom-style-podla-koko": "/nejnovejsi-videa",
  "/pitie-alkoholu-po-40-kedy-sa-z-ritualu-stava-problem": "/nejnovejsi-videa",
  "/vrtulnik-ingenuity-opat-posuva-svoje-moznosti": "/nejnovejsi-videa",
  "/zaujimavy-teren-v-okoli-roveru-curiosity": "/nejnovejsi-videa",
  "/premysl-otakar-ii-sen-o-ovladnutie-europy-skoncil-porazkou-v-jednej-z-nasich-najslavnejsich-bitiek":
    "/nejnovejsi-videa",
  "/ake-leto-mali-nasi-politici": "/nejnovejsi-videa",
  "/jaro-leto-mp4": "/nejnovejsi-videa",
  "/lajfheky-17-0-final-mp4": "/nejnovejsi-videa",
  "/skece-epi-4-slavne-osobnosti-mp4": "/nejnovejsi-videa",
  "/skece-epi-5-deti-mp4": "/nejnovejsi-videa",
  "/skece-epi-6-varenie-mp4": "/nejnovejsi-videa",
  "/filmy-ocami-expertov-epi-1-mp4": "/nejnovejsi-videa",
  "/ake-leto-mali-nasi-politici-2": "/nejnovejsi-videa",
  "/najluxusnejsi-letny-grilovany-spiz": "/nejnovejsi-videa",
  "/vajcia-skotskych-hubarov-jedno-z-najoblubenejsich-piknikovych-jedal":
    "/nejnovejsi-videa",
  "/005-doby-malu-fatru-mp4": "/nejnovejsi-videa",
  "/03-stanfordsky-experiment-final-edit-mp4": "/nejnovejsi-videa",
  "/historia-a-sucasnost-vyskovych-rakiet": "/nejnovejsi-videa",
  "/novy-suborbitalny-miniraketoplan": "/nejnovejsi-videa",
  "/orechove-maslo-ako-alternativa-ku-smotanovej-klasike": "/nejnovejsi-videa",
  "/raketam-atlas-v-zostava-poslednych-29-startov": "/nejnovejsi-videa",
  "/recyklovany-dragon-2-dorucil-na-iss-dve-tony-nakladu": "/nejnovejsi-videa",
  "/papezova-navsteva-na-slovensku": "/nejnovejsi-videa",
  "/skusky-tankovania-rakety-vulcan": "/nejnovejsi-videa",
  "/nove-prikazane-uvolnenie-flash": "/nejnovejsi-videa",
  "/forbiggerescapes-mp4": "/nejnovejsi-videa",
  "/rover-perseverance-odobral-prvu-vzorku": "/nejnovejsi-videa",
  "/start-firefly-alpha-dream-3-9-3-00": "/nejnovejsi-videa",
  "/trhane-bravcove-maso-s-ruzovym-salatom-coleslaw": "/nejnovejsi-videa",
  "/nase-zdravotnictvo": "/nejnovejsi-videa",
  "/deti-to-vedia-najlepsie-hudba-mp4": "/nejnovejsi-videa",
  "/europske-vyskove-rakety": "/nejnovejsi-videa",
  "/prva-raketa-alpha-zlyhala": "/nejnovejsi-videa",
  "/geronimo-masovy-vrah-alebo-statocny-bojovnik-proti-utlaku-indianov":
    "/nejnovejsi-videa",
  "/padaju-zaludia-zelajte-si-nieco": "/nejnovejsi-videa",
  "/stredisko-nasa-po-vycinani-hurikanu": "/nejnovejsi-videa",
  "/s-e-total-e-v-prdeli-vy-vetlim": "/nejnovejsi-videa",
  "/postupna-aktivacia-modulu-nauka-zacala": "/nejnovejsi-videa",
  "/velky-muz-na-ceste-okolo-slovenska": "/nejnovejsi-videa",
  "/ktorym-potravinam-sa-v-tehotenstve-stopercentne-vyhnut":
    "/nejnovejsi-videa",
  "/start-druhej-snimkovacej-druzice-gaofen-5": "/nejnovejsi-videa",
  "/spravodlivost": "/nejnovejsi-videa",
  "/nemecko-zvazuje-plavajuci-kozmodrom": "/nejnovejsi-videa",
  "/losos-udeny-v-esuse-uz-nikdy-ho-nebudete-chciet-jest-inak":
    "/nejnovejsi-videa",
  "/kovova-planetka-caka-na-svoju-sondu": "/nejnovejsi-videa",
  "/vyskum-v-padovych-veziach-parabolickych-rokoch-a-vyskovych-raketach":
    "/nejnovejsi-videa",
  "/sojuz-2-1v-vyniesol-zobrazovaciu-druzicu": "/nejnovejsi-videa",
  "/druhe-dejstvo-ozivovania-modulu-nauka": "/nejnovejsi-videa",
  "/cuketka-hrachovka-mall-sk-mp4-2": "/nejnovejsi-videa",
  "/cuketka-kvaskova-babovkamall-sk-mp4": "/nejnovejsi-videa",
  "/004-soc-experimenty-sexujue-blchy-mp4": "/nejnovejsi-videa",
  "/oda-na-roberta-fica": "/nejnovejsi-videa",
  "/ako-naliat-olej-do-motora-bez-rozliatia": "/nejnovejsi-videa",
  "/ako-pomaha-obycajna-voda-chudnut-staci-dodrziavat-tri-zasady":
    "/nejnovejsi-videa",
  "/webbov-teleskop-poleti-pred-vianocami": "/nejnovejsi-videa",
  "/tomio-ma-bud-pipik-alebo-pipinku-ukazeme-vam-pravdu": "/nejnovejsi-videa",
  "/nezvycajny-japonsko-francuzsky-vystup-z-iss": "/nejnovejsi-videa",
  "/desiata-porcia-druzic-oneweb": "/nejnovejsi-videa",
  "/14-0-v-neprospech-kovacika": "/nejnovejsi-videa",
  "/polnohospodarstvo": "/nejnovejsi-videa",
  "/start-falcon-9-inspiration4-16-9-2-00": "/nejnovejsi-videa",
  "/unikatna-misia-bez-profesionalnych-astronautov": "/nejnovejsi-videa",
  "/frida-kahlo-nekonvencna-zena-bisexualka-a-maliarka-ktora-vtlacila-zivotnu-bolest-do-unikatnych-obrazov":
    "/nejnovejsi-videa",
  "/sestonedelie-doba-hojenia-a-hajenia-ako-sa-da-vyrovnat-s-poporodnom-blues":
    "/nejnovejsi-videa",
  "/vyskove-rekordy-pri-pilotovanych-kozmickych-letoch": "/nejnovejsi-videa",
  "/hybte-sa-spravne-ep5-zahrada-final-cut-mp4": "/nejnovejsi-videa",
  "/sok-experimenty-ep5-sfetovani-vojaci-final-cut-mp4": "/nejnovejsi-videa",
  "/sok-experimenty-ep5-sfetovani-vojaci-final-cut-opraveny-zvuk-mp4":
    "/nejnovejsi-videa",
  "/obrovsky-camembert-zapeceny-v-listkovom-ceste-ako-originalne-pohostenie":
    "/nejnovejsi-videa",
  "/skoncila-najdlhsia-cinska-pilotovana-misia": "/nejnovejsi-videa",
  "/navrat-unikatne-misie-inspiration4": "/nejnovejsi-videa",
  "/preco-je-slovensko-polovnicky-stat": "/nejnovejsi-videa",
  "/006-sok-experimenty-dvojhlave-psy-mp4": "/nejnovejsi-videa",
  "/pikova-dama-plackova-a-jej-prvy-exkluzivny-rozhovor-po-zadrzani-policiou":
    "/nejnovejsi-videa",
  "/ako-kvalitne-nahradit-maso-a-preco-by-aj-masozruti-mali-obmedzit-jeho-konzumaciu":
    "/nejnovejsi-videa",
  "/druha-faza-budovania-siete-starlink": "/nejnovejsi-videa",
  "/dalsia-cinska-kozmicka-nakladna-lod": "/nejnovejsi-videa",
  "/lunarne-vozitko-mapujuce-pritomnost-vody": "/nejnovejsi-videa",
  "/boris-kollar": "/nejnovejsi-videa",
  "/vydatna-a-vonava-francuzska-cesnacka": "/nejnovejsi-videa",
  "/test-oddelenie-ramien-od-rakety-sls": "/nejnovejsi-videa",
  "/dojcenie-myty-predsudky-a-3-najvacsie-problemy-s-ktorymi-sa-ceske-matky-stretavaju":
    "/nejnovejsi-videa",
  "/vyrocie-zaniku-druzice-magion-1": "/nejnovejsi-videa",
  "/zosrotovanie-mobilnej-startovej-plosiny": "/nejnovejsi-videa",
  "/neobvykla-posadka-lode-sojuz": "/nejnovejsi-videa",
  "/vesmir-lubosa-blahu": "/nejnovejsi-videa",
  "/7-hodin-bez-facebooku": "/nejnovejsi-videa",
  "/lanove-semienko-a-jeho-zazracne-zlozenie-prirodne-prehanadlo-pomoze-aj-pri-chudnuti":
    "/nejnovejsi-videa",
  "/ako-sa-dostaneme-z-prie-eru-endikurvakvolbam": "/nejnovejsi-videa",
  "/prvy-krok-k-likvidacii-vysluzilych-druzic": "/nejnovejsi-videa",
  "/pol-storocia-dlhy-program-monitorovania-zeme-pokracuje":
    "/nejnovejsi-videa",
  "/007-sok-experimenty-mozog-z-mliecnych-zubov-final-cut-mp4":
    "/nejnovejsi-videa",
  "/nesputany-james-dean-miloval-rychlost-zabil-sa-vo-veku-24-rokov-svet-prisiel-o-velky-talent-i-sexy-idol":
    "/nejnovejsi-videa",
  "/top-5-celebrit-ktore-to-nedali": "/nejnovejsi-videa",
  "/bepicolombo-prvykrat-pri-merkure": "/nejnovejsi-videa",
  "/komplexne-geneticke-vysetrenie-mate-dispozicie-k-rakovine-kedy-budete-plesati-a-akeho-veku-sa-dozijete":
    "/nejnovejsi-videa",
  "/koho-bude-mikyr-volit-endikurvakvolbam": "/nejnovejsi-videa",
  "/prve-fotky-merkuru-z-bepicolombo": "/nejnovejsi-videa",
  "/modernejsie-verzie-motorov-rs-25": "/nejnovejsi-videa",
  "/mikyrova-uzasna-put-volbami-2021": "/nejnovejsi-videa",
  "/ako-by-vyzeralo-slovensko-keby-bol-komunizmus": "/nejnovejsi-videa",
  "/pouzity-dragon-2-sa-uspesne-vratil": "/nejnovejsi-videa",
  "/posledny-pohlad-na-sondu-lucy": "/nejnovejsi-videa",
  "/slaninove-chipsy-krasne-chrumkave-dokonale-rovne-do-salatov-i-polievok":
    "/nejnovejsi-videa",
  "/hrejivy-kukuricny-krem-bez-krajania-a-priprav-hotovo-za-15-minut":
    "/nejnovejsi-videa",
  "/presun-astronautov-zo-starliner-na-crew-dragon": "/nejnovejsi-videa",
  "/co-sa-to-deje-so-slovenskym-futbalom": "/nejnovejsi-videa",
  "/viete-co-moze-priniest-zene-lepsi-orgazmus-2": "/nejnovejsi-videa",
  "/v-aku-dennu-dobu-konzumovat-ovocie-a-kedy-uz-je-fruktoza-na-skodu":
    "/nejnovejsi-videa",
  "/odovzdavanie-velenia-na-iss": "/nejnovejsi-videa",
  "/hra-o-nasich-ludi": "/nejnovejsi-videa",
  "/008-sok-experimenty-mengele-final-cut-mp4": "/nejnovejsi-videa",
  "/ep03-lula-mov": "/nejnovejsi-videa",
  "/namiesto-oslavy-poriadna-hanba": "/nejnovejsi-videa",
  "/edith-piaf-z-biedy-medzi-prostitutkami-az-k-prvej-dame-francuzskeho-sansonu":
    "/nejnovejsi-videa",
  "/samovrazedna-sonda-dostala-maleho-pomocnika": "/nejnovejsi-videa",
  "/polohovanie-spravna-manipulacia-s-dietatom-sa-podcenuje-tri-zakladne-instrukcie-by-mala-poznat-kazda-mamicka":
    "/nejnovejsi-videa",
  "/prelet-iss-nad-nocou-cinou": "/nejnovejsi-videa",
  "/webbov-teleskop-dorazil-do-juznej-ameriky": "/nejnovejsi-videa",
  "/druhy-pilotovany-let-new-shepard": "/nejnovejsi-videa",
  "/ktore-tuky-su-najlepsie-pre-teplu-a-ktore-pre-studenu-kuchynu":
    "/nejnovejsi-videa",
  "/prazene-mandle-naslano-genialne-a-dokonale": "/nejnovejsi-videa",
  "/slovensko-cestuje-na-sviatky": "/nejnovejsi-videa",
  "/maria-antoinetta-francuzska-kralovna-bola-jednou-z-najkontroverznejsich-zien-europskej-historie":
    "/nejnovejsi-videa",
  "/cinska-pilotovana-misia-dorazila-k-stanici": "/nejnovejsi-videa",
  "/sonda-lucy-vyrazila-na-jedinecnu-misiu": "/nejnovejsi-videa",
  "/stale-este-tabu-muzsky-prechod-cize-andropauza": "/nejnovejsi-videa",
  "/detsky-spanok-vecna-tema-plna-mytov-kedy-uspavat-a-kolko-hodin-v-kuse-ma-dieta-spat":
    "/nejnovejsi-videa",
  "/to-najhorsie-zo-slovenskeho-parlamentu": "/nejnovejsi-videa",
  "/prva-raketa-sls-je-zostavena": "/nejnovejsi-videa",
  "/mikyr-si-to-rozda-s-jirkom-kralom": "/nejnovejsi-videa",
  "/9-exp-nespavost-02-mp4": "/nejnovejsi-videa",
  "/9-exp-nespavost-03-mp4": "/nejnovejsi-videa",
  "/autonomna-jazda-po-marse": "/nejnovejsi-videa",
  "/ranajky-sampionov-vo-svajciarskom-style-zaberu-minimum-casu-a-prace":
    "/nejnovejsi-videa",
  "/ako-v-zlom-filme": "/nejnovejsi-videa",
  "/premiera-juhokorejskej-rakety-nuri": "/nejnovejsi-videa",
  "/tipy-ako-z-obycajnych-kuracej-pecene-urobit-delikatesu":
    "/nejnovejsi-videa",
  "/slovenski-narodni-hrdinovia": "/nejnovejsi-videa",
  "/modul-pirs-prilet-k-stanici-iss": "/nejnovejsi-videa",
  "/tycho-brahe-nahle-a-nevysvetlitelne-umrtie-najlepsieho-a-najpresnejsieho-pozorovatela-hviezdnej-oblohy":
    "/nejnovejsi-videa",
  "/usadenie-lode-orion-na-raketu-sls": "/nejnovejsi-videa",
  "/ariane-5-vyniesla-dve-druzice-a-pripisala-si-rekord": "/nejnovejsi-videa",
  "/neranajkovat-priberat-ako-sa-absencia-ranajok-prejavi-na-vahe":
    "/nejnovejsi-videa",
  "/tip-co-s-prebytocnymi-bylinkami-petrzlenovy-olej-do-polievok-salatov-a-predjedal":
    "/nejnovejsi-videa",
  "/bonus-o-kojeni-v-cesku-statistiky-su-alarmujuce": "/nejnovejsi-videa",
  "/dlhe-preparkovanie-progressu-ms-17": "/nejnovejsi-videa",
  "/nova-japonska-navigacna-druzica": "/nejnovejsi-videa",
  "/progress-ms-18-caka-na-start": "/nejnovejsi-videa",
  "/m-r-stefanik-charizmaticky-a-neunavny-bojovnik-za-samostatny-stat-nikdy-nevkrocil-na-jeho-podu-zahynul-na-nej":
    "/nejnovejsi-videa",
  "/chata-mp4": "/nejnovejsi-videa",
  "/chalani-si-vyskusali-ake-je-to-byt-vysokohorskym-nosicom":
    "/nejnovejsi-videa",
  "/vybalovanie-dalekohladu-jamesa-webba": "/nejnovejsi-videa",
  "/pocasie-odklada-start-lode-crew-dragon": "/nejnovejsi-videa",
  "/kto-je-zahadny-nechcem-hlasovat-w-czc-cz": "/nejnovejsi-videa",
  "/slovensko-a-vystava-expo": "/nejnovejsi-videa",
  "/karlovarska-knedla-varena-v-pare-stavka-na-istotu": "/nejnovejsi-videa",
  "/kozmicky-zber-papriciek": "/nejnovejsi-videa",
  "/startovy-stol-pre-rakety-angara": "/nejnovejsi-videa",
  "/howard-hughes-miliardar-oslnil-svojimi-vynalezmi-i-filmami-svet-skoncil-ako-nestastny-cudak":
    "/nejnovejsi-videa",
  "/posobive-pohlady-na-dva-starty-rakiet": "/nejnovejsi-videa",
  "/vybalenie-sondy-dart": "/nejnovejsi-videa",
  "/rastlinne-mlieka-ako-sa-nenechat-opit-zafarbenou-vodou":
    "/nejnovejsi-videa",
  "/poporodny-smutok-depresia-i-psychoza-pre-cerstve-mamicky-stale-este-stigma":
    "/nejnovejsi-videa",
  "/viete-ze-co-je-najpritazlivejsia-cast-muzskeho-tela-mp4":
    "/nejnovejsi-videa",
  "/vitajte-v-tretej-vlne": "/nejnovejsi-videa",
  "/sipkove-pyre-zaklad-slavnej-sipkovej-omacky-k-diviakovi":
    "/nejnovejsi-videa",
  "/najlepsie-casozbery-astronauta-pesqueta": "/nejnovejsi-videa",
  "/relikviar-svateho-maura-nalez-na-ceskom-uzemi-ma-nevycislitelnu-cenu-tajomne-pozadie-aj-detektivnu-zapletku":
    "/nejnovejsi-videa",
  "/tri-uspesne-starty-cinskych-rakiet": "/nejnovejsi-videa",
  "/co-ziskame-tepelnou-upravou-zeleniny-a-ake-zasady-dodrziavat":
    "/nejnovejsi-videa",
  "/kozmicka-raketa-z-3d-tlaciarne": "/nejnovejsi-videa",
  "/druhy-ostry-crew-dragon-sa-vratil-od-iss": "/nejnovejsi-videa",
  "/prazske-planetarium-bude-mat-vlastnu-druzicu": "/nejnovejsi-videa",
  "/karfiolove-pyre-paradna-priloha-k-masam-aj-vegetarianskym-pokrmom":
    "/nejnovejsi-videa",
  "/kacacie-prsia-na-panvici-jednoduchy-sposob-ako-vykuzlit-luxusny-obed":
    "/nejnovejsi-videa",
  "/komu-chcem-fyzicky-ublizit": "/nejnovejsi-videa",
  "/deti-vedia-najlepsie-politici-final-cut-01-mp4": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-4-1-13-11-13-19": "/nejnovejsi-videa",
  "/start-falcon-9-crew-3-11-11-3-03": "/nejnovejsi-videa",
  "/stavnata-sviatocna-kacica-alebo-hus-s-bozskou-korkou": "/nejnovejsi-videa",
  "/prvy-zazih-vsetkych-motorov-na-starship": "/nejnovejsi-videa",
  "/zaciatok-budovania-stvrtej-urovne-systemu-starlink": "/nejnovejsi-videa",
  "/bonus-100-g-parmskej-sunky-obsahuje-maximalnu-dennu-davku-soli":
    "/nejnovejsi-videa",
  "/existuju-udeniny-bez-dusitanov-vyberajte-podla-obsahu-masa-farby-a-kratkej-trvanlivosti":
    "/nejnovejsi-videa",
  "/lokse-netradicna-priloha-k-sviatocnemu-jedlu-za-par-eur-skvele-su-naslane-aj-na-sladko":
    "/nejnovejsi-videa",
  "/ako-fasisti-a-komunisti-ukradli-17-november": "/nejnovejsi-videa",
  "/sok-exp-ep-13-mys-s-uchom-mp4": "/nejnovejsi-videa",
  "/priprava-najsilnejsej-cinskej-rakety-na-tuhe-pohonne-latky":
    "/nejnovejsi-videa",
  "/chemik-albert-hofmann-a-pribeh-lsd-od-prveho-tripu-az-po-experimenty-v-ceskoslovensku":
    "/nejnovejsi-videa",
  "/rusko-zostrelilo-vlastnu-druzicu-ulomky-ohrozuju-iss": "/nejnovejsi-videa",
  "/zaklady-prvej-pomoci-pri-detskych-urazoch-popaleninach-alebo-vdychnuti-cudzieho-predmetu":
    "/nejnovejsi-videa",
  "/dvadsiaty-start-europskej-rakety-vega": "/nejnovejsi-videa",
  "/electron-vyniesol-dve-snimkovacie-druzice": "/nejnovejsi-videa",
  "/ako-sa-naucit-spravne-bozkavat": "/nejnovejsi-videa",
  "/vitajte-v-3-lockdowne": "/nejnovejsi-videa",
  "/firma-astra-space-dosiahla-obeznu-drahu": "/nejnovejsi-videa",
  "/preco-je-dobre-pocas-jedla-pit-ma-to-vplyv-aj-na-prijem-kalorii":
    "/nejnovejsi-videa",
  "/vyprazany-kel-kuceravy-v-super-cesticku-najchrumkavejsi-a-najzdravsi-popcorn":
    "/nejnovejsi-videa",
  "/rusky-uzlovy-modul-caka-na-start": "/nejnovejsi-videa",
  "/freddie-mercury-vynimocny-spevak-zmenil-pohlad-na-hudbu-aj-na-chorobu-aids":
    "/nejnovejsi-videa",
  "/cinska-radarova-druzica": "/nejnovejsi-videa",
  "/adolf-final-h264-mp4": "/nejnovejsi-videa",
  "/frankfurtska-polievka-poctivejsi-variant-na-dlhe-jesenne-vecery":
    "/nejnovejsi-videa",
  "/top-5-korona-failov": "/nejnovejsi-videa",
  "/start-prvej-misie-testujucej-planetarnu-obranu": "/nejnovejsi-videa",
  "/druzice-vcasnej-vystrahy-tundra-15l": "/nejnovejsi-videa",
  "/rakety-otrag-technicke-riesenie": "/nejnovejsi-videa",
  "/co-robit-ked-dieta-vdychne-cudzi-predmet-naucte-sa-gordonov-a-heimlichov-manever":
    "/nejnovejsi-videa",
  "/ako-pripravit-poctivy-zaklad-kazdej-omacky-podavanej-s-masom":
    "/nejnovejsi-videa",
  "/sok-experimenty-15-pili-krv-mp4": "/nejnovejsi-videa",
  "/prical-pripojenie-k-iss-26-11-16-26": "/nejnovejsi-videa",
  "/iss-sa-rozrastla-o-novy-modul": "/nejnovejsi-videa",
  "/chrenova-omacka-perfektne-zahreje-a-pritom-je-tak-jednoducha":
    "/nejnovejsi-videa",
  "/proteinove-pripravky-kedy-ich-konzumovat-a-ake-vyberat":
    "/nejnovejsi-videa",
  "/priprava-turistov-na-let-v-sojuze": "/nejnovejsi-videa",
  "/jon-zase-podvadza": "/nejnovejsi-videa",
  "/maketa-prveho-stupna-novej-rakety": "/nejnovejsi-videa",
  "/uplne-jedinecne-fotky-stanice-iss": "/nejnovejsi-videa",
  "/minutkovy-gulas-stroganoff-jednoduchy-ale-stale-luxusny":
    "/nejnovejsi-videa",
  "/detaily-o-chystanej-rakete-neutron": "/nejnovejsi-videa",
  "/karol-velky-otec-europy-vybudoval-najvacsiu-stredoveku-risu-a-urcil-smer-dalsich-dejin":
    "/nejnovejsi-videa",
  "/so-starlinky-sa-zviezol-aj-sekundarny-naklad": "/nejnovejsi-videa",
  "/polievka-z-francuzskej-sosovky-v-sychravom-pocasi-skvele-zahreje-a-zasyti":
    "/nejnovejsi-videa",
  "/kto-tu-este-rozumie-opatreniam": "/nejnovejsi-videa",
  "/hydinova-pecen-s-figami-jednoducha-rychlovka-ako-predjedlo-aj-hlavny-chod":
    "/nejnovejsi-videa",
  "/dve-nove-europske-navigacne-druzice": "/nejnovejsi-videa",
  "/len-maso-moze-masozrava-dieta-vyriesit-niektore-zdravotne-problemy":
    "/nejnovejsi-videa",
  "/vianocne-pecivo-bez-stresu-nepecene-gulicky-s-prekvapenim-hravo-zvladnu-aj-deti":
    "/nejnovejsi-videa",
  "/rover-ktory-sa-vie-dostat-z-piesocnej-pasce": "/nejnovejsi-videa",
  "/webbov-teleskop-je-natankovany": "/nejnovejsi-videa",
  "/vianocna-pecienka-podla-receptu-z-19-storocia-konecne-pecivo-pre-masozruty":
    "/nejnovejsi-videa",
  "/sojuz-ms-20-pripojenie-k-iss-8-12-14-41": "/nejnovejsi-videa",
  "/dvaja-japonski-turisti-dorazili-na-iss": "/nejnovejsi-videa",
  "/zapekane-vianocne-gulicky-z-vinnej-klobasy": "/nejnovejsi-videa",
  "/ako-sa-starat-o-pokozku-babatiek-a-ake-kozne-problemy-sa-u-nich-objavuju-najcastejsie":
    "/nejnovejsi-videa",
  "/maly-ale-sikovny-rontgenovy-teleskop-ixpe": "/nejnovejsi-videa",
  "/vianocna-zemiakovka-uzasne-vydatna-a-husta-aj-bez-muky":
    "/nejnovejsi-videa",
  "/dve-snimkovacie-druzice-blacksky": "/nejnovejsi-videa",
  "/rekordny-pocet-ludi-v-kozmickom-priestore": "/nejnovejsi-videa",
  "/je-konzumacia-ryb-kvoli-plastom-a-tazkym-kovom-nebezpecna-a-aky-uzasny-dar-mori-tak-trochu-zabudame":
    "/nejnovejsi-videa",
  "/specialna-vianocna-jednohubka-kaviar-z-kaprich-ikier-je-hotovy-raz-dva":
    "/nejnovejsi-videa",
  "/vaclav-havel-5-oktobra-1936-18-decembra-2011": "/nejnovejsi-videa",
  "/zvysuje-slava-uspech-na-tinderi": "/nejnovejsi-videa",
  "/vreckova-encyklopedia-populistov": "/nejnovejsi-videa",
  "/deti-hudba-2-6-skladba-final-cut-mp4": "/nejnovejsi-videa",
  "/ondro-ma-tazku-srdcovu-vadu-pred-20-rokmi-by-neprezil-dnes-ho-caka-tretia-operacia-ako-to-nesie-rodina":
    "/nejnovejsi-videa",
  "/desat-novych-americkych-astronautov": "/nejnovejsi-videa",
  "/ako-nasi-predkovia-pripravovali-plnenych-slimakov-na-stedry-vecer-aj-silvestra":
    "/nejnovejsi-videa",
  "/panettone-s-vanilkovo-konakovou-omackou-extra-rychly-vianocny-dezert-pre-nepecunov-ti-ktori-nechcu-nevedia-nemusia-piect":
    "/nejnovejsi-videa",
  "/test-motora-pre-raketu-sls": "/nejnovejsi-videa",
  "/rakety-pegasus-a-pegasus-h-technicke-parametre": "/nejnovejsi-videa",
  "/ziaden-kolac-z-lasky-nie-je-zly-pecenie-spaja-rodiny-aj-partnerov-a-robi-nas-lepsimi":
    "/nejnovejsi-videa",
  "/zemiakovy-salat-uplne-inak-snubi-sa-v-nom-klasika-lahkost-a-lahodny-dresing":
    "/nejnovejsi-videa",
  "/start-falcon-9-starlink-4-4-18-12-13-41": "/nejnovejsi-videa",
  "/neobvykly-start-s-druzicami-starlink": "/nejnovejsi-videa",
  "/druhy-start-rakety-falcon-pocas-15-hodin": "/nejnovejsi-videa",
  "/rekapitulacia-roku-2021": "/nejnovejsi-videa",
  "/co-je-pricinou-zachvatoviteho-prejedania-a-ako-si-s-nim-poradit":
    "/nejnovejsi-videa",
  "/josip-broz-tito-krvavy-pes-aj-zboznovany-vodca-juhoslavie-ktory-sa-vymanil-zo-sovietskej-nadvlady":
    "/nejnovejsi-videa",
  "/zazracne-triky-na-dokonale-vyprazanu-rybu-alebo-rezen-a-riad-aj-ruky-zostanu-ciste":
    "/nejnovejsi-videa",
  "/dojcenie-je-vztah-dieta-sa-prirodzene-rozhodne-pre-jeho-koniec-ked-sa-citi-uplne-bezpecne":
    "/nejnovejsi-videa",
  "/turisti-sa-vratili-z-iss": "/nejnovejsi-videa",
  "/crs-24-pripojenie-k-iss-22-12-9-40": "/nejnovejsi-videa",
  "/ste-pristatie-rakiet-falcon": "/nejnovejsi-videa",
  "/odlahcene-chlebicky-v-troch-variantoch": "/nejnovejsi-videa",
  "/iss-opustil-kozmicky-tahac": "/nejnovejsi-videa",
  "/start-ariane-5-dalekohlad-jamesa-webba-25-12-13-20": "/nejnovejsi-videa",
  "/najdolezitejsi-start-poslednych-rokov": "/nejnovejsi-videa",
  "/preco-sadlo-vitazi-zo-zdravotneho-hladiska-nad-maslom": "/nejnovejsi-videa",
  "/dve-cinske-druzice-leteli-na-dlhom-pochode-7a": "/nejnovejsi-videa",
  "/predpoved-na-rok-2022": "/nejnovejsi-videa",
  "/5-najocakavanejsich-momentov-kozmonautiky-v-roku-2022": "/nejnovejsi-videa",
  "/horny-stupen-persej-pri-premiere-zlyhal": "/nejnovejsi-videa",
  "/karel-capek-najslavnejsi-cesky-spisovatel-a-jeho-trpky-zaver-zivota-zomrel-vsak-skor-nez-pre-neho-prislo-gestapo":
    "/nejnovejsi-videa",
  "/ako-vznikli-ceske-chlebicky-a-jeden-tip-na-silvestrovsky-stol":
    "/nejnovejsi-videa",
  "/webbov-teleskop-vstupuje-do-kritickej-fazy": "/nejnovejsi-videa",
  "/silvestrovska-vyzva-poriadne-silna-a-netradicna-jednohubka":
    "/nejnovejsi-videa",
  "/keby-chceli-tarkovica-davno-by-sa-dohodli-skriniar-pojde-len-do-velkoklubu":
    "/nejnovejsi-videa",
  "/prvy-zazih-noveho-ruskeho-raketoveho-motora": "/nejnovejsi-videa",
  "/prve-kozmicke-dalekohlady": "/nejnovejsi-videa",
  "/desatminutova-novorocna-sosovica-ktoru-uvarite-aj-namol":
    "/nejnovejsi-videa",
  "/posledny-start-roku-2021-nebol-uspesny": "/nejnovejsi-videa",
  "/sosovica-ako-novorocny-symbol-hojnosti-byvala-nedostatkovym-tovarom-zhanala-sa-v-tuzexe":
    "/nejnovejsi-videa",
  "/neobycajna-panovnicka-isabela-kastilska-a-vzostup-spanielska-zjednotenie-krajiny-dobytie-granady-aj-zamorske-objavy":
    "/nejnovejsi-videa",
  "/rozkladanie-webbovho-stitu-zacalo": "/nejnovejsi-videa",
  "/ako-zo-seba-nerobit-debila": "/nejnovejsi-videa",
  "/najlepsi-vyzivovy-styl": "/nejnovejsi-videa",
  "/prva-pomoc-pri-detskych-urazoch-hlavy": "/nejnovejsi-videa",
  "/5-najdolezitejsich-udalosti-v-kozmonautike-roku-2021": "/nejnovejsi-videa",
  "/spacex-ma-zaujem-o-uplne-novu-rampu": "/nejnovejsi-videa",
  "/vari-sefkuchar-uspesna-ceskoslovenska-sou-sa-bohuzial-nezachovala-do-dneska":
    "/nejnovejsi-videa",
  "/spacex-rozhybala-obrie-ramena": "/nejnovejsi-videa",
  "/100-najlepsich-fotiek-od-nasa-z-roku-2021": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-4-5-6-1-22-49": "/nejnovejsi-videa",
  "/kurca-na-paprike-podla-koko-bez-jedinej-stipky-muky": "/nejnovejsi-videa",
  "/prvy-kozmicky-start-roku-2022": "/nejnovejsi-videa",
  "/vznik-rakiet-falcon-1": "/nejnovejsi-videa",
  "/hlavne-rozkladanie-webbovho-teleskopu-skoncilo": "/nejnovejsi-videa",
  "/preco-tenistom-tak-drbe": "/nejnovejsi-videa",
  "/zavada-celneho-skla-sposobila-kurioznu-situaciu-kapitan-bol-vysaty-z-kabiny-birmingham-malaga-1990":
    "/nejnovejsi-videa",
  "/ako-funguje-zazrak-na-chudnutie": "/nejnovejsi-videa",
  "/psychicke-problemy-po-porode-kedy-premyslat-o-hospitalizacii":
    "/nejnovejsi-videa",
  "/skusky-techniky-pre-dopravu-vzoriek-z-marsu": "/nejnovejsi-videa",
  "/dalsie-skusky-obrych-ramien-mechazilla": "/nejnovejsi-videa",
  "/policia-v-cmolikovom-hoteli-mupi-night-live-1-highlights":
    "/nejnovejsi-videa",
  "/dokonale-zlate-a-chrumkave-krutony": "/nejnovejsi-videa",
  "/povianocna-dieta-podla-priekopnickeho-lekara-filipa": "/nejnovejsi-videa",
  "/raketa-ktora-vrati-ludi-k-mesiacu": "/nejnovejsi-videa",
  "/johnny-cash-hviezda-americkej-country-muziky-a-jeho-slavny-koncert-v-prisne-strazenej-vaznici-folsom":
    "/nejnovejsi-videa",
  "/pecena-karfiolova-ryza-ako-originalna-priloha": "/nejnovejsi-videa",
  "/nova-ceska-druzica-je-na-obeznej-drahe": "/nejnovejsi-videa",
  "/radosti-aj-narocnost-prechodneho-pestunstva-tety-a-strykovia-vybavuju-deti-do-zivota-laskou-a-vyplnaju-im-biele-miesto":
    "/nejnovejsi-videa",
  "/konstrukcia-falconu-1": "/nejnovejsi-videa",
  "/bleskova-karfiolova-polievka-podla-koko": "/nejnovejsi-videa",
  "/launcherone-vyniesol-7-malych-druzic": "/nejnovejsi-videa",
  "/testy-obrovskej-rakety-sls-v-montaznej-hale": "/nejnovejsi-videa",
  "/za-koho-bojuje-fico": "/nejnovejsi-videa",
  "/skodi-kava-zdraviu-alebo-je-to-superpotravina": "/nejnovejsi-videa",
  "/najvacsia-sopecna-erupcia-21-storocia-ocami-druzic": "/nejnovejsi-videa",
  "/otto-von-bismarck-ku-zjednotenie-nemecka-krvou-a-zelezom":
    "/nejnovejsi-videa",
  "/taliani-by-sa-o-nu-pobili-tradicna-poctiva-bolonska-omacka":
    "/nejnovejsi-videa",
  "/rozcvicka-cinskeho-manipulatora": "/nejnovejsi-videa",
  "/kde-sa-vzalo-ruske-vajcie-v-rusku-o-nom-nikdy-nepoculi":
    "/nejnovejsi-videa",
  "/falcon-so-starlinky-ponukol-uzasny-zazitok": "/nejnovejsi-videa",
  "/cudle-cuketove-cestoviny-pre-zdrave-chudnutie": "/nejnovejsi-videa",
  "/prvy-tohtorocny-vystup-do-volneho-kozmickeho-priestoru":
    "/nejnovejsi-videa",
  "/mikyr-zatknuty-v-priamom-prenose-mupi-night-live-2-highlights":
    "/nejnovejsi-videa",
  "/ako-resuscitovat-dieta-v-bezvedomi": "/nejnovejsi-videa",
  "/prevadzka-rakiet-falcon-1": "/nejnovejsi-videa",
  "/premiera-novej-konfiguracie-rakety-atlas-v-bola-uspesna":
    "/nejnovejsi-videa",
  "/starocesky-macicak-alebo-zemiakova-lepenica": "/nejnovejsi-videa",
  "/ako-dopadneme-na-olympiade": "/nejnovejsi-videa",
  "/pu-ep58-olympiada-online-mp4-2": "/nejnovejsi-videa",
  "/r2-mov": "/nejnovejsi-videa",
  "/r2-mov-2": "/nejnovejsi-videa",
  "/albrecht-z-valdstejna-ukladna-vrazda-vyhlaseneho-vojvodcu-mocneho-politika-a-sikovneho-obchodnika":
    "/nejnovejsi-videa",
  "/ktore-potraviny-pred-spracovanim-umyvat-a-ktore-nie-niekedy-umyvanie-napacha-viac-hygienickej-skody-ako-uzitku":
    "/nejnovejsi-videa",
  "/testy-navigacie-pre-sondu-dragonfly": "/nejnovejsi-videa",
  "/silny-zimny-bravcovy-vyvar-zaklad-do-gulasu-aj-omacok": "/nejnovejsi-videa",
  "/mikyr-internetova-put-uzasom-official-video": "/nejnovejsi-videa",
  "/ako-zhodit-kilo-tyzdenne-prva-moderna-dieta-od-slavneho-ceskeho-lekara":
    "/nejnovejsi-videa",
  "/druhy-cinsky-tohtorocny-start": "/nejnovejsi-videa",
  "/detske-popaleniny-a-obareniny-tazke-zranenie-ma-len-jedno-riesenie":
    "/nejnovejsi-videa",
  "/stiepne-jadrove-reaktory-v-kozmonautike": "/nejnovejsi-videa",
  "/testovacie-stupne-novej-europskej-rakety": "/nejnovejsi-videa",
  "/priprava-novej-americkej-meteodruzice": "/nejnovejsi-videa",
  "/predstavte-si-cesko-slovensku-vojnu": "/nejnovejsi-videa",
  "/ako-pozitivne-ovplyvnit-nas-zrak-tym-co-jeme": "/nejnovejsi-videa",
  "/dragon-2-sa-vratil-od-iss": "/nejnovejsi-videa",
  "/kuzelny-vecerny-start-falconu-9": "/nejnovejsi-videa",
  "/odklad-start-falcon-9-csg-2-29-1-0-11": "/nejnovejsi-videa",
  "/konstantin-velky-prvy-krestansky-cisar-rimskeho-imperia":
    "/nejnovejsi-videa",
  "/trik-na-zapeceny-besamel-bleskovka-bez-miesania": "/nejnovejsi-videa",
  "/start-falcon-9-nrol-87-2-2-21-27": "/nejnovejsi-videa",
  "/utajeny-naklad-letel-na-falcone-9": "/nejnovejsi-videa",
  "/kozmicke-stiepne-reaktory-z-usa": "/nejnovejsi-videa",
  "/treti-start-falconu-9-pocas-troch-dni": "/nejnovejsi-videa",
  "/gustav-husak-z-komunistickeho-vazna-komunistickym-prezidentom":
    "/nejnovejsi-videa",
  "/toto-ste-vidiet-nechceli-ale-museli": "/nejnovejsi-videa",
  "/o5bazeny-mov": "/nejnovejsi-videa",
  "/valentinska-vecera-bez-varenia-carpaccio-v-dvoch-variantoch":
    "/nejnovejsi-videa",
  "/preco-ti-rusi-stale-dopuju": "/nejnovejsi-videa",
  "/so-sipkovou-naucte-sa-ceskej-a-slovenskej-polovnickej-kuchyne":
    "/nejnovejsi-videa",
  "/ako-si-zrychlit-metabolizmus-chyba-nam-normalny-pohyb-kvalitny-spanok-aj-prirodzena-strava":
    "/nejnovejsi-videa",
  "/nova-ruska-prieskumna-druzica": "/nejnovejsi-videa",
  "/vajce-marinovane-v-sojovej-omacke": "/nejnovejsi-videa",
  "/testovaci-zazih-motora-rs-25": "/nejnovejsi-videa",
  "/pecene-avokado-s-kimchi-a-marinovanym-zltkom-desiata-hipsterskych-sampionov":
    "/nejnovejsi-videa",
  "/erwin-rommel-majster-bleskovych-ofenziv-a-milacik-nemecka-hitler-ho-donutil-k-najkrutejsiemu-zivotnemu-rozhodnutiu":
    "/nejnovejsi-videa",
  "/prve-fotky-z-webbovho-teleskopu": "/nejnovejsi-videa",
  "/dve-vedecky-podlozene-praktiky-ktore-spomaluju-starnutie":
    "/nejnovejsi-videa",
  "/navrat-ku-korenom-test-holky-z-ikey-2-mikyrov-prehlad-5-diel":
    "/nejnovejsi-videa",
  "/vigil-predsunuta-hliadka-sledujuca-slnko": "/nejnovejsi-videa",
  "/netradicne-chrumky-na-vecerne-maskrtenie-chrumkave-pecene-drzky":
    "/nejnovejsi-videa",
  "/prvy-tohtorocny-prilet-k-iss": "/nejnovejsi-videa",
  "/ema-destinnova-cesku-divu-a-vlastenku-ktora-ocarila-svet-doma-obvinili-zo-spionaze":
    "/nejnovejsi-videa",
  "/zabijackova-polievka-bez-zabijacky-staci-kvalitny-vyvar-a-jaternica":
    "/nejnovejsi-videa",
  "/5-skladieb-ktore-vystihuju-rok-2022": "/nejnovejsi-videa",
  "/ako-dobre-a-chutne-uvarit-ryzu-a-servirovat-ju-ako-tortu":
    "/nejnovejsi-videa",
  "/start-misie-snapshot": "/nejnovejsi-videa",
  "/rameno-magnetometra-europsko-cinskej-sondy": "/nejnovejsi-videa",
  "/start-antares-cygnus-ng-17-19-2-18-40": "/nejnovejsi-videa",
  "/pohlady-na-zem-z-cinskej-kozmickej-stanice": "/nejnovejsi-videa",
  "/hraskove-pyre-je-nova-zemiakova-kasa-privolajte-jar-do-kuchyne":
    "/nejnovejsi-videa",
  "/preco-je-kukurica-kontroverznou-potravinou-a-v-akej-forme-sa-jej-vyhnut":
    "/nejnovejsi-videa",
  "/druzice-starlink-leteli-na-vyssiu-drahu": "/nejnovejsi-videa",
  "/poctiva-zimna-kapustnica-s-udenym-masom": "/nejnovejsi-videa",
  "/preco-je-vojna-na-ukrajine": "/nejnovejsi-videa",
  "/najviac-rozvodov-mp4": "/nejnovejsi-videa",
  "/rozvody-mp4": "/nejnovejsi-videa",
  "/prevadzka-jadroveho-reaktora-pri-misii-snapshot": "/nejnovejsi-videa",
  "/petr-ludwig-opat-zasahuje-mupi-night-live-highlights-6":
    "/nejnovejsi-videa",
  "/start-falcon-9-starlink-4-11-25-2-18-12": "/nejnovejsi-videa",
  "/tipy-ako-pripravit-perfektne-kuracie-spizy": "/nejnovejsi-videa",
  "/rva-snimka-z-teleskopu-ixpe": "/nejnovejsi-videa",
  "/uho-je-spat-na-skolsku-jedalen-zabudnite-tato-omacka-je-poctiva-a-plna-chuti":
    "/nejnovejsi-videa",
  "/pravda-o-osude-ludwajza-mupi-night-live-highlights-5": "/nejnovejsi-videa",
  "/mangova-salsa-tropy-a-exotika-priamo-na-vasom-stole": "/nejnovejsi-videa",
  "/ako-upravit-jedalnicek-v-tehotenstve-co-ne-konzumovat-aby-sa-plod-vyvijal-zdravo":
    "/nejnovejsi-videa",
  "/vpad-ruska-na-ukrajinu-ovplyvni-aj-kozmonautiku": "/nejnovejsi-videa",
  "/miluje-boh-slovensko": "/nejnovejsi-videa",
  "/v6-zelenskyy-mov": "/nejnovejsi-videa",
  "/premierovy-start-z-novej-rampy": "/nejnovejsi-videa",
  "/start-novej-americkej-meteodruzice": "/nejnovejsi-videa",
  "/skvely-tip-do-kuchyne-najnadychanejsia-zemiakova-kasa-bez-hrudiek":
    "/nejnovejsi-videa",
  "/start-falcon-9-starlink-4-9-3-3-15-25": "/nejnovejsi-videa",
  "/falcon-9-vyniesol-47-novych-druzic-starlink": "/nejnovejsi-videa",
  "/pan-bleskov-nikola-tesla-ako-pomohol-genialny-vynalezca-a-podivinsky-vedec-zmenit-20-storocie":
    "/nejnovejsi-videa",
  "/sovietske-kozmicke-rtg-zdroje-energie": "/nejnovejsi-videa",
  "/omacka-k-svedskym-masovym-gulickam": "/nejnovejsi-videa",
  "/prva-porcia-cinskych-komunikacnych-druzic-jin-che": "/nejnovejsi-videa",
  "/skusky-pristroja-pre-mesiac": "/nejnovejsi-videa",
  "/prichadza-obdobie-detoxu-preco-sa-nanho-pokojne-vykaslat":
    "/nejnovejsi-videa",
  "/ako-spoznat-zrele-granatove-jablko-ako-sa-donho-dobyt-a-potom-si-uzit-jeho-poklady":
    "/nejnovejsi-videa",
  "/slovaci-odbornici-na-utecencov": "/nejnovejsi-videa",
  "/obrie-panely-sondy-psyche": "/nejnovejsi-videa",
  "/strava-vo-vojnovej-dobe-cim-sa-da-nahradit-maso-obilie-aj-pivo":
    "/nejnovejsi-videa",
  "/v10-ue-vlajka-mov": "/nejnovejsi-videa",
  "/desiaty-tohtorocny-start-firmy-spacex": "/nejnovejsi-videa",
  "/jar-prichadza-na-tanier-opalene-jarne-cibulky-s-bryndzou":
    "/nejnovejsi-videa",
  "/blizi-sa-prvy-vyvoz-dokoncenej-obrovskej-rakety-sls": "/nejnovejsi-videa",
  "/nadeje-vychodu-i-zapadu-ako-michail-gorbacov-zmenil-politicku-mapu-sveta-a-ziskal-za-to-nobelovu-cenu-mieru":
    "/nejnovejsi-videa",
  "/sovietsky-stiepny-reaktor-romaska": "/nejnovejsi-videa",
  "/odvoz-pre-raketu-sls-je-pripraveny": "/nejnovejsi-videa",
  "/tip-ako-prepiect-panenku-presne-podla-vasich-predstav": "/nejnovejsi-videa",
  "/od-kedy-mozu-deti-celozrnne-pecivo-otruby-su-ako-crepy-mozu-poskodit-jemne-crevo":
    "/nejnovejsi-videa",
  "/dobrodruh-a-neortodoxny-spisovatel-jaroslav-hasek-ako-sa-zrodil-vojak-svejk-hrdina-najprekladanejsej-knihy":
    "/nejnovejsi-videa",
  "/europska-sonda-sa-blizi-k-slnku": "/nejnovejsi-videa",
  "/ako-ovplyvni-stanicu-iss-ruska-invazia": "/nejnovejsi-videa",
  "/slovensko-raj-ruskych-spionov": "/nejnovejsi-videa",
  "/kuracie-prsia-ziadna-sucha-nuda-ako-ich-ochutit-a-cim-naplnit":
    "/nejnovejsi-videa",
  "/priprava-prvej-tohtorocnej-pilotovanej-orbitalnej-misie":
    "/nejnovejsi-videa",
  "/011-adam-bardy-mp4": "/nejnovejsi-videa",
  "/syrove-pasticky-ako-skvele-pohostenie-alebo-predjedlo": "/nejnovejsi-videa",
  "/ako-vznikla-prelomova-dieta-pre-tych-ktori-cely-den-v-praci-sedia-a-tiez-vytvaraju-hodnoty":
    "/nejnovejsi-videa",
  "/sovietsky-stiepny-reaktor-buk": "/nejnovejsi-videa",
  "/jar-sa-hlasi-paradajkova-polievka-s-medvedim-cesnakom": "/nejnovejsi-videa",
  "/najlepsie-fotky-z-vyvozu-rakety-sls-na-rampe": "/nejnovejsi-videa",
  "/na-iss-dorazila-kozmicka-lod-sojuz-ms-21": "/nejnovejsi-videa",
  "/na-co-ma-vplyv-pitie-vody-s-citronom-moze-podporit-aj-chudnutie":
    "/nejnovejsi-videa",
  "/jaro-vzdava-hold-vladimirovi-putinovi": "/nejnovejsi-videa",
  "/ako-si-doma-urobit-rychlu-omacku-k-vietnamskym-zavitkom":
    "/nejnovejsi-videa",
  "/misia-exomars-tento-rok-nepoleti-kvoli-ruskej-invazii": "/nejnovejsi-videa",
  "/spacex-ziskava-zakazky-zo-sojuzov": "/nejnovejsi-videa",
  "/viete-ze-benzin-a-nafta-mp4": "/nejnovejsi-videa",
  "/viete-ze-benzin-a-nafta-mp4-2": "/nejnovejsi-videa",
  "/viete-ze-benzin-a-nafta-ver003-mp4": "/nejnovejsi-videa",
  "/r9-tanky-mov": "/nejnovejsi-videa",
  "/r9-tanky-mov-2": "/nejnovejsi-videa",
  "/r9tanky-mov": "/nejnovejsi-videa",
  "/r9tanky-mov-2": "/nejnovejsi-videa",
  "/testy-pozemneho-vybavenia-pre-miniraketoplan": "/nejnovejsi-videa",
  "/ako-sa-stal-syn-kovaca-osobnym-kucharom-vlasta-buriana":
    "/nejnovejsi-videa",
  "/dan-andreas-mogensen-sa-docka-dlhodobej-kozmickej-misie":
    "/nejnovejsi-videa",
  "/lekcia-marketingu-s-martinkom-mupi-night-live-highlights-7":
    "/nejnovejsi-videa",
  "/sovietske-reaktory-buk-na-druziciach-us-a": "/nejnovejsi-videa",
  "/gremolata-doda-smrnc-kazdemu-vasmu-jedlu": "/nejnovejsi-videa",
  "/jan-amos-komensky-unikatne-myslienky-a-spisy-jedneho-z-najvacsich-cechov-ktory-nestratil-vieru-ani-cez-tazke-rany-osudu":
    "/nejnovejsi-videa",
  "/co-so-zvysnym-kvaskom-upecte-z-neho-mramorovu-babovku": "/nejnovejsi-videa",
  "/soja-ma-vyrazne-zdravotne-benefity-na-co-si-ale-dat-pozor":
    "/nejnovejsi-videa",
  "/l11let72-mov": "/nejnovejsi-videa",
  "/ma-to-krasnu-farbu-a-chrumka-to-co-je-to-najlepsie-pecene-zemiaky":
    "/nejnovejsi-videa",
  "/europska-sonda-zaobstarala-najpodrobnejsiu-snimku-slnka":
    "/nejnovejsi-videa",
  "/skoncil-najdlhsi-pobyt-na-stanici-iss": "/nejnovejsi-videa",
  "/k13-padle-celebrity-mov": "/nejnovejsi-videa",
  "/k13celebrity-mov": "/nejnovejsi-videa",
  "/premiera-novej-verzie-cinskej-rakety": "/nejnovejsi-videa",
  "/k13celebrity-mov-2": "/nejnovejsi-videa",
  "/k13celebrity-mov-3": "/nejnovejsi-videa",
  "/d8-co-nerobit-s-autom-mov": "/nejnovejsi-videa",
  "/jarne-zemiakove-placky-s-kelom-a-cmarom": "/nejnovejsi-videa",
  "/prevadzka-sovietskych-druzic-us-a-s-jadrovym-reaktorom":
    "/nejnovejsi-videa",
  "/ryba-s-bylinkovou-krustou-idealna-rychlovka-na-jar-a-leto":
    "/nejnovejsi-videa",
  "/ceska-republika-ma-dalsiu-druzicu": "/nejnovejsi-videa",
  "/raketa-electron-vyniesla-dve-snimkovacie-druzice": "/nejnovejsi-videa",
  "/vsade-sama-zdrava-strava-ake-su-ale-rizika-pri-nadmernej-konzumacii-ovocia-a-zeleniny":
    "/nejnovejsi-videa",
  "/sest-novych-kozmickych-turistov": "/nejnovejsi-videa",
  "/rostovsky-masiar-cikatilo-asi-najhorsi-pripad-ktory-budete-pocut":
    "/nejnovejsi-videa",
  "/najvacsia-tragedia-na-uzemi-kanady-americki-vojaci-sa-vracali-z-misie-blizki-sa-ich-ale-nedockali-kahira-fort-campbell-1985":
    "/nejnovejsi-videa",
  "/slavnostny-spenatovy-superkrem": "/nejnovejsi-videa",
  "/nadupana-esencia-z-medvedieho-cesnaku-pozdvihne-rizoto-aj-cestoviny":
    "/nejnovejsi-videa",
  "/planovany-priebeh-velkej-europskej-misie-juice": "/nejnovejsi-videa",
  "/najstarsi-recept-na-velkonocny-mazanec-a-jeho-tajne-prisady":
    "/nejnovejsi-videa",
  "/amazon-vybral-nosice-pre-svoju-satelitnu-siet": "/nejnovejsi-videa",
  "/priprava-rakety-sls-pre-pilotovanu-misiu": "/nejnovejsi-videa",
  "/special1-mp4": "/nejnovejsi-videa",
  "/cesky-alchymista-stvoril-epicky-velkonocny-recept-zrejme-na-drogach":
    "/nejnovejsi-videa",
  "/lahka-vajecna-tlacenka-bez-majonezy": "/nejnovejsi-videa",
  "/vylepsena-druzice-us-a-a-jadrove-reaktory-topol": "/nejnovejsi-videa",
  "/crew-dragon-priviezol-na-iss-komercnych-astronautov": "/nejnovejsi-videa",
  "/paul-mccartney-ako-ustal-karieru-po-rozpade-beatles-a-stal-sa-najuspesnejsim-skladatelom-vsetkych-cias":
    "/nejnovejsi-videa",
  "/novemu-exemplarovi-super-heavy-zacali-skusky": "/nejnovejsi-videa",
  "/rizika-obezity-pred-otehotnenim-a-v-tehotenstve": "/nejnovejsi-videa",
  "/co-so-zvysnymi-bielkami-upecte-uzasne-chrumkave-snehove-pusinky":
    "/nejnovejsi-videa",
  "/testy-rakety-sls-stretli-komplikacie": "/nejnovejsi-videa",
  "/psychopat-z-milwaukee-si-uchovaval-kostry-a-organy-muzov-ktore-znasilnoval-nic-pre-citlive-povahy":
    "/nejnovejsi-videa",
  "/r12-merch-mov-2": "/nejnovejsi-videa",
  "/obri-rezen-na-spanielsky-sposob-plneny-sunkou-a-syrom": "/nejnovejsi-videa",
  "/3d-tlacena-raketa-pokracuje-v-testoch": "/nejnovejsi-videa",
  "/stavba-floridskej-rampy-pre-super-heavy-a-starship": "/nejnovejsi-videa",
  "/najchladnejsi-pristroj-webbovho-teleskopu-dosiahol-pracovnu-teplotu":
    "/nejnovejsi-videa",
  "/k12-aids-mov": "/nejnovejsi-videa",
  "/dezert-ktory-si-hned-zamilujete-sabayon-s-pecenymi-jahodami-a-lesnym-ovocim":
    "/nejnovejsi-videa",
  "/final20-mp4": "/nejnovejsi-videa",
  "/hamizna-berta-hostom-servirovala-odpadky-sama-si-zila-ako-kralovna-za-okradanie-sovietskeho-zvazu-bola-popravena":
    "/nejnovejsi-videa",
  "/jahnacie-kolienka-na-cervenom-vine": "/nejnovejsi-videa",
  "/navrhy-lietadiel-pre-mars": "/nejnovejsi-videa",
  "/navrat-rekordnej-cinskej-misie": "/nejnovejsi-videa",
  "/diktator-pol-pot-zrusil-meranie-casu-aj-peniaze-zvrateny-experiment-o-beztriednej-spolocnosti-skoncil-krutou-genocidou":
    "/nejnovejsi-videa",
  "/start-falcon-9-nrol-85-17-4-15-13": "/nejnovejsi-videa",
  "/falcon-9-vyniesol-dve-prieskumne-druzice": "/nejnovejsi-videa",
  "/raketa-sls-sa-musi-vratit-do-montaznej-haly": "/nejnovejsi-videa",
  "/priprava-europskeho-manipulatora-na-iss-zacala": "/nejnovejsi-videa",
  "/odkial-priletel-spanielsky-vtacik-recept-podla-rettigovej-je-este-divokejsi-ako-verzia-s-uhorkou-a-vajcom":
    "/nejnovejsi-videa",
  "/k14-plavky-mov": "/nejnovejsi-videa",
  "/na-floridu-dorazila-posadka-lode-crew-dragon": "/nejnovejsi-videa",
  "/rover-perseverance-natocil-ciastocne-zatmenie-slnka": "/nejnovejsi-videa",
  "/k14-plavky-mov-2": "/nejnovejsi-videa",
  "/ruskababka-mp4": "/nejnovejsi-videa",
  "/ruskababka-mp4-2": "/nejnovejsi-videa",
  "/zodiac-najtajomnejsi-seriovy-vrah-nebol-doteraz-chyteny":
    "/nejnovejsi-videa",
  "/ed-gein-1-3-ake-detstvo-mal-masiar-z-plainfieldu-bol-fixovany-na-fanaticku-matku-kvoli-nej-zrejme-zabil-svojho-brata":
    "/nejnovejsi-videa",
  "/nova-porcia-druzic-starlink": "/nejnovejsi-videa",
  "/cisarovna-sissi-krasna-a-posadnuta-svojim-vzhladom-nesputana-aj-zboznovana-alzbeta-bavorska-tragicky-zomrela-rukou-talianskeho-anarchistu":
    "/nejnovejsi-videa",
  "/posobive-pohlady-na-stanicu-iss": "/nejnovejsi-videa",
  "/vedecke-odporucania-pre-nasa-prioritu-ma-mat-uran-a-enceladus":
    "/nejnovejsi-videa",
  "/tajuplny-zivot-williama-shakespeara-bol-homosexual-a-bol-skutocnym-autorom-vsetkych-genialnych-sonetov-a-hier":
    "/nejnovejsi-videa",
  "/017-victoria-beckham-mp4": "/nejnovejsi-videa",
  "/017-victoria-beckham-mp4-2": "/nejnovejsi-videa",
  "/turisti-sa-z-iss-vratili-o-niekolko-dni-neskor": "/nejnovejsi-videa",
  "/vojna-o-borsc-pochadza-z-ruska-alebo-ukrajiny-vsetko-je-trochu-inak":
    "/nejnovejsi-videa",
  "/017-victoria-beckham001-mp4": "/nejnovejsi-videa",
  "/crew-dragon-priviezol-na-iss-novu-posadku": "/nejnovejsi-videa",
  "/konstrukcia-marsovskeho-vrtulnika-ingenuity": "/nejnovejsi-videa",
  "/falcon-9-opat-posunul-svoj-rekord": "/nejnovejsi-videa",
  "/udeniny-tavene-syry-rastlinne-nahradky-masa-ktorym-proteinom-sa-oblukom-vyhnut":
    "/nejnovejsi-videa",
  "/pecene-morkove-kosti-chutova-bomba-pre-skutocnych-fajnsmekrov":
    "/nejnovejsi-videa",
  "/priprava-europskeho-manipulatora-na-iss-pokracuje": "/nejnovejsi-videa",
  "/co-sa-jedlo-v-roku-1948-nudzove-pridely-potravin-vyhnali-ceny-na-ciernom-trhu-boli-drahsie-ako-dnes":
    "/nejnovejsi-videa",
  "/roastbeef-z-pupka-usetrite-cas-aj-peniaze": "/nejnovejsi-videa",
  "/kozmicka-lod-starliner-uz-sedi-na-rakete-atlas-v": "/nejnovejsi-videa",
  "/fair-play-e54-gaspar-mp4": "/nejnovejsi-videa",
  "/casy-bondrov-a-zigov-sa-skoncili-necakajme-ze-slafkovsky-vystriela-dalsi-kov":
    "/nejnovejsi-videa",
  "/elektronika-marsovskeho-vrtulnika-ingenuity": "/nejnovejsi-videa",
  "/crew-dragon-priviezol-na-zem-styroch-astronautov": "/nejnovejsi-videa",
  "/ed-gein-2-3-vykopal-hrob-vlastnej-matky-a-zabil-dve-zeny-ktore-mu-ju-pripominali":
    "/nejnovejsi-videa",
  "/ed-gein-3-3-dopad-psychologicky-rozbor-a-dalsie-zaujimavosti":
    "/nejnovejsi-videa",
  "/019-emilia-vasaryova-1-mp4": "/nejnovejsi-videa",
  "/019-emilia-vasaryova-2-mp4": "/nejnovejsi-videa",
  "/helena-houdova-ezoknezna-posvatneho-podvodu": "/nejnovejsi-videa",
  "/fairplay-e55-pukalovic-mp4-2": "/nejnovejsi-videa",
  "/mame-tim-bojovnikov-ti-talentovanejsi-zatial-spia-a-vetraju-dresy":
    "/nejnovejsi-videa",
  "/zachyt-prveho-stupna-rakety-electron-sa-takmer-podaril":
    "/nejnovejsi-videa",
  "/ako-si-uzit-cesku-a-slovensku-sparglu-v-jednoduchom-recepte-sezona-prave-startuje":
    "/nejnovejsi-videa",
  "/tajomstvo-zidovskej-kuchyne-ako-sa-pripravuje-koser-svieckova-zaberie-to-cele-dni":
    "/nejnovejsi-videa",
  "/stavba-velkeho-zasobnika-vodika-pre-raketu-sls": "/nejnovejsi-videa",
  "/dramaticky-osud-bedricha-smetany-prisiel-o-tri-deti-aj-manzelku-vrcholne-dielo-napisal-po-ohluchnuti-na-sklonku-zivota-trpel-zachvatmi-zurivosti-a-halucinaciami":
    "/nejnovejsi-videa",
  "/grilovane-sampinony-portobello-s-jednoduchou-naplnou": "/nejnovejsi-videa",
  "/sonda-psyche-v-cistej-miestnosti": "/nejnovejsi-videa",
  "/ako-maly-velky-muz-david-ben-gurion-vydupal-zo-zeme-stat-izrael-zidia-sa-dockali-po-2-000-rokoch-v-utlaku-vlastneho-statu":
    "/nejnovejsi-videa",
  "/cinska-raketa-hyperbola-1-opat-zlyhala": "/nejnovejsi-videa",
  "/dva-starty-falconov-9-s-druzicami-starlink": "/nejnovejsi-videa",
  "/vyrobte-si-domaci-smrekovy-olej-zdrave-a-vonave-ochucovadlo":
    "/nejnovejsi-videa",
  "/mallsk-1-mp4": "/nejnovejsi-videa",
  "/zatial-najsilnejsie-marsotrasenie": "/nejnovejsi-videa",
  "/ako-vyuzit-starsi-chlieb-sup-s-nim-do-chlebovej-polievky-s-hribikmi":
    "/nejnovejsi-videa",
  "/naozaj-su-nadrzane-zeny-vo-vasom-okoli": "/nejnovejsi-videa",
  "/ako-vyzera-putinova-vikendova-chatka-taky-maly-palac-versailles-za-30-miliard-udrzba-stoji-miliardu-rocne":
    "/nejnovejsi-videa",
  "/hostina-v-romskej-osade-nesmie-chybat-gulasok-a-poctivy-domaci-chlieb":
    "/nejnovejsi-videa",
  "/skladanie-prvej-rakety-vega-c-pokracuje": "/nejnovejsi-videa",
  "/ako-cesky-miesany-aperitiv-felicia-slavil-uspech-az-vo-francuzsku":
    "/nejnovejsi-videa",
  "/start-falcon-9-starlink-4-18-18-5-12-59": "/nejnovejsi-videa",
  "/neruseny-pohlad-na-navrat-prveho-stupna-falconu-9": "/nejnovejsi-videa",
  "/fermentovany-tvaroh-robili-uz-nase-babicky-ziadna-dobra-natierka-sa-bez-neho-nezaobide":
    "/nejnovejsi-videa",
  "/europske-inkubatory-kubik-na-iss": "/nejnovejsi-videa",
  "/lod-starliner-od-boeingu-vyrazila-na-opravnu-testovaciu-misiu":
    "/nejnovejsi-videa",
  "/albert-fish-1-3-jeden-z-najzvratenejsich-seriovych-vrahov-trpel-niekolkymi-uchylkami-vratane-koprofilie-a-kanibalizmu":
    "/nejnovejsi-videa",
  "/albert-fish-2-3-vylakal-malu-grace-na-oslavu-zabil-ju-zjedol-a-jej-rodicom-o-tom-napisal-list":
    "/nejnovejsi-videa",
  "/prve-pripojenie-lode-starliner-k-stanici-iss": "/nejnovejsi-videa",
  "/europska-sonda-priniesla-svoje-prve-blizke-pohlady-na-slnko":
    "/nejnovejsi-videa",
  "/pokracuje-stavba-rampy-na-ruskom-kozmodrome-vostocnyj": "/nejnovejsi-videa",
  "/jesse-owens-fenomenalny-hrdina-berlinskej-olympiady-ziskal-styri-zlate-medaily-jeho-kariera-ale-vzala-trpky-koniec":
    "/nejnovejsi-videa",
  "/robotnicky-rezen-proletarska-pochutka-z-imperialistickeho-lancmitu":
    "/nejnovejsi-videa",
  "/pripravy-na-odlet-lode-starliner-od-iss": "/nejnovejsi-videa",
  "/ceska-republika-ma-svoju-11-druzicu": "/nejnovejsi-videa",
  "/ako-grilovat-rozne-druhy-syrov-dostante-z-nich-to-najlepsie":
    "/nejnovejsi-videa",
  "/r18-hardbass-mov": "/nejnovejsi-videa",
  "/r18-hardbass-mov-2": "/nejnovejsi-videa",
  "/ako-dokazal-vsehoschopny-pistolnik-ronald-reagan-vyhrat-studenu-vojnu":
    "/nejnovejsi-videa",
  "/lod-starliner-uspesne-dokoncila-bezpilotny-test": "/nejnovejsi-videa",
  "/europske-chladnicky-mraznicky-melfi-na-iss": "/nejnovejsi-videa",
  "/prave-som-zastrelila-andyho-warhola-homosexualny-umelec-so-slovenskymi-korenmi-zazrakom-prezil-atentat-feministickej-fanaticky":
    "/nejnovejsi-videa",
  "/nacvik-vystupu-do-volneho-priestoru-v-bazene": "/nejnovejsi-videa",
  "/konstrukcia-a-prevadzka-europskych-mrazniciek-melfi-na-iss":
    "/nejnovejsi-videa",
  "/lod-progress-ms-20-doviezla-na-iss-dve-a-pol-tony-nakladu":
    "/nejnovejsi-videa",
  "/s2e6-david-si-uziva-krasu-grecka-vylet-lodou-vyborny-obed-a-expresso-k-tomu":
    "/nejnovejsi-videa",
  "/bleskove-chilli-con-carne-idealna-vecera-na-vsedny-den":
    "/nejnovejsi-videa",
  "/cinska-kozmicka-stanica-bude-nepretrzite-obyvana": "/nejnovejsi-videa",
  "/dalsich-sest-ludi-za-hranicou-kozmickeho-priestoru": "/nejnovejsi-videa",
  "/obria-raketa-sls-sa-vratila-na-startovu-rampu": "/nejnovejsi-videa",
  "/o-krok-blizsie-k-prieskumnikovi-ladoveho-mesiaca": "/nejnovejsi-videa",
  "/macerovane-jahody-s-mascarpone-kremom-osviezujuci-a-jednoduchy-dezert":
    "/nejnovejsi-videa",
  "/maximilien-robespierre-povodne-humanista-nakoniec-rozputal-taku-hrozovladu-ktoru-sam-nemohol-prezit":
    "/nejnovejsi-videa",
  "/s2-e4-exkjuzik-suvenir-david-na-korfu-zhana-darceky-pre-svoju-rodinu":
    "/nejnovejsi-videa",
  "/cesta-k-rusko-nemeckemu-observatoriu-spektr-rg": "/nejnovejsi-videa",
  "/023-martin-huba-2-mp4": "/nejnovejsi-videa",
  "/pripravy-na-prvy-orbitalny-let-super-heavy-starship-pokracuju":
    "/nejnovejsi-videa",
  "/prva-raketa-terran-1-dorazila-na-kozmodrom": "/nejnovejsi-videa",
  "/paradne-pohostenie-nielen-na-party-plnene-hambulky-sandwiche-podla-koko":
    "/nejnovejsi-videa",
  "/masove-platky-rema-podivny-vynalez-socialistickej-gastronomie":
    "/nejnovejsi-videa",
  "/zrkadlo-teleskopu-jamesa-webba-zasiahol-mikrometeoroid":
    "/nejnovejsi-videa",
  "/dlho-ocakavane-rozhodnutie-uradu-faa-je-tu": "/nejnovejsi-videa",
  "/elektromobily-pre-astronautov-mieriacich-k-mesiacu": "/nejnovejsi-videa",
  "/alfred-hitchcock-majster-napatia-a-genialny-reziser-alebo-nasilnicky-podivin":
    "/nejnovejsi-videa",
  "/pecene-citronove-kura-skvely-letny-recept-si-zamilujete":
    "/nejnovejsi-videa",
  "/r19moscowood-mov": "/nejnovejsi-videa",
  "/budovanie-floridskej-rampy-pre-super-heavy-starship": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-4-19-17-6-18-08": "/nejnovejsi-videa",
  "/finalizacia-rusko-nemeckeho-observatoria-spektr-rg": "/nejnovejsi-videa",
  "/s2-e5-david-si-uziva-nocny-zivot-na-korfu-ziadna-zena-mu-neunikne":
    "/nejnovejsi-videa",
  "/hraskove-guacamole-sviezi-variant-ktory-chuti-aj-detom":
    "/nejnovejsi-videa",
  "/vynasala-raketa-falcon-9-utajeny-naklad": "/nejnovejsi-videa",
  "/albert-fish-3-3-psychologicky-profil-zvrateneho-serioveho-vraha-na-svete-sa-nenarodil-iny-clovek-s-tolkymi-sexalnymi-deviaciami":
    "/nejnovejsi-videa",
  "/s2-e6-dramaticky-rozhovor-s-rozaliou-a-davidovo-posledne-dobrodruzstvo-na-korfu-naozaj-stoji-za-to":
    "/nejnovejsi-videa",
  "/predstartova-simulacia-rakety-sls-uz-bola-takmer-dokonala":
    "/nejnovejsi-videa",
  "/drzanie-postu-ako-velka-moda-aj-kazdorocna-bezna-vec-a-typicke-postne-jedlo-nakyp-slovanskych-narodov":
    "/nejnovejsi-videa",
  "/juhokorejska-raketa-zlozila-reparat": "/nejnovejsi-videa",
  "/top-10-wtf-momentov-jaromira-soukupa": "/nejnovejsi-videa",
  "/hodvabna-omeleta-s-cerstvym-syrom-jemne-a-nadychane-ranajky":
    "/nejnovejsi-videa",
  "/najsilnejsia-europska-raketa-po-pol-roku-opat-v-akcii": "/nejnovejsi-videa",
  "/hannibal-barkas-najvacsi-nepriatel-rima-a-jeden-z-najlepsich-vojenskych-strategov-v-dejinach":
    "/nejnovejsi-videa",
  "/presun-70-metrov-vysokej-rakety-s-33-motormi": "/nejnovejsi-videa",
  "/priprava-rakety-pre-armadnu-misiu": "/nejnovejsi-videa",
  "/r23-hasbulla-mov": "/nejnovejsi-videa",
  "/kr7-sport-mov": "/nejnovejsi-videa",
  "/kralicie-chrbty-v-talianskom-sate-stavnata-rychlovka-s-minimom-prace":
    "/nejnovejsi-videa",
  "/europsko-japonska-misia-druhykrat-preletela-okolo-merkuru":
    "/nejnovejsi-videa",
  "/ako-prisiel-jan-neruda-k-susienkam-skvele-chrumkanie-nielen-na-citanie-fejtonov":
    "/nejnovejsi-videa",
  "/atlantske-vrazdy-1-3-28-mladych-obeti-z-afroamerickej-komunity-frustrovane-rodiny-necinna-policia":
    "/nejnovejsi-videa",
  "/nabity-zaver-misie-lode-cygnus": "/nejnovejsi-videa",
  "/poctivy-grecky-salat-klasika-ktora-nesmie-v-lete-chybat":
    "/nejnovejsi-videa",
  "/uz-piaty-junovy-start-spacex": "/nejnovejsi-videa",
  "/rontgenovy-dalekohlad-erosita": "/nejnovejsi-videa",
  "/nova-dvojica-armadnych-druzic-na-geostacionarnej-drahe":
    "/nejnovejsi-videa",
  "/indicka-raketa-vyniesla-tri-singapurske-druzice": "/nejnovejsi-videa",
  "/polenta-z-cerstvej-kukurice-originalna-priloha-k-masu-i-zelenine":
    "/nejnovejsi-videa",
  "/cyril-a-metod-strhujuci-pribeh-dvoch-mimoriadne-nadanych-osobnosti":
    "/nejnovejsi-videa",
  "/ake-jedlo-so-sebou-na-vylet-kuneticke-praclicky-podla-magdaleny-dobromily-rettigovej-su-skvela-volba":
    "/nejnovejsi-videa",
  "/sequence-18-3-mp4": "/nejnovejsi-videa",
  "/prva-lod-starship-pre-orbitalny-let": "/nejnovejsi-videa",
  "/krevetovy-koktail-s-kimchi-a-avokadom-tazke-retro-v-modernom-azijskom-have-vas-nadchne":
    "/nejnovejsi-videa",
  "/co-ukazu-prve-vedecke-snimky-z-webbovho-teleskopu": "/nejnovejsi-videa",
  "/filizofia-clash-of-clans-2": "/nejnovejsi-videa",
  "/konstrukcia-rontgenoveho-dalekohladu-erosita": "/nejnovejsi-videa",
  "/nova-ruska-navigacna-druzica": "/nejnovejsi-videa",
  "/ako-raketa-sls-precvicila-svoje-motory": "/nejnovejsi-videa",
  "/start-z-hmly-zacal-vznik-dalsej-fazy-siete-starlink": "/nejnovejsi-videa",
  "/najhlbsia-snimka-vesmiru-vsetkych-cias": "/nejnovejsi-videa",
  "/tomas-bata-obuvnik-ktory-dobyl-svet-bol-nielen-genialnym-strategom-a-obchodnikom":
    "/nejnovejsi-videa",
  "/noblesna-kasa-styroch-farieb-cim-farebnejsia-tym-luxusnejsia":
    "/nejnovejsi-videa",
  "/prve-vedecke-data-z-teleskopu-jamesa-webba-su-fascinujuce":
    "/nejnovejsi-videa",
  "/europa-ma-novu-kozmicku-raketu": "/nejnovejsi-videa",
  "/start-falcon-9-starlink-4-22-17-7-16-20-start-falcon-9-crs-25-15-7-2-44":
    "/nejnovejsi-videa",
  "/start-falcon-9-crs-25-15-7-2-44": "/nejnovejsi-videa",
  "/rusky-rontgenovy-teleskop-art-xc": "/nejnovejsi-videa",
  "/explozia-pod-raketou-super-heavy": "/nejnovejsi-videa",
  "/neil-armstrong-maly-krok-pre-cloveka-velky-skok-pre-ludstvo":
    "/nejnovejsi-videa",
  "/leco-s-klobaskou-oblubene-aj-medzi-zarytymi-odporcami-zeleniny":
    "/nejnovejsi-videa",
  "/dalsia-prestizna-zakazka-pre-falcon-heavy": "/nejnovejsi-videa",
  "/najjednoduchsi-a-najrychlejsi-vonavy-marhulovy-kolac": "/nejnovejsi-videa",
  "/priprava-loga-pre-aerodynamicky-kryt-rakety-atlas-v": "/nejnovejsi-videa",
  "/vyrazne-utajovana-misia-zuma": "/nejnovejsi-videa",
  "/salat-nicoise-lahky-obed-pre-letne-dni-ako-stvoreny": "/nejnovejsi-videa",
  "/sunkofleky-alebo-ako-si-lacno-napchat-brucho-povodny-recept-z-devatnasteho-storocia":
    "/nejnovejsi-videa",
  "/nekurime-mp4": "/nejnovejsi-videa",
  "/nekurime-1-mp4": "/nejnovejsi-videa",
  "/109-najbohatsie-deti-na-svete-2-mp4": "/nejnovejsi-videa",
  "/zigmund-luxembursky-pre-madarov-a-nemcov-uznavany-vladca-a-hrdina-pre-cechov-len-prehnana-liska-rysava":
    "/nejnovejsi-videa",
  "/tipy-na-vychytany-hermelinovy-burger-ktory-nikdy-nevytecie-kam-nema":
    "/nejnovejsi-videa",
  "/zmeny-v-programe-dopravy-vzoriek-z-marsu": "/nejnovejsi-videa",
  "/atlanticke-vrazdy-2-3-hlavny-podozrivy-nevyjasnene-dokazy-a-zvlastne-svedectvo":
    "/nejnovejsi-videa",
  "/rucne-fotoaparaty-a-kamery-pri-prvych-pilotovanych-kozmickych-misiach":
    "/nejnovejsi-videa",
  "/nova-cinska-kozmicka-raketa-zk-1a": "/nejnovejsi-videa",
  "/silny-masovy-vyvar-na-japonsky-ramien": "/nejnovejsi-videa",
  "/slaninovy-dzem-poklad-do-burgerov-hot-dogov-alebo-na-hrianku":
    "/nejnovejsi-videa",
  "/koniec-misie-sentinelu-1b": "/nejnovejsi-videa",
  "/rucne-fotoaparaty-a-kamery-od-programu-apollo-dodnes": "/nejnovejsi-videa",
  "/desat-rokov-roveru-curiosity-na-marse": "/nejnovejsi-videa",
  "/neuspesna-premiera-malej-indickej-rakety-sslv": "/nejnovejsi-videa",
  "/kontroverzny-francis-drake-v-anglicku-vlastenec-a-hrdina-v-spanielsku-pirat-a-vrah":
    "/nejnovejsi-videa",
  "/ancho-chili-pasta-na-marinady-dipy-a-ochutene-maslo": "/nejnovejsi-videa",
  "/novi-kozmicki-turisti-z-usa-portugalska-a-egypta": "/nejnovejsi-videa",
  "/nehynuca-laska-k-nive-vyprazana-v-cesticku-a-sunke-nema-chybu":
    "/nejnovejsi-videa",
  "/zazih-rakety-super-heavy-aj-lode-starship": "/nejnovejsi-videa",
  "/moderne-a-zdrave-predjedlo-budapestianska-natierka-s-grilovanou-paprikou":
    "/nejnovejsi-videa",
  "/pociatky-planetarnej-ochrany": "/nejnovejsi-videa",
  "/zlte-leco-zdrava-a-chutna-letna-klasika-podla-koko": "/nejnovejsi-videa",
  "/atlanske-vrazdy-3-3-bol-proces-zmanipulovany-a-ma-vrazdy-deti-na-svedomi-ku-klux-klan":
    "/nejnovejsi-videa",
  "/spekacikovy-kotlik-kacera-donalda-recepty-z-devatdesiatok-su-zase-v-kurze":
    "/nejnovejsi-videa",
  "/peter-i-velky-panovnik-ktory-na-ceste-k-pokrokovemu-rusku-umucil-vlastneho-syna":
    "/nejnovejsi-videa",
  "/pokrievka-na-polievku-z-kazdej-polievky-urobi-uplne-sviatocnu-zalezitost":
    "/nejnovejsi-videa",
  "/predcasne-ukonceny-rusky-vystup-do-volneho-priestoru": "/nejnovejsi-videa",
  "/dve-torza-figurin-zenskych-tiel-v-lodi-orion": "/nejnovejsi-videa",
  "/ako-na-nadychanu-a-vonavu-ryzu-bez-ryzovaru-skvela-priloha-k-rybam-kurcatu-i-azijskym-pokrmom":
    "/nejnovejsi-videa",
  "/kde-pristanu-ludia-pri-navrate-na-mesiac": "/nejnovejsi-videa",
  "/webbov-teleskop-ponukol-uzasne-pohlady-na-jupiter": "/nejnovejsi-videa",
  "/obalovany-karfiol-bez-vyprazania-odlahcena-a-napriek-tomu-chrumkava-verzia":
    "/nejnovejsi-videa",
  "/orzo-s-liskami-hubove-cestoviny-z-jedneho-hrnca": "/nejnovejsi-videa",
  "/technicka-porucha-zrusila-prvy-pokus-o-start-rakety-sls":
    "/nejnovejsi-videa",
  "/manzelia-westovi-1-3-ako-zacal-najzvratenejsi-krimi-pripad-z-velkej-britanie":
    "/nejnovejsi-videa",
  "/manzelia-westovi-2-3-zo-starostlivej-mamy-prostitutkou-sexualnou-nasilnicou-a-vrahynou-deti-a-mladych-dievcat":
    "/nejnovejsi-videa",
  "/ernest-shackleton-britsky-polarnik-a-hrdina-zachranil-vsetkych-26-clenov-svojej-vypravy":
    "/nejnovejsi-videa",
  "/hubbleov-a-webbov-teleskop-spojili-svoje-sily": "/nejnovejsi-videa",
  "/moderne-a-netradicne-cuketova-placka-na-japonsky-sposob":
    "/nejnovejsi-videa",
  "/nasa-si-objednala-dalsich-5-letov-lodou-crew-dragon": "/nejnovejsi-videa",
  "/netradicna-ale-vyborna-hubova-vyprazanica-podla-povodneho-receptu-zo-16-storocia":
    "/nejnovejsi-videa",
  "/tesnenie-a-sterilizacia-lunarnych-kontajnerov-alsrc": "/nejnovejsi-videa",
  "/rustikalna-hubova-polievka-s-chlebom-husta-a-vydatna": "/nejnovejsi-videa",
  "/uz-rastu-kde-najdete-vyhlaseny-hubarsky-raj-a-co-ste-o-hubach-nevedeli":
    "/nejnovejsi-videa",
  "/hmlovina-tarantula-pohladom-teleskopu-jamesa-webba": "/nejnovejsi-videa",
  "/michelangelo-buonarroti-ohromujuce-dielo-a-zivot-jedneho-z-najvacsich-umelcov-vsetkych-cias":
    "/nejnovejsi-videa",
  "/sest-a-pol-tony-tazka-europska-komunikacna-druzica": "/nejnovejsi-videa",
  "/nastroje-na-odber-lunarnych-vzoriek": "/nejnovejsi-videa",
  "/alexander-hamilton-muz-ktory-pomohol-slabuckej-bankrotujucej-krajine-az-k-pozicii-svetovej-supervelmoci":
    "/nejnovejsi-videa",
  "/farba-bugatti-lambotoma-z-betcademy-odhalena": "/nejnovejsi-videa",
  "/018-charles-mp4": "/nejnovejsi-videa",
  "/bezpilotnu-raketu-new-shepard-stretla-nehoda": "/nejnovejsi-videa",
  "/kurca-a-la-bazant-ako-jednoducho-a-rychlo-povysit-obycajne-kura":
    "/nejnovejsi-videa",
  "/cina-vypustila-telekomunikacnu-druzicu": "/nejnovejsi-videa",
  "/rekordna-panorama-z-povrchu-marsu": "/nejnovejsi-videa",
  "/zemiakovy-fondan-elegantna-priloha-pre-specialne-prilezitosti":
    "/nejnovejsi-videa",
  "/jubilejna-misia-malej-rakety-electron": "/nejnovejsi-videa",
  "/ozonova-diera-pohladom-europskej-druzice": "/nejnovejsi-videa",
  "/hubovy-perkelt-rychlovka-ktoru-si-zamilujete": "/nejnovejsi-videa",
  "/geologicky-vycvik-astronautov-pre-mesiac": "/nejnovejsi-videa",
  "/buchticky-so-sodo-pravy-poklad-zo-skolskych-jedalni": "/nejnovejsi-videa",
  "/vysetrenie-na-orl-hadica-s-kamerou-v-nose-neboli-a-problemy-odhali-vcas":
    "/nejnovejsi-videa",
  "/rusko-americka-trojica-dorazila-na-stanicu-iss": "/nejnovejsi-videa",
  "/06-nehody-new-mov": "/nejnovejsi-videa",
  "/preco-su-shopaholic-adel-alebo-sugar-denny-tak-popularny":
    "/nejnovejsi-videa",
  "/bob-marley-najvacsia-legenda-reggae-symbol-mieru-dlhych-dredov-a-fajcenie-marihuany":
    "/nejnovejsi-videa",
  "/nastroje-z-programu-apollo-pre-odber-podpovrchovych-vzoriek":
    "/nejnovejsi-videa",
  "/test-rakety-sls-bol-uspesny-napriek-tomu-sa-start-odklada":
    "/nejnovejsi-videa",
  "/silna-raketa-delta-iv-heavy-sa-rozlucila-s-kaliforniou":
    "/nejnovejsi-videa",
  "/skautsky-chlebicek-poctiva-a-vydatna-svaca-nielen-na-tury-a-vylety":
    "/nejnovejsi-videa",
  "/ako-prebieha-funkcne-vysetrenie-pluc-a-kozne-testy-na-alergologii":
    "/nejnovejsi-videa",
  "/obria-raketa-sls-je-spat-v-montaznej-hale": "/nejnovejsi-videa",
  "/v22-surovikin-mov": "/nejnovejsi-videa",
  "/v22-surovikin-mov-2": "/nejnovejsi-videa",
  "/raketovy-motor-be-4-je-po-oneskoreniach-pripraveny": "/nejnovejsi-videa",
  "/futbalista-pele-najlepsi-hrac-vsetkych-cias-ktoreho-milovala-brazilia-a-obdivoval-cely-svet":
    "/nejnovejsi-videa",
  "/dva-odlisne-pohlady-na-rovnaku-galaxiu": "/nejnovejsi-videa",
  "/biopsia-pazeraka-neprijemne-vysetrenie-neboli-zato-moze-odhalit-nebezpecny-problem-vcas":
    "/nejnovejsi-videa",
  "/45-tohtorocny-start-falconu-9": "/nejnovejsi-videa",
  "/vlasiak-kde-sa-vzala-tolko-milovana-prasarnicka": "/nejnovejsi-videa",
  "/raketa-falcon-9-vycarila-na-oblohe-uzasny-zazitok": "/nejnovejsi-videa",
  "/ako-prebehne-test-nafukovacieho-tepelneho-stitu": "/nejnovejsi-videa",
  "/nevyspytatelny-sovietsky-vodca-nikita-chruscov-skandalny-prejav-v-osn-ktory-sa-stal-legendou":
    "/nejnovejsi-videa",
  "/nelutostny-zatah-rozprasil-templarsky-poriadok-a-viedol-k-smrti-velmajstra-jacquesa-de-molay":
    "/nejnovejsi-videa",
  "/hura-do-tunela-magneticka-rezonancia-je-bezpecna-a-zaroven-najpresnejsia-metoda-vysetrenia-organov":
    "/nejnovejsi-videa",
  "/telekomunikacna-angolska-druzica-letela-na-ruskom-protone-m":
    "/nejnovejsi-videa",
  "/navrat-astronautov-z-mesiaca-a-ich-karantena": "/nejnovejsi-videa",
  "/falcon-9-vyniesol-europsku-komunikacnu-druzicu": "/nejnovejsi-videa",
  "/testovaci-kus-novej-europskej-rakety": "/nejnovejsi-videa",
  "/co-sa-deje-pri-odbere-krvnej-plazmy-av-com-sa-lisi-od-darovania-krvi":
    "/nejnovejsi-videa",
  "/florence-nightingale-zakladatelka-moderneho-osetrovatelstva-a-zdravotna-sestra-ktora-dokazala-zachranit-tisice-zivotov":
    "/nejnovejsi-videa",
  "/planetarna-ochrana-pri-analyze-vzoriek-z-programu-apollo":
    "/nejnovejsi-videa",
  "/mozaika-snimok-z-marsu-ukazuje-krater-gale": "/nejnovejsi-videa",
  "/dva-starty-ruskych-rakiet-sojuz": "/nejnovejsi-videa",
  "/falcon-9-vyniesol-dalsich-54-druzic-starlink": "/nejnovejsi-videa",
  "/aruceny-pasivny-prijem-tu-mupi-najt-lajf-43": "/nejnovejsi-videa",
  "/ako-si-spravne-zariadit-pracovne-miesto-tipy-a-vychytavky-nielen-pre-pracu-z-domu":
    "/nejnovejsi-videa",
  "/bizarne-prisady-ktore-nabudia-sexualny-apetit-eroticka-kucharka-z-devatdesiatok-je-plna-inspiracie":
    "/nejnovejsi-videa",
  "/priprava-novej-meteorologickej-druzice-jpss-2": "/nejnovejsi-videa",
  "/ako-prebieha-vysetrenie-prostaty-nie-je-sa-coho-bat-muzi-po-styridsiatke-by-ho-mali-absolvovat-pravidelne":
    "/nejnovejsi-videa",
  "/mimoriadne-detailny-pohlad-na-slnko-od-europskej-sondy":
    "/nejnovejsi-videa",
  "/benito-mussolini-diktator-ktory-zavliekol-taliansko-do-niekolkych-vojen-a-vytvoril-z-neho-totalitny-stat":
    "/nejnovejsi-videa",
  "/rybie-file-ako-najlacnejsi-masovy-pokrm-kde-sa-v-cesku-a-na-slovensku-vzala-stalica-nasho-stravovania":
    "/nejnovejsi-videa",
  "/hypnoza-pomoze-prebudit-vase-skryte-moznosti-takto-to-prebieha":
    "/nejnovejsi-videa",
  "/raketove-motory-be-4-pre-novu-raketu-vulcan-su-pripravene":
    "/nejnovejsi-videa",
  "/dalsia-europska-komunikacna-druzica-letela-na-falcone-9":
    "/nejnovejsi-videa",
  "/trest-smrti-pre-saddama-husajna-vodcu-iraku-strojcu-devastujucich-cistiek-a-genocid":
    "/nejnovejsi-videa",
  "/americko-francuzsky-merac-hladin-dorazil-na-kozmodrom": "/nejnovejsi-videa",
  "/david-livingstone-legendarny-cestovatel-a-misionar-ktoreho-laska-k-miestnym-ludom-zmenila-pohlad-europanov-na-afriku":
    "/nejnovejsi-videa",
  "/ako-velmi-boli-epilacia-muzi-si-coraz-castejsie-nechavaju-odstranovat-chlpy-zo-vsetkych-casti-tela":
    "/nejnovejsi-videa",
  "/raketa-atlas-v-sa-rozlucila-s-kaliforniou-uspesnou-misiou":
    "/nejnovejsi-videa",
  "/jan-jansky-psychiater-a-vedec-ktory-objavil-krvne-skupiny-sa-mal-stat-svetovou-hviezdou":
    "/nejnovejsi-videa",
  "/najsilnejsi-staticky-zazih-v-historii-spacex": "/nejnovejsi-videa",
  "/rekordy-kral-rodiny-mp4": "/nejnovejsi-videa",
  "/problemy-cubesatov-vypustenych-s-misiou-artemis-i": "/nejnovejsi-videa",
  "/3-vystupy-do-volneho-priestoru-pocas-3-dni": "/nejnovejsi-videa",
  "/dennodenna-konzumacia-piva-a-strasna-zivotosprava-co-odhali-ultrazvukove-vysetrenie-pecene":
    "/nejnovejsi-videa",
  "/rakety-antares-vo-verziach-230-a-330": "/nejnovejsi-videa",
  "/americki-strazcovia-hurikanov-poleti-na-inej-rakete": "/nejnovejsi-videa",
  "/bill-gates-obdivuhodne-uspechy-aj-kontroverzie-jedneho-z-najuspesnejsich-podnikatelov-vsetkych-cias":
    "/nejnovejsi-videa",
  "/o-krok-blizsie-k-startu-meraca-hladin-swot": "/nejnovejsi-videa",
  "/na-obeznej-drahe-je-prvykrat-6-cinanov": "/nejnovejsi-videa",
  "/140-skandaly-roku-2022-mp4": "/nejnovejsi-videa",
  "/josif-vissarionovic-stalin-jeden-z-najvacsich-zlocincov-20-storocia-za-ktoreho-vlady-zahynuli-desiatky-milionov-ludi":
    "/nejnovejsi-videa",
  "/pazerakove-echo-hadica-zavedena-do-krku-moze-odhalit-skryte-srdcove-chyby":
    "/nejnovejsi-videa",
  "/zlozita-cesta-k-raketovym-motorom-rd-170": "/nejnovejsi-videa",
  "/kozmicka-lod-orion-sa-vracia-na-zem": "/nejnovejsi-videa",
  "/isadora-duncan-velke-lasky-dramy-a-tragedie-slavnej-priekopnicky-moderneho-tanca":
    "/nejnovejsi-videa",
  "/spiroergometria-zatazovy-test-odhali-kondiciu-srdca-a-pluc":
    "/nejnovejsi-videa",
  "/pripravy-na-navrat-lode-orion": "/nejnovejsi-videa",
  "/do-posadky-lunarnej-turistickej-misie-dearmoon-sa-dostal-aj-cech":
    "/nejnovejsi-videa",
  "/56-mp4": "/nejnovejsi-videa",
  "/adolf-hitler-z-bezdomovce-diktatorem-posledni-dny-pred-sebevrazdou-a-konec-zvraceneho-snu":
    "/nejnovejsi-videa",

  "/relacie": "/porady",

  // "/serialy":
  //   "/kategorie?tab=Seri%C3%A1ly",

  // "/popularno-naucne":
  //   "/kategorie?tab=Popul%C3%A1rn%C4%9B-nau%C4%8Dn%C3%A9",

  // "/varenie-jedlo-2":
  //   "/kategorie?tab=Va%C5%99en%C3%AD+%26+J%C3%ADdlo",

  // "/inspiracie":
  //   "/kategorie?tab=Inspirace",

  "/milan-junior-zimnykoval": "/suhajici-pri-panvici",

  "/joe-trendy": "/naloz-s-joe-trendym",

  "/jana-kovalcikova": "/kavej",

  "/juraj-curny": "/top-7-juraja-curneho",

  "/vesmirne-spravy": "/vesmirne-zpravy",

  "/anka-jakab-rakovska": "/kavej",

  "/team-paranormal": "/paranormal",

  "/ivo-ladizo-ladizinsky": "/kinopati",

  "/jerry-szabo": "/kinopati",

  "/soko": "/takze-tak",

  "/sarkan": "/nocna-smena",

  "/petr-vanek": "/stylista",

  "/petra-nesvacilova": "/stylista",

  "/andrej-polak": "/olo-show",

  "/michal-kern": "/olo-show",

  "/silvia-suvadova": "/takze-tak",

  "/admin-zomri": "/takze-tak",

  "/michael-szatmary": "/takze-tak",

  "/magicky-rudolf": "/takze-tak",

  "/veronika-koko-smehlikova": "/menutovka",

  "/radan-sprongl": "/no-go-zone",

  "/roman-vanek": "/rozum-v-troube",

  "/tadeas-danek": "/utecenecka-vlna",

  "/jakub-klingohr": "/delnici-zivota",

  "/dusan-majer": "/vesmirne-zpravy",

  "/andy-kraus": "/7-x-7",

  "/marian-cekovsky": "/7-x-7",

  "/adela-vinczeova": "/7-x-7",

  "/dano-dangl": "/7-x-7",

  "/elena-podzamska": "/bullwar",

  "/jakub-rybarik": "/bullwar",

  "/lucia-javorcekova": "/bullwar",

  "/fero-joke": "/vyber-si",

  "/prekvapive-stavby": "/gebrian-prekvapive-stavby",

  "/m-test": "/mtest",

  "/adam-gebrian": "/gebrian-prekvapive-stavby",

  "/tomas-ouhel": "/wildlife-guards",

  "/michal-galik": "/wildlife-guards",

  "/naomi-adachi": "/naomi-v-japonsku",

  "/pavel-zuna": "/top-moment",

  "/roman-mrazik": "/mtest",

  "/wildlife-guards-strazcovia-prirody": "/wildlife-guards",

  "/prazdna-chladnicka": "/prazdna-lednice",

  "/petr-havlicek": "/rozum-v-troube",

  "/matej-kosut": "/prazdna-lednice",

  "/oliver-vozarik": "/prazdna-lednice",

  "/rozum-v-rure": "/rozum-v-troube",

  "/5-veci-ktore-robite-zle": "/5-veci-ktere-delate-blbe",

  "/dobru-chut": "/dobrou-chut",

  "/na-vlastny-otvor": "/na-vlastni-otvor",

  "/robotnici-zivota": "/delnici-zivota",

  "/vit-samek": "/na-vlastni-otvor",

  "/hana-vecerkova": "/pod-penou",

  "/agata-pracharova": "/spolu-doma",

  "/majster-pekar": "/mistr-pekar",

  "/jan-prusinovsky": "/zivot-je-hra",

  "/michal-suchanek": "/zivot-je-hra",

  "/petr-marek": "/zivot-je-hra",

  "/jenovefa-bokova": "/zivot-je-hra",

  "/vaclav-marhold": "/zivot-je-hra",

  "/daniel-dangl": "/7-x-7",

  "/lukas-latinak": "/porady",

  "/juraj-kemka": "/gejmhaus",

  "/marian-miezga": "/gejmhaus",

  "/robert-jakab": "/gejmhaus",

  "/retazovka": "/retazovka",

  "/milujem-ta-pucinka": "/miluju-te-pucinko",

  "/jiri-madl": "/miluju-te-pucinko",

  "/kktv": "/kktv",

  "/ludek-stanek": "/udalosti-ludka-stanka",

  "/ludek-stanek-sa-bavi": "/ludek-stanek-se-bavi",

  "/martin-hanus": "/mistr-pekar",

  "/festival-rock-for-people": "/rock-for-people-home",

  "/tady-vary": "/tady-vary",

  "/ide-to-aj-bez-masa": "/jde-to-i-bez-masa",

  "/radek-david": "/jde-to-i-bez-masa",

  "/pan-cuketka": "/recepty-pana-cuketky",

  "/jakub-klingohr-2": "/delnici-zivota",

  "/lubica-kmet-jakusova": "/stylovecka",

  "/dodik": "/glupe-videja",

  "/terapia-zdielanim": "/terapie-sdilenim",

  "/vladimir-skultety": "/olo-show",

  "/sefovia-na-cestach": "/sefove-na-cestach",

  "/viete-ze": "/viete-ze",

  "/mikyrova-uzasna-put-internetom": "/mikyrova-uzasna-pout-internetem",

  "/martin-mikyska": "/mikyrova-uzasna-pout-internetem",

  "/tuning-vianocnych-dobrot": "/tuning-vianocnych-dobrot",

  "/lucid": "/kurz-hviezdneho-licenia-s-lucid",

  "/tajomstvo-spanku": "/tajemstvi-spanku",

  "/martin-jirman": "/trafika",

  "/maly-miso": "/1-den-ako",

  "/menu-s-hviezdickou": "/menu-s-hvezdickou",

  "/marie-radostova": "/sitoviny",

  "/mall-tv-zive-sk": "/mall-tv-zive",

  "/jaro-slavik": "/prikazane-uvolnenie",

  "/robotnici-ziadostivosti": "/delnici-chtice",

  "/matej-filadelfi": "/jazdenky",

  "/kralovne-poriadku": "/kralovny-poradku",

  "/triedna-schodzka": "/tridni-schuzka",

  "/mr-tupelo": "/mr-tupelo-show",
}

const SLUG_KEYS2 = {
  "/o-nas/kontakty": "/kontakty",
  "/o-nas/pro-media": "/",
  "/o-nas/pravni-dolozka": "/pravni-dolozka",
  "/o-nas/principy-ochrany-soukromi": "/principy-ochrany-soukromi",
  "/o-nas/faq": "/faq",
  "/o-nas": "/faq",
}

export default function (to, from) {
  const headers = useRequestHeaders()

  const projectId = headers["x-vp-apps-project-id"]

  if (
    projectId === "Z6wMB4kEl2n" &&
    SLUG_KEYS[to.path] &&
    to.path !== "/" &&
    SLUG_KEYS[to.path] != to.path
  ) {
    return navigateTo(SLUG_KEYS[to.path])
  }

  if (
    projectId === "lWjM5lgNAO7" &&
    SLUG_KEYS2[to.path] &&
    to.path !== "/" &&
    SLUG_KEYS2[to.path] != to.path
  ) {
    return navigateTo(SLUG_KEYS2[to.path])
  }
}
